import React, { useEffect, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { useDispatch } from 'react-redux';
import { AmazonListingsActions } from '../_redux/actions';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';

function AmazonListingsWithdrawDialog({ id, show, onHide, marketId }) {
  const dispatch = useDispatch();

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(
    () => ({
      queryParams: UIContext.queryParams
    }),
    [UIContext.queryParams]
  );

  // if !id we should close modal
  useEffect(() => {
    !id && onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Deactivate action
  const withdrawListings = () => {
    dispatch(AmazonListingsActions.amazonWithdrawListingItemRequest({ productId: id, marketId, queryParams: UIProps.queryParams }));
    onHide();
  };
  return (
    <Portal node={document && document.getElementById('layout-portal')}>
      <Modal show={show} onHide={onHide} size="md" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>
        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          <div className="mt-3">
            <FormattedMessage id="AMAZON_LISTINGS.WITHDRAW_CONFIRMATION" defaultMessage="Are you sure about this!" />
          </div>
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">
          <Button variant="ios" className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="ios" className="text-danger" onClick={withdrawListings} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.WITHDRAW" defaultMessage="Withdraw" />
          </Button>
        </Modal.Footer>
      </Modal>
    </Portal>
  );
}

export { AmazonListingsWithdrawDialog };
