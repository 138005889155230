import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { Icon } from '_metronic/_icons';
import { useDispatch } from 'react-redux';
import { SettingsActions } from 'app/modules/settings/_redux/actions';



export function SettingsDialog({ documentSettings, action, onHide, documentType, loading }) {

  const dispatch = useDispatch();

  const { type, checked } = action;

  const handleSubmit = () => {
    const updateSetting = (key, value) => {
      const newValues = {
        ...documentSettings,
        ...(documentType === 'invoices' && {
          oss_details: {
            ...documentSettings.oss_details,
            [key]: value,
            ...(key === "oss_limit_active" && { is_oss_threshold: value }),
          },
        }),
      };

      documentSettings.id
        ? dispatch(SettingsActions.updateUserSettings(documentSettings.id, newValues))
        : dispatch(SettingsActions.createUserSettings(newValues));
    };

    if (type === "oss_active" || type === "oss_limit_active") {
      updateSetting(type, action.checked);
    }

    onHide();
  };


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={action.show} onHide={onHide} aria-labelledby="example-modal-sizes-title-xl" size="md" centered>

        <Modal.Body className='px-10 px-6'>
          <div className="d-flex flex-column align-items-center">
          <Icon.InfoCircle className="text-info icon-4x my-6" />
          </div>

          <h6 className="text-center font-weight-bold">
            {type === "oss_active" && <FormattedMessage id={checked ? "SETTINGS.DOCUMENT.OSS.OSS_ACTIVATE.INFO" : "SETTINGS.DOCUMENT.OSS.OSS_DEACTIVATE.INFO"} />}
            {type === "oss_limit_active" && <FormattedMessage id={checked ? "SETTINGS.DOCUMENT.OSS.OSS_LIMIT_ACTIVATE.INFO" : "SETTINGS.DOCUMENT.OSS.OSS_LIMIT_DEACTIVATE.INFO"} />}
          </h6>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='light' className='ml-auto' onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>

          <Button variant='primary' className='ml-2' onClick={handleSubmit} disabled={loading} id="btn_confirm_oss">
            {/* <FormattedMessage id="GENERAL.SEND" /> */}
            <FormattedMessage id="GENERAL.CONTINUE" />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  )
}