import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { uiGetMwstTextByTaxCategory, uiGetDocumentFormValues, getLastSearch, documentTypes } from '_metronic/_helpers';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { BackButton, SaveButton, CreationAndUpdateInfo } from '_metronic/_partials';
import { OverPaymentInfoDialog } from '../document-dialogs/OverPaymentInfoDialog';
import { PrintLabelsDialog } from '../label-setting-dialog/PrintLabelsDialog';
import { SendEmailDialog } from './shipping/shipping-dialogs/SendEmailDialog';
import { StockControlDialog } from '../document-dialogs/StockControlDialog';
import { CustomerEdit } from '../../customers/customer-edit/CustomerEdit';
import { CustomerSelectCard } from './customer-select/CustomerSelectCard';
import { UserGuideHelper } from '../../../user-guides/UserGuideHelper';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { CustomerActions } from 'app/modules/customers/_redux/actions';
import { ServerMessageDialog } from '_metronic/_partials/dialogs';
import { DocumentEditProvider } from './DocumentEditUIContext';
import * as uiHelpers from '../_context/DocumentsUIHelpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { DocumentActions } from '../_redux/actions';
import { FormikHandler } from './FormikHandler';
import { LayoutSplashScreen } from '_metronic/layout';
import { PositionsCard } from './positions/PositionsCard';
import { CustomerCard } from './customer/CustomerCard';
import { ShippingCard } from './shipping/ShippingCard';
import { AddressCard } from './address/AddressCard';
import { PaymentCard } from './payment/PaymentCard';
import { useLoadingHandler } from 'app/hooks';
import { Col, Row } from 'react-bootstrap';
import { History } from './history/History';
import { TextCard } from './text/TextCard';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { isNumber } from 'lodash';
import { SettingsActions } from 'app/modules/settings/_redux/actions';



export function DocumentEdit({ history, match: { params: { id, copyId, convertedDocumentId, convertedDocumentUrl } } }) {

  const dispatch = useDispatch();
  const lastSearch = getLastSearch()
  const { formatMessage: intl } = useIntl()

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    requestedDocument: UIContext.requestedDocument,
    showCustomerEditDialog: UIContext.showCustomerEditDialog,
    handleCloseCustomerEditDialog: UIContext.handleCloseCustomerEditDialog,
    handleOpenServerRespondDialog: UIContext.handleOpenServerRespondDialog,
    showServerRespondDialog: UIContext.showServerRespondDialog,
    handleOpenEnterPaymentEditDialog: UIContext.handleOpenEnterPaymentEditDialog,
    openLabelSettingDialog: UIContext.openLabelSettingDialog,
    openOverPaymentInfoDialog: UIContext.openOverPaymentInfoDialog,
    showLabelSettingsDialog: UIContext.showLabelSettingsDialog,
    setDocumentId: UIContext.setDocumentId
  }), [UIContext]);

  const [stockControl, setStockControl] = useState({ show: false, data: { convertDocument: {}, convertParams: {} } });
  const [requestHasError, setRequestHasError] = useState(false);
  const handleMessageDialog = useCallback(() => {
    setRequestHasError(false);
  }, []);

  const { documentForEdit, positions, request, documentSettings, documentActionLoading, loading, customerId, success } = useSelector(state => ({
    documentActionLoading: state.documents.actionLoading,
    loading: state.documents.loading,
    documentForEdit: state.documents.documentForEdit,
    positions: state.documents.positions,
    request: state.documents.request,
    documentSettings: state.documents.userSettings,
    customerId: state.documents?.customer?.id,
    success: state.documents.success,
    //tax: state.documents.userSettings?.default_product?.tax
    //  ? +state.documents.settings.default_product.tax
    //  : 19, 
  }),
    shallowEqual
  );

  const isLoading = useLoadingHandler(loading, DocumentActions.clearLoadingEffects());


  useEffect(() => {
    id &&
      dispatch(DocumentActions.getDocumentById(id, false));

    copyId &&
      dispatch(DocumentActions.getDocumentById(copyId, true));

    convertedDocumentId &&
      dispatch(DocumentActions.getDocumentById(convertedDocumentId, false, convertedDocumentUrl));

  }, [id, dispatch, copyId, convertedDocumentId, convertedDocumentUrl]);

  useEffect(() => {
    dispatch(SettingsActions.getOss('document'));
    dispatch(DocumentActions.getSettingsByType(UIProps.requestedDocument.type));
    dispatch(DocumentActions.getCompanyBaseData());
  }, [dispatch, UIProps.requestedDocument.type]);


  // //Burasi kalkabilir?
  //   useEffect(() => {
  //     return () => {
  //       dispatch(
  //         DocumentActions.cleanStatesInStore({
  //           documentForEdit: uiHelpers.initialDocument,
  //           request: uiHelpers.initialStatesForReducer.request,
  //         })
  //       );
  //       dispatch(CustomerActions.cleanCustomer())
  //     };
  //   }, [dispatch]);


  useEffect(() => {
    if (request.status === 'fulfilled') {
      setRequestHasError(false);
      if (UIProps.showLabelSettingsDialog) {
        UIProps.setDocumentId(request.documentId);
        return history.replace(`${ModuleRoutes.DOCUMENT}/${UIProps.requestedDocument.type}/${request.documentId}/edit`)
      }
      return history.push(`${ModuleRoutes.DOCUMENT}/${UIProps.requestedDocument.type + lastSearch}`)
    }
    /*  if (request.status === 'failed' && request.error) {
       return setRequestHasError(true);
     } */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.requestedDocument.type, history, request.error, request.status, lastSearch]);


  // Save Document
  const saveDocument = (values) => {

    const { selectedCustomerAddressId, customer_details, shipping, payment_warnings, history, ...restValues } = values;

    // --------------------------------- Toast Message ---------------------------------
    const overPayment = uiHelpers.paymentAmountDifference(positions, values)

    if (overPayment < 0) {
      return UIProps.openOverPaymentInfoDialog(-overPayment, positions[0]?.currency)
    }
    const formattedMessageId = uiHelpers.documentToastMessage(customer_details, positions)
    if (formattedMessageId) {
      return toast.warning(intl({ id: formattedMessageId }))
    }
    for (const pos of positions) {
      if (!isNumber(pos.quantity)) {
        return toast.warning(<FormattedMessage id='DOCUMENT.EDIT.QUANTITY.NOT_NUMBER' values={{ sku: pos.sku }} />);
      }
    }

    // ---------------------------------  Shipping Price ---------------------------------
    const filterShipping = shipping?.filter(item => item.type === 'shipment');
    const shippingPrice = filterShipping?.map(s => s.price).reduce((acc, price) => acc + +price, 0) ?? 0;

    // ----------------------------------  Total Weight ----------------------------------
    const totalWeight = filterShipping?.map(s => s.weight).reduce((acc, weight) => acc + +weight ?? 0, 0) || 2;

    // ---------------------------  Total Net and Brutto Price ---------------------------
    const netTotal = positions.reduce((acc, net) => acc + +net.total_net_price, 0);
    const bruttoTotal = positions.reduce((acc, net) => acc + +net.total_gross_price, 0);
    //const calculateTaxes = taxCalculator(positions, shippingPrice);
    //const totalTaxPrice = calculateTaxes.reduce((acc, arr) => acc + arr.mwst, 0);

    // ---------------------------  Document Preparation ---------------------------

    const document = {
      ...restValues,
      customer_details,
      position: Array.isArray(positions) ? positions : [],
      tax: {
        ...values.tax,
        country_code: values?.customer_details?.delivery_address?.country_code,
        legal_text: uiGetMwstTextByTaxCategory(values.tax?.category),
      },
      pricing_summary: {
        ...values.pricing_summary,
        shipping_price: values.marketplace !== "" ? +values.pricing_summary.shipping_price : +shippingPrice,
        total: +bruttoTotal,
        sub_total: +netTotal,
        total_tax: +bruttoTotal - +netTotal,
        currency: values.marketplace !== "" ? values.pricing_summary.currency : positions[0]?.currency,
      },
      total_weight: totalWeight !== 0 ? totalWeight : 2,
    };

    document.history = Array.isArray(documentForEdit.history) && documentForEdit?.history.map((h) => {
      if (h.document_from === "credit" || h.document_to === "credit") {
        return { ...h, relation_id: values.related_invoice_id };
      }
      return h;
    });

    // Eğer credit daha önce oluşturulduysa ve history varsa related_invoice_id'yi burada siliyoruz, çünkü Credit modelinde yok ve ilgili yere zaten kullandık
    Array.isArray(documentForEdit.history) && delete document.related_invoice_id;

    if (document.order_date === null) {// FIXME: order_date string tutulduğu için, migrasyonda order_date silinmeli, controller replaceByID olmadığı için buradan silinemez
      delete document.order_date
    }

    if (id) {
      // ---------------------------  Update Document ---------------------------
      dispatch(DocumentActions.updateDocument(document));
    } else if (convertedDocumentId && convertedDocumentUrl) {
      // ---------------------------  Create Convert Document ---------------------------
      const { id, data_origin, invoice_uploaded, created_at, updated_at, order_date, ...restConvertDocument } = document;

      const convertDocument = {
        ...restConvertDocument,
        shipping: shipping?.map(({ price, weight, relation_documents, created_at, updated_at, ...rest }) => ({ ...rest })),
        history: [...history, {
          id: uuidv4(),
          status: "success",
          action: "convert_document",
          document_from: documentTypes[convertedDocumentUrl],
          document_to: documentTypes[UIProps.requestedDocument.type],
          description: convertedDocumentId,
          relation_id: convertedDocumentId,
        }],
      };

      // --------------------------- Converting Document Update ---------------------------
      const convertParams = {
        id: convertedDocumentId,
        model: convertedDocumentUrl,
        type: documentTypes[UIProps.requestedDocument.type]
      };

      // ---------------------------  Stock Control && Create Document ---------------------------
      if (UIProps.requestedDocument.type === "credits" && convertDocument?.position?.length) {
        setStockControl({ show: true, data: { convertDocument, convertParams } });
      } else {
        dispatch(DocumentActions.createDocument(convertDocument, convertParams));
      };

    } else {
      // ----------------------  Create New Document / Create Copy Document ----------------------

      const { order_date, related_invoice_id, ...restCopyDocument } = document;

      const newDocument = {
        ...restCopyDocument,
        history: [{
          id: uuidv4(),
          status: "success",
          action: `${copyId ? "copy_document" : "create_document"}`,
          document_from: documentTypes[UIProps.requestedDocument.type],
          description: copyId ? copyId : document.order_number ?? document.order_id ?? "",
          relation_id: values.related_invoice_id || "",
          ...(copyId && { relation_id: copyId }),
        }]
      };

      dispatch(DocumentActions.createDocument(newDocument));
    }

  };

  const btnRef = useRef();
  const resetBtnRef = useRef();
  const saveDocumentClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const pageTitle = () => {
    let message
    if (id) {
      message = "DOCUMENT.UPDATE_TITLE"
    } else {
      message = UIProps.requestedDocument.type === 'offers' ? "DOCUMENT.NEUES" : UIProps.requestedDocument.type === 'waybills' ? "DOCUMENT.NEUER" : "DOCUMENT.NEW"
    }
    return intl({ id: message }, { name: UIProps.requestedDocument.title })
  }


  return (
    <DocumentEditProvider>

      {documentActionLoading && <LayoutSplashScreen />}

      {/*  kaldırılmalı */}
      {requestHasError && request.error &&
        <ServerMessageDialog message={request.error} show={requestHasError} onHide={handleMessageDialog} />
      }

      <CustomerEdit show={UIProps.showCustomerEditDialog} onHide={UIProps.handleCloseCustomerEditDialog} modal />

      <Card className="card-box">

        <CardHeader
          back={<BackButton link={`${ModuleRoutes.DOCUMENT}/${UIProps.requestedDocument.type + lastSearch}`} />}
          title={pageTitle()}
          info={<UserGuideHelper title={UIProps.requestedDocument.title} />}
          id={`title_document_${id ? 'update' : 'add'}`}
          sticky
        >
          <CardHeaderToolbar>
            <SaveButton onClick={saveDocumentClick} id={UIProps.requestedDocument.type} />
          </CardHeaderToolbar>
        </CardHeader>

        <CardBody>

          <FormikHandler
            autoComplete="on"
            document={uiGetDocumentFormValues({
              id,
              copyId,
              convertedDocumentId,
              documentForEdit,
              customerId,
              documentProps: UIProps.requestedDocument,
              settingProps: documentSettings,
              initialDocument: uiHelpers.initialDocument,
            })}
            btnRef={btnRef}
            resetBtnRef={resetBtnRef}
            saveDocument={saveDocument}
            id={id}
            success={success}
            onClose={() => {
              history.push(`${ModuleRoutes.DOCUMENT}/${UIProps.requestedDocument.type + lastSearch}`);
              dispatch(
                DocumentActions.cleanStatesInStore({
                  documentForEdit: uiHelpers.initialDocument,
                  request: uiHelpers.initialStatesForReducer.request,
                })
              );
              dispatch(CustomerActions.cleanCustomer())
            }}
          >

            {!(id || convertedDocumentId) &&
              <CustomerSelectCard loading={isLoading} />
            }

            {documentForEdit?.customer_details?.id
              ? <>

                <CustomerCard loading={isLoading} />

                <AddressCard loading={isLoading} />

                <PositionsCard loading={isLoading} />

                <ShippingCard loading={isLoading} openLabelSettingDialog={UIProps.openLabelSettingDialog} documentId={id} isConvert={convertedDocumentUrl} saveDocumentClick={saveDocumentClick} />

                <PaymentCard loading={isLoading} documentId={id} requestedDocument={UIProps.requestedDocument.type} handleOpenEnterPaymentEditDialog={UIProps.handleOpenEnterPaymentEditDialog} />

                <Row>
                  <Col md={6} className='mb-md-0 mb-sm-8'>
                    <TextCard loading={isLoading} documentId={id} />
                  </Col>

                  <Col md={6}>
                    <History history={documentForEdit?.history} />
                  </Col>
                </Row>

              </>
              : <></>}

          </FormikHandler>

        </CardBody>

        <CreationAndUpdateInfo data={documentForEdit} />

        <StockControlDialog show={stockControl.show} onHide={() => setStockControl({ ...stockControl, show: false })} data={stockControl.data} />

        <PrintLabelsDialog />

        <SendEmailDialog />

        <OverPaymentInfoDialog />

      </Card>
    </DocumentEditProvider>
  );
}