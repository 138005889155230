
import React, { useState, useEffect } from 'react';
import { LICENSE_TYPE, ACCOUNT_STATUS } from '../../_context/AdminUIHelper';
import { useReportsUserUIContext } from '../_context/ReportsUserUIContext';
import { DatePickerDropdown } from '_metronic/_partials/components';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { MultipleFilter } from '_metronic/_partials/components';
import { SearchControl } from '_metronic/_partials/controls';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';



export function ReportsUserFilter({ UIProps, reports }) {

  const query = useQuery();
  const [search, setSearch] = useState(query.get('search') ?? '');

  const UIContext = useReportsUserUIContext();

  useEffect(() => {
    setSearch(query.get('search') ?? '');

    // ReportsUserTable.js içinden filtreler temizlendiğinde license filtresindeki seçimin güncellenmesi için
    !UIProps?.queryParams?.filter?.license && setLicenseFilterValue([]);
    // // ReportsUserTable.js içinden filtreler temizlendiğinde status filtresindeki seçimin güncellenmesi için
    !UIProps?.queryParams?.filter?.stat && setStatusFilterValue([]);

  }, [UIProps.queryParams.filter.license, UIProps.queryParams.filter.stat, query])

  const handleSearch = (values) => {
    setSearch(values);
    UIProps.setSearchQuery(values);
  }

  const filteredConnections = CONNECTION_SETTINGS
    .filter(token => token.active && !['dhl_warenpost', 'dhl_new_warenpost'].includes(token.name))
    .sort((a, b) => {
      const typeOrder = { marketplace: 0, shop: 1, shipper: 2 };
      const typeComparison = typeOrder[a.type] - typeOrder[b.type];
      if (typeComparison === 0) {
        return a.name.localeCompare(b.name);
      }
      return typeComparison;
    });

  const defaultConnectionFilterValues = filteredConnections.reduce((acc, item) => {
    acc[item.name] = UIProps.queryParams?.filter?.connection?.[item.name] ? true : false;
    return acc;
  }, {});

  const [connectionFilterValue, setConnectionFilterValue] = useState(defaultConnectionFilterValues);

  const handleConnectionFilterChange = (updatedFilters) => {
    setConnectionFilterValue(updatedFilters);
    UIProps.setFilterQuery(updatedFilters, "connection");
  };

  // License Filtresi fonksiyonları
  const defaultLicenseFilterValues = {
    trial: UIProps.queryParams?.filter?.license?.trial ? true : false,
    premium: UIProps.queryParams?.filter?.license?.premium ? true : false,
    development: UIProps.queryParams?.filter?.license?.development ? true : false,
    tester: UIProps.queryParams?.filter?.license?.tester ? true : false,
    test_user: UIProps.queryParams?.filter?.license?.test_user ? true : false,
    standard: UIProps.queryParams?.filter?.license?.standard ? true : false,
    all_licenses: UIProps.queryParams?.filter?.license?.all_licenses ? true : false,
  }

  const [licenseFilterValue, setLicenseFilterValue] = useState(defaultLicenseFilterValues);

  const handleLicenseFilterChange = (updatedFilters) => {
    setLicenseFilterValue(updatedFilters);
    UIProps.setFilterQuery(updatedFilters, "license");
  };

  // Status Filtresi fonksiyonları
  const defaultStatusFilterValues = {
    active: UIProps.queryParams?.filter?.stat?.active ? true : false,
    passive: UIProps.queryParams?.filter?.stat?.passive ? true : false,
    deleted: UIProps.queryParams?.filter?.stat?.deleted ? true : false,
    first_login: UIProps.queryParams?.filter?.stat?.first_login ? true : false,
    all_statuses: UIProps.queryParams?.filter?.stat?.all_statuses ? true : false,
  }

  const [statusFilterValue, setStatusFilterValue] = useState(defaultStatusFilterValues);

  const handleStatusFilterChange = (updatedFilters) => {
    setStatusFilterValue(updatedFilters);
    UIProps.setFilterQuery(updatedFilters, "stat");
  };

  return (
    <Row className="mb-3">

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start justify-content-start px-md-0'>

        <MultipleFilter
          icon={<Icon.Award />}
          title={<FormattedMessage id='HEADER.LICENSE' />}
          items={LICENSE_TYPE}
          itemNamePlural={<FormattedMessage id="GENERAL.FILTER.LICENSES" />}
          noItemMessage={<FormattedMessage id="GENERAL.NO_OPTIONS_AVAILABLE" />}
          selectedFilters={licenseFilterValue}
          onFilterChange={handleLicenseFilterChange}
        />

        <MultipleFilter
          icon={<Icon.Person />}
          title={<FormattedMessage id='GENERAL.STATUS' />}
          items={ACCOUNT_STATUS}
          itemNamePlural={<FormattedMessage id="GENERAL.FILTER.STATUSES" />}
          noItemMessage={<FormattedMessage id="GENERAL.NO_OPTIONS_AVAILABLE" />}
          selectedFilters={statusFilterValue}
          onFilterChange={handleStatusFilterChange}
        />

        <MultipleFilter
          icon={<Icon.Connection />}
          title={<FormattedMessage id="MENU.ASIDE.CONNECTIONS" />}
          items={filteredConnections}
          itemNamePlural={<FormattedMessage id="MENU.ASIDE.CONNECTIONS" />}
          noItemMessage={<FormattedMessage id="GENERAL.NO_OPTIONS_AVAILABLE" />}
          selectedFilters={connectionFilterValue}
          onFilterChange={handleConnectionFilterChange}
        />

        {/* ---------------------------------- Date filter  ---------------------------------- */}
        <DatePickerDropdown UIContext={UIContext} setFilterQuery={UIProps.setFilterQuery} />

      </Col>

    </Row>
  );
}
