import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { BlankMessage } from '_metronic/_partials/components';
import { UserInfoDropdown } from '_metronic/_partials';
import { Icon } from '_metronic/_icons';
import EnterPaymentBody from './enter-payment-dialog/EnterPaymentBody';



export function PaymentCard({ documentId, handleOpenEnterPaymentEditDialog }) {

  const userInvoiceForEdit = useSelector(state => state.adminUserInvoices.userInvoiceForEdit);

  return (

    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.Cash />}
        title={<FormattedMessage id="DOCUMENT.PAYMENT_TITLE" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='DOCUMENT.PAYMENT.INFO_TITLE' />}
          description={<FormattedMessage id='DOCUMENT.PAYMENT.INFO_DESCRIPTION' />} />}
      >


        <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => handleOpenEnterPaymentEditDialog()} id="btn_payment">
          <Icon.Plus />
          <span className="d-none d-sm-inline font-weight-bold ml-2">
            <FormattedMessage id='DOCUMENT.PAYMENT.ENTER_TITLE' />
          </span>
        </Button>

      </CardSubHeader>

      <CardBody className="pt-0 px-0">

        {userInvoiceForEdit.position?.length
          ?
          <EnterPaymentBody documentId={documentId} />
          : <BlankMessage
            icon={<Icon.Cash />}
            title={<FormattedMessage id='DOCUMENT.POSITION.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='DOCUMENT.POSITION.BLANK_MESSAGE' />}
            arrow
          />
        }

      </CardBody>

    </Card>
  );
}
