import ActionTypes from './actionTypes';


export class AdminUsersReportActions {

  // Get all users
  static getReportsUsers = (dateRange, queryParams) => {
    return {
      type: ActionTypes.GET_REPORTS_USERS,
      payload: {
        dateRange,
        queryParams
      },
    };
  };

  static getReportsUsersSuccess = (users, count) => {
    return {
      type: ActionTypes.GET_REPORTS_USERS_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getReportsUsersFail = (error) => {
    return {
      type: ActionTypes.GET_REPORTS_USERS_FAIL,
      payload: {
        error
      },
    };
  };

  // Users Action Creators
  static cleanUpUserModule = () => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE,
    };
  };

  static cleanUpUserModuleParams = (params) => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE_PARAMS,
      payload: {
        params
      },
    };
  };

}