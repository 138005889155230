import React, { useEffect, useMemo, useState } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { SelectCounter } from '_metronic/_partials';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import AmazonListingsGrouping from './AmazonListingsGrouping';



export function AmazonListingsFilter({ queryParams, setSearchQuery }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');
  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    market_id: UIContext.market_id,
  }),
    [UIContext.ids, UIContext.market_id]
  );

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setSearchBase(e.target.value);
  };


  return (
    <Row className="mb-3">

      <Col lg="4">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col md="8" className='d-flex align-items-start px-md-0'>

        <SelectCounter count={UIProps.ids.length} />

        <AmazonListingsGrouping UIProps={UIProps} queryParams={queryParams} />

      </Col>

    </Row>
  );
}
