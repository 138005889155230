import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Table, Col, Row } from 'react-bootstrap';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { getCountryList, UIDates, convertPriceCurrency } from '_metronic/_helpers';
import * as UIHelpers from '../../../modules/documents/_context/DocumentsUIHelpers';
import { useUserInvoicesUIContext } from '../_context/UserInvoicesUIContext';
import { getPositionLabel } from '../_context/UserInvoicesUIHelpers';
import { AdminUserInvoicesActions } from '../_redux/actions';
import PerfectScrollbar from "react-perfect-scrollbar";
import { AdminRoutes } from 'constants/moduleRoutes';
import { Link, useHistory } from 'react-router-dom';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isArray } from 'lodash';
import { useLang } from '_metronic/i18n';
import { Portal } from 'react-portal';



export function InvoiceDetailsDialog() {

  const locale = useLang();
  const dispatch = useDispatch();
  const history = useHistory();

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  const UIContext = useUserInvoicesUIContext();
  const UIDocContext = useDocumentsUIContext();

  const UIProps = useMemo(() => ({
    show: UIContext.showDocumentDetailsDialog,
    onHide: UIContext.closeDocumentDetailsDialog,
    documentId: UIContext.documentId,
    openEditDocumentDialog: UIContext.openEditDocumentDialog,
    requestedDocument: UIDocContext.requestedDocument,
  }), [UIContext, UIDocContext]);


  const userInvoice = useSelector(state => state.adminUserInvoices.userInvoiceForEdit);

  const { name, email, phone, company, invoice_address, discount: customerDiscount } = userInvoice?.customer_details;

  const currency = userInvoice?.pricing_summary?.currency;

  // yerelde oluşturulan sipariş indirim oranı (%)
  const discount = userInvoice?.pricing_summary?.discount ?? customerDiscount ?? 0;

  // apiden gelen siparişlerdeki indirim tutarı (xx EUR) (ebay için yapıldı sadece)
  const marketPriceDiscount = userInvoice?.pricing_summary?.total_price_discount ?? 0;

  const calculateDiscountTaxTotal = userInvoice?.position?.reduce((totalTax, position) => {
    const positionPriceDiscount = position.price_discount ?? 0;
    if (positionPriceDiscount === 0) {
      return totalTax;
    }

    const taxRate = position.tax_rate ?? 0;
    return totalTax + positionPriceDiscount - positionPriceDiscount / (1 + taxRate / 100);
  }, 0);

  const rest = convertPriceCurrency(+totalPrice - +totalPaid, currency);

  useEffect(() => {
    UIProps?.documentId &&
      dispatch(AdminUserInvoicesActions.getUserInvoiceById(UIProps?.documentId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps?.documentId, dispatch]);

  // if !id we should close modal
  useEffect(() => {
    !UIProps.documentId && UIProps.onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps]);

  useEffect(() => {
    let totalPayment = 0;
    if (isArray(userInvoice.entered_payment)) {
      const paid = userInvoice.entered_payment.reduce((acc, payment) => acc + payment.amount, 0);
      totalPayment = paid;
    }
    const totalPrice = (+userInvoice.pricing_summary.total - (discount ?? 0) * (+userInvoice.pricing_summary.total / 100));
    setTotalPaid(totalPayment);
    setTotalPrice(totalPrice);
  }, [userInvoice.entered_payment, userInvoice.pricing_summary, discount]);

  useEffect(() => {
    return () => {
      dispatch(AdminUserInvoicesActions.cleanUpUserModuleParams({ userInvoiceForEdit: {} }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" size='lg'>

        <Modal.Body className="font-weight-bold px-10 pt-10 pt-md-25">

          <CloseButton onClick={UIProps.onHide} className="position-absolute top-0 right-0 mr-10 mt-10" />

          {userInvoice && !isEmpty(userInvoice) && (
            <>

              {/* --------------------------------- CUSTOMER --------------------------------- */}

              <div className="d-flex justify-content-between flex-column flex-md-row">
                <h1 className="font-weight-boldest display-4 text-nowrap">
                  <FormattedMessage id='DOCUMENT.DETAIL_DIALOG.TITLE' values={{ title: UIProps.requestedDocument.title }} />
                </h1>
                <div className="d-flex flex-column align-items-md-end px-0 pr-5">
                  <span className="d-flex flex-column text-md-right opacity-70">
                    <Link to={AdminRoutes.ADMIN_USERS_EDIT_FN(userInvoice?.user_id)} type="button" className="text-decoration-none text-hover-primary" onClick={UIProps.onHide} id="btn_customer_preview">
                      {name}
                    </Link>
                    <span className='text-break'>{company}</span>
                    <span>{phone}</span>
                    <span>{email}</span>
                  </span>
                </div>
              </div>

              <div className="separator separator-dashed my-5 my-md-10" />

              <PerfectScrollbar options={{ wheelSpeed: 1, wheelPropagation: false }} className="scroll" style={{ maxHeight: "calc(100vh - 400px)", minHeight: "200px" }}>


                {/* --------------------------------- ORDER SUMMARY --------------------------------- */}

                <Row className="d-flex justify-content-between pl-5 mx-0">

                  <Col xs="6" md="3" className="py-5">
                    <div className="font-weight-bolder text-uppercase mb-2">
                      <FormattedMessage id="DOCUMENT.TABLE.COLUMN.DOCUMENT_ID" values={{ title: UIProps.requestedDocument.title }} />
                    </div>
                    <div className="opacity-70">
                      {userInvoice?.invoice_number}
                    </div>
                  </Col>

                  <Col xs="6" md="3" className="py-5">
                    <div className="text-center font-weight-bolder text-uppercase mb-2">
                      <FormattedMessage id="DOCUMENT.DETAIL_DIALOG.INVOICE.DATE"
                      />
                    </div>
                    <div className="text-center opacity-70">
                      {UIDates.formatDateTime(userInvoice?.created_at)}
                    </div>
                  </Col>


                  <Col xs="6" md="3" className="py-5">
                    <div className="font-weight-bolder text-uppercase text-right mb-2">
                      <FormattedMessage id="DOCUMENT.DETAIL_DIALOG.DELIVERY_TO" />
                    </div>
                    <div className="text-right opacity-70">
                      <div>{invoice_address?.address_line1} {invoice_address?.address_line2}</div>
                      <div>{invoice_address?.post_code} {invoice_address?.city}</div>
                      <div>{getCountryList(locale).find(code => code.value === invoice_address?.country_code)?.label}</div>
                    </div>
                  </Col>

                </Row>

                <div className="separator separator-dashed my-5 my-md-10" />


                {/* --------------------------------- POSITIONS --------------------------------- */}
                {userInvoice.position?.length > 0 && <>
                  <div className="table-responsive">
                    <Table className='mb-0'>
                      <thead>
                        <tr>
                          <th className="font-weight-bold text-muted text-uppercase pl-5">
                            <FormattedMessage id="GENERAL.DESCRIPTION" />
                          </th>
                          <th className="font-weight-bold text-center text-muted text-uppercase">
                            <FormattedMessage id="GENERAL.COUNT" />
                          </th>
                          <th className="font-weight-bold text-right text-muted text-uppercase">
                            <FormattedMessage id="GENERAL.UNIT_PRICE" />
                          </th>

                          {(marketPriceDiscount !== 0 || discount !== 0) &&
                            <th className="font-weight-bold text-right text-muted text-uppercase">
                              <FormattedMessage id="GENERAL.DISCOUNT_VALUE" values={{ name: marketPriceDiscount !== 0 ? `${convertPriceCurrency(-marketPriceDiscount, currency)}` : `${discount} %` }} />
                            </th>
                          }

                          <th className="font-weight-bold text-right text-muted text-uppercase pr-5">
                            <FormattedMessage id="DOCUMENT.POSITION_TOTAL_PRICE" />
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {userInvoice.position.map((position, index) => (
                          <tr className="font-weight-boldest" key={index}>
                            <td className="d-flex flex-column pt-3 pl-5">
                              <span>{getPositionLabel(position?.title)}</span>
                              <span className="font-weight-bold text-dark-25">
                                {position.sku}
                              </span>
                            </td>
                            <td className="text-center align-middle pt-3">
                              {position.quantity}
                            </td>
                            <td className="text-right align-middle pt-3">
                              {convertPriceCurrency(+position?.gross_price, currency)}
                            </td>

                            {(marketPriceDiscount !== 0 || discount !== 0) &&
                              <td className="text-right align-middle pt-3">
                                {convertPriceCurrency(-(marketPriceDiscount !== 0 ? position.price_discount : (discount * (+position.gross_price / 100))), currency)}
                              </td>
                            }

                            <td className="text-primary text-right align-middle pt-3 pr-5">
                              {convertPriceCurrency(+position?.total_gross_price, currency)}
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </Table>
                  </div>

                  <div className="separator separator-dashed my-5 my-md-10" />
                </>
                }


                {/* --------------------------------- PAYMENT SUMMARY --------------------------------- */}

                <Row className="d-flex justify-content-between font-weight-bold bg-gray-100 pl-5 py-5 px-6 mx-0">

                  <Col xs="6" md="3" className="text-left text-md-center py-5 px-0">
                    <div className="text-muted text-uppercase border-bottom text-lg-left text-md-left text-sm-left pb-5 mb-5">
                      <FormattedMessage id="DOCUMENT.POSITION_TOTAL_NET" />
                    </div>
                    <div className="font-weight-bolder text-lg-left text-md-left text-sm-left">
                      {convertPriceCurrency(+userInvoice?.pricing_summary?.sub_total, currency)}
                    </div>
                  </Col>

                  {(marketPriceDiscount !== 0 || discount !== 0) &&
                    <Col xs="6" md="2" className="text-right text-md-center py-5 px-0">
                      <div className="text-muted text-uppercase border-bottom pb-5 mb-5 text-nowrap">
                        <FormattedMessage id="GENERAL.DISCOUNT_VALUE" values={{ name: marketPriceDiscount !== 0 ? `Br.: ${convertPriceCurrency(-marketPriceDiscount, currency)}` : `${discount} %` }} />
                      </div>
                      <div className="font-weight-bolder">
                        {convertPriceCurrency((marketPriceDiscount !== 0 ? (marketPriceDiscount + calculateDiscountTaxTotal) : (discount * -(+userInvoice?.pricing_summary?.sub_total / 100))), currency)}
                      </div>
                    </Col>
                  }

                  <Col xs="6" md="2" className="text-md-center py-5 px-0">
                    <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                      <FormattedMessage id="DOCUMENT.POSITION_TOTAL_ITEM" />
                    </div>
                    <div className="font-weight-bolder">
                      {convertPriceCurrency((+userInvoice?.pricing_summary?.total_tax - (discount ?? 0) * (+userInvoice?.pricing_summary?.total_tax) / 100) - calculateDiscountTaxTotal, currency)}
                    </div>
                  </Col>
                  {/* <Col xs="6" md="2" className="text-right text-md-center py-5 px-0">
                    <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                      <FormattedMessage id="DOCUMENT.SHIPPING_PRICE" />
                    </div>
                    <div className="font-weight-bolder">
                      {convertPriceCurrency(userInvoice?.pricing_summary?.shipping_price, currency)}
                    </div>
                  </Col> */}

                  <Col xs="6" md="2" className="py-5 px-0">
                    <div className="text-muted text-uppercase border-bottom text-lg-right text-md-left text-sm-left pb-5 mb-5">
                      <FormattedMessage id="DOCUMENT.POSITION_TOTAL_AMOUNT" />
                    </div>
                    <div className="font-weight-boldest text-primary font-size-h3 text-lg-right text-md-left text-sm-left">
                      {convertPriceCurrency((userInvoice?.pricing_summary?.total - (discount ?? 0) * (userInvoice?.pricing_summary?.total) / 100) + marketPriceDiscount, currency)}
                    </div>
                  </Col>
                </Row>


                {/* --------------------------------- OPEN PAYMENTS --------------------------------- */}

                {userInvoice.payment_status !== 'paid'
                  ? <>
                    <div className="separator separator-dashed my-5 my-md-10" />
                    <Row className="d-flex justify-content-between font-weight-bold bg-danger-o-20 pl-5 py-5 px-6 mx-0">

                      <Col xs="6" md="2" className="py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                          <FormattedMessage id="DOCUMENT.PAYMENT_STATUS" />
                        </div>
                        <div className="font-weight-bolder">
                          {UIHelpers.getPaymentStatus(userInvoice.payment_status)?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
                        </div>
                      </Col>

                      <Col xs="6" md="2" className="text-right text-md-center py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5 text-nowrap">
                          <FormattedMessage id="DOCUMENT.PAYMENT.AMOUNT_PAID" />
                        </div>
                        <div className="font-weight-bolder">
                          {convertPriceCurrency(totalPaid, currency)}
                        </div>
                      </Col>

                      <Col xs="6" md="2" className="text-md-center py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom pb-5 mb-5">
                          <FormattedMessage id="DOCUMENT.TABLE.COLUMN.OPEN_AMOUNT" />
                        </div>
                        <div className="font-weight-bolder">
                          {convertPriceCurrency((rest <= 0 ? 0 : rest), currency)}
                        </div>
                      </Col>

                      <Col xs="6" md="2" className="py-5 px-0">
                        <div className="text-muted text-uppercase border-bottom text-lg-right text-md-left text-sm-left pb-5 mb-5">
                          <FormattedMessage id="DOCUMENT.POSITION_TOTAL_AMOUNT" />
                        </div>
                        <div className="font-weight-boldest text-primary font-size-h3 text-lg-right text-md-left text-sm-left">
                          {convertPriceCurrency(totalPrice, currency)}
                        </div>
                      </Col>
                    </Row>
                  </>
                  : null
                }
              </PerfectScrollbar>
            </>
          )}

        </Modal.Body>

        <Modal.Footer>

          <Button variant="light" onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" className="ml-2" onClick={() => { history.push(AdminRoutes.DOCUMENT_UI_CONTEXT_EDIT_FN(UIProps.requestedDocument.type, UIProps?.documentId)); UIProps.onHide() }} id="btn_edit">
            <FormattedMessage id="GENERAL.EDIT" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};