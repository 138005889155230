/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



const DropdownCustomToggler = forwardRef((props, ref) => {
  return (
    <a ref={ref} className="btn btn-clean btn-primary btn-sm btn-icon" style={{ height: '2rem' }} onClick={e => { e.preventDefault(); props.onClick(e) }}>
      {props.children}
    </a>
  );
});

export function ResponsiveActionFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openPrintDocumentDialog,
    openEditDocumentDialog,
    openDeleteDocumentDialog,
    openPaymentReminderDialog,
    openEnterPaymentDialog,
    copyDocumentForCreateNewOne,
    openDocumentConvertToCredit,
    openDocumentConvertToWaybill,
    openDocumentConvertToInvoice,
    openDocumentConvertToOrder,
    openLabelSettingDialog,
    documentConvertHandler,
    openExportDocumentDialog,
    documentTitle,
    documentType,
  }
) {

  const isConvert = (action, document_to) => {
    return row?.history?.find(history => history.action === action && history.document_to === document_to)?.relation_id ?? false;
  }

  return (

    <Dropdown className="dropdown-inline d-none d-md-inline my-3" drop="down" alignRight>

      <Dropdown.Toggle variant="transparent" id="dropdown-toggle-top" as={DropdownCustomToggler}>
        <Icon.ThreeDots className='icon-lg' />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu dropdown-menu-right global-dropdowc-cls" id='dropdown_document_formatter'>
        <ul className="navi navi-hover">

          {openLabelSettingDialog && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => openLabelSettingDialog(row.id)} id={`dropdown_item_label_${row.id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.Box />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.LABEL_TITLE' />
                </span>
              </Dropdown.Item>
            </li>
          )}

          <li className="navi-item">
            <Dropdown.Item className="navi-link" onClick={() => openPrintDocumentDialog(row.id)} id={`dropdown_item_print_${row.id}`}>
              <span className="svg-icon svg-icon-md mr-2">
                <Icon.Printer />
              </span>
              <span className="navi-text text-nowrap font-weight-bold">
                <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.PRINT' values={{ documentTitle: documentTitle }} />
              </span>
            </Dropdown.Item>
          </li>

          <li className="navi-item">
            <Dropdown.Item className="navi-link" onClick={() => openEditDocumentDialog(row.id)} id={`dropdown_item_edit_${row.id}`}>
              <span className="svg-icon svg-icon-md mr-2">
                <Icon.PencilSquare />
              </span>
              <span className="navi-text text-nowrap font-weight-bold">
                <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.EDIT' values={{ documentTitle: documentTitle }} />
              </span>
            </Dropdown.Item>
          </li>

          <li className="navi-item">
            <Dropdown.Item className="navi-link" onClick={() => copyDocumentForCreateNewOne(row.id)} id={`dropdown_item_copy_${row.id}`}>
              <span className="svg-icon svg-icon-md mr-2">
                <Icon.Copy />
              </span>
              <span className="navi-text text-nowrap font-weight-bold">
                <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.COPY' values={{ documentTitle: documentTitle }} />
              </span>
            </Dropdown.Item>
          </li>

          {openDocumentConvertToCredit && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => documentConvertHandler(row.id, documentType, 'credits', isConvert('convert_document', 'credit'))} id={`dropdown_item_credit"_${row.id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.FileEarmarkText />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.CREDIT_INVOICE' />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {openDocumentConvertToWaybill && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => documentConvertHandler(row.id, documentType, 'waybills', isConvert('convert_document', 'waybill'))} id={`dropdown_item_waybill"_${row.id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.FileEarmarkText />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.DELIVERY' values={{ documentTitle: documentTitle }} />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {openDocumentConvertToInvoice && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => documentConvertHandler(row.id, documentType, 'invoices', isConvert('convert_document', 'invoice'), isConvert('create', 'invoice'))} id={`dropdown_item_fulfillment_${row.id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.FileEarmarkText />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.INVOICE' values={{ documentTitle: documentTitle }} />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {openDocumentConvertToOrder && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => documentConvertHandler(row.id, documentType, 'orders', isConvert('convert_document', 'order'))} id={`dropdown_item_order_${row.id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.FileEarmarkText />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.ORDER' values={{ documentTitle: documentTitle }} />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {openEnterPaymentDialog && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => openEnterPaymentDialog(row.id)} id={`dropdown_item_payment_${row.id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.CreditCard />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.ACTION.PAYMENTS' />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {openPaymentReminderDialog && (
            <div className={row.payment_status === 'paid' ? 'd-none' : ""}>
              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => openPaymentReminderDialog(row.id, 'reminder')} id={`dropdown_item_reminder_${row.id}`}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.Bell />
                  </span>
                  <span className="navi-text text-nowrap font-weight-bold">
                    <FormattedMessage id='DOCUMENT.ACTION.PAYMENT_REMINDER' />
                  </span>
                </Dropdown.Item>
              </li>

              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => openPaymentReminderDialog(row.id, 'warning_1')} id={`dropdown_item_warning1_${row.id}`}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.AppIndicator />
                  </span>
                  <span className="navi-text text-nowrap font-weight-bold">
                    <FormattedMessage id='DOCUMENT.ACTION.REMINDER1' />
                  </span>
                </Dropdown.Item>
              </li>

              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => openPaymentReminderDialog(row.id, 'warning_2')} id={`dropdown_item_warning2_${row.id}`}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.AppIndicator />
                  </span>
                  <span className="navi-text text-nowrap font-weight-bold">
                    <FormattedMessage id='DOCUMENT.ACTION.REMINDER2' />
                  </span>
                </Dropdown.Item>
              </li>
            </div>
          )}


          {openDeleteDocumentDialog && (
            <>
              <li className="navi-separator my-1" />
              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => openDeleteDocumentDialog([row.id], "archive")} id={`dropdown_item_archive_${row.id}`}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.Archive />
                  </span>
                  <span className="navi-text text-nowrap font-weight-bold">
                    <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.SEND_TO_ARCHIVE' values={{ documentTitle: documentTitle }} />
                  </span>
                </Dropdown.Item>
              </li>
              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => openDeleteDocumentDialog([row.id], "delete")} id={`dropdown_item_archive_${row.id}`}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.Trash />
                  </span>
                  <span className="navi-text text-nowrap font-weight-bold">
                    <FormattedMessage id='GENERAL.DELETE' values={{ documentTitle: documentTitle }} />
                  </span>
                </Dropdown.Item>
              </li>
            </>
          )}

        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}