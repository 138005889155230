import { API } from 'constants/apiUrl';



export const connectionsMiddleware = {

  getConnections: async function (accessToken, token_type) {
    return await API.get(`/connections`, {
      params: {
        ...(token_type && {
          filter: {
            where: {
              token_type: {
                inq: token_type
              },
            }
          }
        })
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  connectionCheck: async function (accessToken, tokenId) {
    return await API.get(`/connections/test/${tokenId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  connectionFetchOrders: async function (accessToken, market_id) {
    return await API.get(`/connections/fetch-orders/${market_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  createSettings: async function (accessToken, settings) {
    return await API.post(`/connections`, settings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  updateSettings: async function (accessToken, settings) {
    return await API.put(`/connections/${settings.id}`, settings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteSettings: async function (accessToken, settingsType, settingsId) {
    return await API.delete(`/connections/${settingsType}/${settingsId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getSettingById: async function (accessToken, settingId) {
    return await API.get(`/connections/${settingId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },



  // ----------------- DEUTSCHE POST ----------------- //

  getDeutschePostConfigs: async function (accessToken) {
    return await API.get(`/connections/deutsche-post-configs`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getOttoToken: async function (accessToken, sandbox, callType, code) {
    return await API.get(`/connections/otto-token`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        sandbox,
        callType,
        code
      },
    });
  },


  saveOttoToken: async function (accessToken, code, sandbox) {
    const body = { code: code, sandbox: sandbox };
    return await API.post(`/connections/otto-save-token`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  },


  // ---------------------- EBAY ---------------------- //

  getEbayToken: async function (accessToken) {
    return await API.get(`/connections/ebay-token`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  },


  saveEbayToken: async function (accessToken, code) {
    return await API.post(`/connections/ebay-save-token`, code, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  },


  // ---------------------- AMAZON ---------------------- //

  getAmazonTokenQuery: async function (accessToken) {
    return await API.get(`/connections`, {
      params: {
        filter: {
          where: {
            name: { like: 'Amazon', options: 'i' },
            token_type: 'Shop',
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  // ---------------------- ETSY ---------------------- //

  getEtsyAuth: async function (accessToken) {
    return await API.get(`/connections/etsy-token`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  setEtsyToken: async function (accessToken, settings) {
    return await API.post(`/connections/etsy-save-token`, settings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
