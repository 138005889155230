import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { SVReactSelect } from "_metronic/_partials/controls";
import { ConnectionsActions } from "../../../_redux/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { CloseButton } from "_metronic/_partials";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";



export function LayoutSelect({ loading, layouts, connection, show, onHide }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const validationSchema = Yup.object().shape({
    id: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
  });


  const handleSubmit = (values) => {
    const updatedConnection = {
      ...connection,
      deutsche_post: { ...connection.deutsche_post, layout: values?.id },
    };
    dispatch(ConnectionsActions.updateSettings(updatedConnection));
    onHide();
  };

  useEffect(() => {
    if (connection.name === 'deutsche_post' && !layouts) {
      dispatch(ConnectionsActions.getDeutschePostConfigs());
    }
    // eslint-disable-next-line
  }, [connection]);

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-xl" size="md" centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id='CONNECTIONS.SHIPPER.D_POST.LAYOUT' />
        </Modal.Title>
        <CloseButton onClick={onHide} />
      </Modal.Header>

      <Formik enableReinitialize={true} initialValues={{ id: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <Form autoComplete="off">

          <Modal.Body>
            <Field component={SVReactSelect}
              {...{
                name: "id",
                label: "Layouts",
                options: layouts,
                feedbackLabel: true,
                disabled: loading
              }}
            />
          </Modal.Body>


          <Modal.Footer>

            <Button variant="light" onClick={onHide} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>

            <Button variant="primary" type="submit" className="ml-2" id="btn_add_modal">
              <FormattedMessage id="GENERAL.ADD" />
            </Button>

          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  );
}