import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useUserInvoicesUIContext } from '../_context/UserInvoicesUIContext';
import { CloseButton, SelectCounter, Spinner } from '_metronic/_partials';
import * as columnFormatters from './column-formatters/ColumnFormatters';
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { AdminUserInvoicesActions } from '../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoadingHandler } from 'app/hooks';
import { UIDates } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import clsx from "clsx";
import moment from 'moment';



const initialState = {
  isInvoice: true,
  isSendMail: true,
};

export function ConvertingDocumentsDialog() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const [selectedActions, setSelectedActions] = useState(initialState);
  const [processed, setProcessed] = useState(false);


  const { loading, convertDetails } = useSelector(state => ({
    loading: state.documents.actionLoading,
    convertDetails: state.adminUserInvoices.convertDetails,
  }), shallowEqual);

  const UIContext = useUserInvoicesUIContext();
  const UIDocContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    show: UIContext.showConvertDocumentsDialog,
    onHide: UIContext.closeConvertDocumentsDialog,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    requestedDocument: UIDocContext.requestedDocument,
  }), [UIContext, UIDocContext]);


  useEffect(() => {
    if (!selectedActions?.isInvoice) {
      setSelectedActions(prevState => ({ ...prevState, isSendMail: false }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActions?.isInvoice, convertDetails]);

  const onCheckChange = (type) => setSelectedActions(prevState => ({ ...prevState, [type]: !prevState[type] }));
  // const sortedConvertDetails = convertDetails?.sort((a, b) => parseInt(a?.id, 10) - parseInt(b?.id, 10));

  const { isInvoice, isSendMail } = selectedActions;

  const useLoading = useLoadingHandler(
    loading,
    AdminUserInvoicesActions.cleanUpUserModuleParams({ actionLoading: false, convertDetails: [] }),
    150000
  );

  const handleOnHide = () => {
    UIProps.onHide();
    setSelectedActions(initialState);
    setProcessed(false)
    UIProps.setIds([]);
  };

  const convertDocumentsHandler = () => {

    const data = {
      // ids: convertDetails?.sort((a, b) => parseInt(a?.id, 10) - parseInt(b?.id, 10))?.map(i => i.id),
      ids: UIProps.ids,
      selectedActions,
    }

    dispatch(AdminUserInvoicesActions.createUserInvoices(data));
    setProcessed(true)
  };

  const openPrintDocumentDialog = () => {

    if (UIProps?.ids?.length > 0) {
      const data = {
        ids: UIProps.ids,
        user_invoice_action: 'invoices',
      }
      dispatch(AdminUserInvoicesActions.printDocument(data));
      UIProps.openPrintDocumentDialog();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(AdminUserInvoicesActions.cleanUpUserModuleParams({ convertDetails: [] }))
    };
  }, [dispatch, UIProps.show]);

  const COLUMNS = [
    {
      dataField: 'id',
      headerClasses: 'text-left pl-9',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ORDER_ID' }),
      headerStyle: { minWidth: '100px', width: "20%" },
      classes: 'text-left font-weight-bold text-dark-75 text-nowrap py-3 pl-9',
      editable: false,
      formatter: columnFormatters.OrderIdFormatter
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.INVOICE_PERIOD' }),
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bold text-nowrap text-center pr-4',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIDates.formatDateTime(row.created_at)}</Tooltip>}>
          <span>
            {row.created_at && moment(row?.created_at).format('MMMM YYYY')}
          </span>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'payment_status',
      headerClasses: 'text-center',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.PaymentStatusFormatter
    },
    {
      dataField: 'email',
      text: intl({ id: 'GENERAL.EMAIL' }),
      headerStyle: { minWidth: '60px', width: "15%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.EmailFormatter
    },
    {
      dataField: 'invoice',
      text: intl({ id: 'DOCUMENT.INVOICE_PLURAL' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75',
      editable: false,
      formatter: columnFormatters.InvoiceFormatter
    },
    {
      dataField: '#',
      text: "",
      classes: 'hover-menu',
      editable: false,
      hidden: processed,
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: {
        deleteCompleteList: (id) => dispatch(AdminUserInvoicesActions.deleteCompleteList(id))
      },
    }
  ];


  return (
    <Modal show={UIProps.show} onHide={handleOnHide} aria-labelledby="example-modal-sizes-title-lg" size="lg">

      <Modal.Header>
        <Modal.Title>
          <Icon.CheckSquare className="mr-3" />
          <FormattedMessage id="DOCUMENT.CONVERT.TITLE" />
        </Modal.Title>
        <CloseButton onClick={handleOnHide} loading={useLoading} />
      </Modal.Header>

      <Modal.Body className='p-0 overflow-hidden'>

        <PerfectScrollbar className="scroll pr-5 mr-n5" style={{ maxHeight: '390px' }}>
          <BootstrapTable
            keyField="id"
            data={convertDetails || []}
            columns={COLUMNS}
            wrapperClasses="table-responsive"
            headerClasses="bg-info-o-10"
            classes="table table-head-custom table-vertical-center mb-0"
            bootstrap4
            bordered={false}
            deleteRow={true}
            condensed
          />
        </PerfectScrollbar>

        <Row className='border-top px-9 py-5'>

          <Col lg='6'>
            <input type="checkbox" className='d-none' />
            <label className="checkbox checkbox-single mb-3" style={{ pointerEvents: (useLoading || processed) ? "none" : null }}>
              <input type="checkbox" checked={isInvoice} onChange={() => onCheckChange('isInvoice')} disabled={useLoading || processed} id="checkbox_create_invoice" />
              <span className="mr-2" />
              <FormattedMessage id="DOCUMENT.CONVERT.CREATE_INVOICE" />
            </label>
            <input type="checkbox" className='d-none' />
            <label className="checkbox checkbox-single mb-3" style={{ pointerEvents: (useLoading || processed) ? "none" : null }}>
              <input type="checkbox" checked={isSendMail} onChange={() => onCheckChange('isSendMail')} title={!isInvoice ? "Enable 'Create Invoice' to send an email" : ""} disabled={useLoading || processed || !isInvoice} id="checkbox_send_email" />
              <span className="mr-2" />
              <FormattedMessage id="GENERAL.EMAIL.INVOICE" />
            </label>
          </Col>

        </Row>

      </Modal.Body>

      <Modal.Footer>

        <SelectCounter count={convertDetails.length} />

        <Button variant='light' style={useLoading ? { pointerEvents: "none" } : null} className="ml-auto mx-2" onClick={handleOnHide} disabled={useLoading} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CLOSE" />
        </Button>

        {(!processed || useLoading) &&
          <Button
            variant='primary'
            onClick={convertDocumentsHandler}
            disabled={useLoading || !convertDetails.length || (!isInvoice)}
            style={(useLoading || !convertDetails.length) ? { pointerEvents: "none" } : null}
            id='btn_convert_modal'
          >
            <Spinner loading={useLoading} color="light" size="sm" className={clsx(useLoading && 'mr-8')} />
            <FormattedMessage id="DOCUMENT.CONVERT.CREATE_INVOICE" />
          </Button>
        }

        {processed && isInvoice && convertDetails.some(s => s.invoice === true) &&
          <Button variant='primary' style={useLoading ? { pointerEvents: "none" } : null} onClick={() => openPrintDocumentDialog('invoices')} disabled={useLoading} id='btn_convert_waybill_modal'>
            <FormattedMessage id="GENERAL.PRINT.INVOICES" />
          </Button>
        }

      </Modal.Footer>

    </Modal>
  );
}