import React from 'react';
import { Route, useParams } from 'react-router-dom';
import { AmazonSubscriptionsCheckDialog } from './amazon-listings-dialogs/AmazonSubscriptionsCheckDialog';
import { AmazonListingsWithdrawDialog } from './amazon-listings-dialogs/AmazonListingsWithdrawDialog';
import { AmazonListingsPublishDialog } from './amazon-listings-dialogs/AmazonListingsPublishDialog';
import { AmazonRestrictionsDialog } from './amazon-listings-dialogs/AmazonRestrictionsDialog';
import { AmazonListingsCard } from './AmazonListingsCard';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { getLastSearch } from '_metronic/_helpers';



export function AmazonListingsPage() {

  const lastSearch = getLastSearch()
  const params = useParams()

  return (
    <>
      {/* Deactivate Lsting dialog */}
      <Route path={ModuleRoutes.AMAZON_LISTINGS_DEACTIVATE}>
        {({ history, match }) => (
          <AmazonListingsWithdrawDialog
            show={match != null && match.params.id !== undefined}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.AMAZON_LISTINGS_FN(params.tokenId) + lastSearch)}
            marketId={params.tokenId}
          />
        )}
      </Route>

      {/* Restrictions Isuues Dialog */}
      <Route path={ModuleRoutes.AMAZON_LISTINGS_RESTRICTIONS}>
        {({ history, match }) => (
          <AmazonRestrictionsDialog
            show={match != null && match.params.id !== undefined}
            id={match && match.params.id}
            onHide={() => history.push(ModuleRoutes.AMAZON_LISTINGS_FN(params.tokenId) + lastSearch)}
            marketId={params.tokenId}
          />
        )}
      </Route>

      {/* Publish Items Dialog */}
      <Route path={ModuleRoutes.AMAZON_LISTINGS_PUBLISH}>
        {({ history, match }) => (
          <AmazonListingsPublishDialog
            show={match != null && match.params.ids !== undefined}
            ids={match && match.params.ids.split('__')}
            onHide={() => history.push(ModuleRoutes.AMAZON_LISTINGS_FN(params.tokenId) + lastSearch)}
            marketId={params.tokenId}
          />
        )}
      </Route>

      {/* Subscriptions check Dialog */}
      <Route path={ModuleRoutes.AMAZON_LISTINGS_SUBSCRIPTIONS}>
        {({ history, match }) => (
          <AmazonSubscriptionsCheckDialog
            show={match != null && match.params.ids !== undefined}
            ids={match && match.params.ids.split('__')}
            onHide={() => history.push(ModuleRoutes.AMAZON_LISTINGS_FN(params.tokenId) + lastSearch)}
            marketId={params.tokenId}
          />
        )}
      </Route>

      <AmazonListingsCard />
    </>
  );
}
