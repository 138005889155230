import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';



const searchKeys = [
  'email',
  'name',
  'type',
];

export const reportMiddleware = {

  getOrderList: async function (accessToken, dateRange, { pagination, filter, order, search }) {
    return await API.get(`/order-list`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [order ? order : 'created_at DESC'],
          where: {
            ...((dateRange.date_from && dateRange.date_to) && {
              created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
            }),
            ...(search && { search }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getEmailLogs: async function (accessToken, dateRange, { pagination, filter, order, search }) {
    const { email_type, date_from, date_to, ...rest } = filter;

    return await API.get(`/email-logs`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [order ? order : 'created_at DESC'],
          where: {
            ...((dateRange.date_from && dateRange.date_to) && {
              created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
            }),
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && {
              ...rest,
              ...(email_type !== 'all_types' && {
                type: email_type,
              }),
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getEmailLogsCount: async function (accessToken, dateRange, { pagination, filter, order, search }) {
    const { email_type, date_from, date_to, ...rest } = filter;
    return await API.get(`/email-logs/count`, {
      params: {
        where: {
          ...((dateRange.date_from && dateRange.date_to) && {
            created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
          }),
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
          ...(filter && {
            ...rest,
            ...(email_type !== 'all_types' && {
              type: email_type,
            }),
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
