import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  reports: [],
  success: false,
  count: 0,
  totalCount: 0,
  error: null,
};


export const adminUsersReportsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {


    // Get all users reports
    case ActionTypes.GET_REPORTS_USERS: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.GET_REPORTS_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        reports: payload.users,
        count: payload.count.count,
        totalCount: payload.count.total,
      };
    }

    case ActionTypes.GET_REPORTS_USERS_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    // Clean up the user module
    case ActionTypes.CLEAN_UP_USER_MODULE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case ActionTypes.CLEAN_UP_USER_MODULE_PARAMS: {
      return {
        ...state,
        ...payload.params,
      };
    }


    default:
      return state;
  }
};
