import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { useSelector } from 'react-redux';

function AmazonRestrictionsDialog({ id, show, onHide, marketId }) {
  // if !id we should close modal
  useEffect(() => {
    !id && onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const product = useSelector((state) => {
    const { products } = state.listings;
    const product = products.find((product) => product.id === id);
    return product;
  });

  const restrictions = product && Array.isArray(product.inventories) ? product.inventories.find((item) => item.market_id === marketId)?.restrictions : [];

  return (
    <Portal node={document && document.getElementById('layout-portal')}>
      <Modal show={show} onHide={onHide} size="md" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>
        <Modal.Body className="line-height-md h6 py-10 mb-0">
          <div className="mt-3">
            {restrictions &&
              restrictions.length > 0 &&
              restrictions.map((item, r_index) => (
                <div key={`restriction-${r_index}`} className="mt-3 card card-box p-2">
                  {/* <h3>{item.conditionType}</h3> */}
                  <div className="d-flex flex-row">
                    <span className="fw-bold">
                      <FormattedMessage id="AMAZON_LISTINGS.RESTRICTION_CONDITION" defaultMessage="Condition:" />
                    </span>
                    <span className="ml-2">{item.conditionType}</span>
                  </div>
                  {item.reasons &&
                    item.reasons.map((reason, reason_index) => (
                      <div key={`restriction-reason-${reason_index}`} className="mt-3 d-flex flex-column">
                        <p>
                          <FormattedMessage id="AMAZON_LISTINGS.RESTRICTION_REASON_CODE" defaultMessage="Reason Code:" />
                          <span>{reason.reasonCode}</span>
                        </p>
                        <p>{reason.message}</p>

                        {reason.links && reason.links.length > 0 && (
                          <div className="mt-2">
                            <FormattedMessage id="AMAZON_LISTINGS.RESTRICTION_LINKS" defaultMessage="Links:" />
                            <ul>
                              {reason.links.map((link, link_index) => (
                                <li key={`restriction-reason-link-${link_index}`} className="mt-2">
                                  <a href={link?.resource || '#'} target="_blank" rel="noopener noreferrer">
                                    {link?.title}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">
          <Button variant="ios" className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </Modal.Footer>
      </Modal>
    </Portal>
  );
}

export { AmazonRestrictionsDialog };
