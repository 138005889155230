import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardSubHeader } from "_metronic/_partials/controls";
import { UserGuideHelper } from "app/user-guides/UserGuideHelper";
import { BlankMessage } from "_metronic/_partials/components";
import { UpdateProductsTable } from "./UpdateProductsTable";
import { importExportActions } from "../../_redux/actions";
import { checkImportProduct } from "./UpdateProductHelper";
import { CardBody } from "_metronic/_partials/controls";
import { ModuleRoutes } from "constants/moduleRoutes";
import { EditErrorDialog } from "./EditErrorDialog";
import { FormattedMessage } from "react-intl";
import { useLang } from "_metronic/i18n";
import { Icon } from '_metronic/_icons';



export default function UpdateProductPreparation({ submitBtnRef }) {

  const dispatch = useDispatch();
  const history = useHistory();
  const language = useLang();
  const [openErrorDialog, setOpenErrorDialog] = useState({ show: false, errors: [] });
  const [productsCreate, setProductsCreate] = useState([]);

  const { importPreparation, checkData, isImport } = useSelector((state) => ({
    importPreparation: state.importExport.importPreparation,
    checkData: state.importExport.checkData,
    isImport: state.importExport.isImport,
  }));

  const closeErrorDialog = () => setOpenErrorDialog({ show: false, errors: [] });

  const handlerProduct = () => {

    // variantlı ve variantsız ürünlerin ayrılması
    const data = []
    const unique = [...new Set(importPreparation.map(item => item.sku))];
    for (const key in unique) {
      const distinctProduct = importPreparation.filter(x => x.sku === unique[key]);
      data.push(distinctProduct);
    };

    // product create
    const productsToCreate = [];
    for (const ProductList of data) {

      const { sku, quantity, gross_price, tax } = ProductList[0];

      const productObject = {
        sku,
        pricing: {
          ...(gross_price && { gross_price: +gross_price }),
          ...(tax && { tax: +tax }),
        },
        ...(quantity >= 0 && { quantity: +quantity }),
      };

      productsToCreate.push(productObject);

    }
    setProductsCreate(productsToCreate);

    const errors = checkImportProduct(importPreparation, checkData);
    if (Object.values(errors).some(count => count > 0)) {
      setOpenErrorDialog({ show: true, errors: errors });
    } else {
      dispatch(importExportActions.importUpdateProduct(productsToCreate, language));
    };
  }

  useEffect(() => {
    if (isImport) {
      history.push(ModuleRoutes.PRODUCT)
    }

    return () => {
      dispatch(importExportActions.resetFormHandler());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isImport]);


  return (
    <>
      <EditErrorDialog show={openErrorDialog.show} onHide={closeErrorDialog} errors={openErrorDialog.errors} productsCreate={productsCreate} language={language} setOpenErrorDialog={setOpenErrorDialog} />

      <Card className="card-height">

        <CardSubHeader
          icon={<Icon.PencilSquare />}
          title={<FormattedMessage id='IMPORT.PRODUCT.PREPARE_LIST' />}
          info={<UserGuideHelper />}
          id={'import_product'}
        />

        <CardBody>
          {importPreparation.length > 0
            ? <UpdateProductsTable />
            : <BlankMessage
              icon={<Icon.TextIndentLeft />}
              title={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE_TITLE' />}
              message={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE' />}
            />}
          <button type="submit" className='d-none' ref={submitBtnRef} onClick={handlerProduct} />
        </CardBody>

      </Card>
    </>
  )
}