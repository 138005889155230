import ActionTypes from './actionTypes';



export class AdminUsersContactActions {

  // Get all users contact
  static getUsersContact = (queryParams) => {
    return {
      type: ActionTypes.GET_USERS_CONTACT,
      payload: {
        queryParams
      },
    };
  };

  static getUsersContactSuccess = (users, count) => {
    return {
      type: ActionTypes.GET_USERS_CONTACT_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getUsersContactFail = (error) => {
    return {
      type: ActionTypes.GET_USERS_CONTACT_FAIL,
      payload: {
        error
      },
    };
  };


  // Users Action Creators
  static cleanUpUserModule = () => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE,
    };
  };

  static cleanUpUserModuleParams = (params) => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE_PARAMS,
      payload: {
        params
      },
    };
  };

}