import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { INITIAL_VALUES } from '../_context/SettingsUIHelpers'
import { DeleteConfirm } from '_metronic/_partials/dialogs'
import { UserInfoDropdown } from '_metronic/_partials'
import { ConnectionsActions } from '../_redux/actions'
import { FormattedMessage } from 'react-intl'
import { Icon } from '_metronic/_icons';
import { toast } from "react-toastify";



export const PolicyDocsUploadSettingsCard = ({ findToken, connectionName, connection }) => {

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [policyDocs, setPolicyDocs] = useState(connection?.policy_docs || INITIAL_VALUES[connectionName].policy_docs);
  const closeDialog = () => { setShow(false); };

  useEffect(() => {
    if (connection?.policy_docs) {
      setPolicyDocs(connection.policy_docs);
    }
    // eslint-disable-next-line
  }, [connection?.policy_docs, connectionName]);


  const handleFileChange = async (event, category) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    if (selectedFile.size > 10 * 1024 * 1024) { // 10 MB sınırı
      toast.error(`File ${selectedFile.name} is larger than 10 MB.`);
      return;
    }

    if ([policyDocs?.right_of_withdrawal?.name, policyDocs?.term_and_conditions?.name].includes(selectedFile.name)) {
      toast.error(`File ${selectedFile.name} is already uploaded.`);
      return;
    }

    if (selectedFile.type !== 'application/pdf') { // PDF kontrolü
      toast.error(`File ${selectedFile.name} is not a PDF.`);
      return;
    }

    const newFile = {
      id: Date.now().toString() + selectedFile.name,
      name: selectedFile.name,
      file: selectedFile
    };

    let fileIsExisted = false;
    const form_data = new FormData();

    if (selectedFile.name) {
      fileIsExisted = true;
      form_data.append(category, selectedFile);
    }


    setPolicyDocs(prevDocs => {
      const updatedDocs = {
        ...prevDocs,
        [category]: newFile
      };

      const validPolicyDocs = Object.fromEntries(
        Object.entries(policyDocs).filter(([_, doc]) => doc.id)
      );

      const upload = { is: fileIsExisted, file: form_data };

      dispatch(ConnectionsActions.updateSettings({ ...connection, policy_docs: Object.keys(validPolicyDocs).length > 0 ? validPolicyDocs : {} }, upload));

      return updatedDocs;
    });

    event.target.value = null;
  };


  const downloadFile = (file) => {
    if (!file || !file.link) {
      toast.error("No file available for download.");
      return;
    }

    fetch(file.link)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name || 'download';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(error => toast.error("Failed to download file."));
  };


  const removeFile = (category) => {
    setPolicyDocs(prevDocs => {
      const updatedDocs = {
        ...prevDocs,
        [category]: { id: "", name: "", link: '' }
      };
      return updatedDocs;
    });

    if (policyDocs[category]?.id) {
      dispatch(ConnectionsActions.deleteFile(connectionName, category, policyDocs[category].id))
    }
  };


  return (
    <>
      {['marketplace', 'shop'].includes(findToken.type) && ["term_and_conditions", "right_of_withdrawal"].map((category, index) => (
        <div key={index} className="mb-4">

          <div>
            <span className="font-weight-bolder">
              <FormattedMessage id={`CONNECTIONS.SETTINGS.${category?.toUpperCase()}`} />
            </span>
            <UserInfoDropdown title={<FormattedMessage id={`CONNECTIONS.SETTINGS.${category?.toUpperCase()}`} />} description={<FormattedMessage id={`CONNECTIONS.SETTINGS.${category?.toUpperCase()}.INFO`} />} />
          </div>

          <div className='d-flex justify-content-between align-items-center flex-wrap'>

            {policyDocs[category]?.name
              ? <>

                <OverlayTrigger overlay={<Tooltip id="download-tooltip" className='font-weight-bold'><FormattedMessage id="GENERAL.DOWNLOAD" /></Tooltip>}>
                  <div type="button" className="text-nowrap text-hover-primary svg-icon svg-icon-sm py-2 mr-2" onClick={() => downloadFile(policyDocs[category])}>
                    <Icon.FileTypePdf className="mr-2" />
                    {policyDocs[category]?.name}
                  </div>
                </OverlayTrigger>

                <OverlayTrigger overlay={<Tooltip id="delete-tooltip" className='font-weight-bold'><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
                  <Button variant="outline-primary" size="sm" className="btn-icon btn-hover-danger svg-icon svg-icon-md" onClick={() => { setSelectedCategory(category); setShow(true) }} id={`btn_${category}_delete`}>
                    <Icon.Trash />
                  </Button>
                </OverlayTrigger>

              </>
              : <>

                <div className="text-dark-25">
                  <FormattedMessage id="GENERAL.NO.UPLOADED_FILE" />
                </div>

                <OverlayTrigger overlay={<Tooltip id="upload-tooltip" className='font-weight-bold'><FormattedMessage id="GENERAL.UPLOAD" /></Tooltip>}>
                  <Button variant="outline-primary" size="sm" className="btn-icon btn-hover-danger svg-icon svg-icon-md" onClick={() => document.getElementById(`${category}Upload`).click()} id={`btn_${category}_add`}>
                    <Icon.BoxArrowUp />
                    <input type="file" accept="application/pdf" style={{ display: "none" }} onChange={(e) => handleFileChange(e, category)} id={`${category}Upload`} />
                  </Button>
                </OverlayTrigger>

              </>
            }

          </div>

        </div>
      ))}

      <DeleteConfirm show={show} onHide={closeDialog} toDelete={() => { selectedCategory && removeFile(selectedCategory); closeDialog() }} />

    </>
  )
}
