import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  contact: [],
  count: 0,
  totalCount: 0,
  error: null,
};


export const adminUsersContactReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    // Get all users contact
    case ActionTypes.GET_USERS_CONTACT: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.GET_USERS_CONTACT_SUCCESS: {
      return {
        ...state,
        loading: false,
        contact: payload.users,
        totalCount: payload.count.total,
        count: payload.count.count,
      };
    }

    case ActionTypes.GET_USERS_CONTACT_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    // Clean up the user module
    case ActionTypes.CLEAN_UP_USER_MODULE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case ActionTypes.CLEAN_UP_USER_MODULE_PARAMS: {
      return {
        ...state,
        ...payload.params,
      };
    }


    default:
      return state;
  }
};
