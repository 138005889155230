export default class ActionTypes {
  static get PREFIX() {
    return '[adminUserInvoices]';
  }



  /**
 * *Get User Invoices
 * @return action types for get all invoices
 */
  static get GET_USER_INVOICES() {
    return `${ActionTypes.PREFIX}GET_USER_INVOICES`;
  }
  static get GET_USER_INVOICES_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_USER_INVOICES_SUCCESS`;
  }
  static get GET_USER_INVOICES_FAIL() {
    return `${ActionTypes.PREFIX}GET_USER_INVOICES_FAIL`;
  }


  // Get User Invoice By Id
  static get GET_USER_INVOICE_BY_ID() {
    return `${ActionTypes.PREFIX}GET_USER_INVOICE_BY_ID`;
  }
  static get GET_USER_INVOICE_BY_ID_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_USER_INVOICE_BY_ID_SUCCESS`;
  }
  static get GET_USER_INVOICE_BY_ID_FAIL() {
    return `${ActionTypes.PREFIX}GET_USER_INVOICE_BY_ID_FAIL`;
  }


  // Get User Invoice By Ids
  static get GET_USER_INVOICES_BY_IDS() {
    return `${ActionTypes.PREFIX}GET_USER_INVOICES_BY_IDS`;
  }
  static get GET_USER_INVOICES_BY_IDS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_USER_INVOICES_BY_IDS_SUCCESS`;
  }
  static get GET_USER_INVOICES_BY_IDS_FAIL() {
    return `${ActionTypes.PREFIX}GET_USER_INVOICES_BY_IDS_FAIL`;
  }


  // Patch User Invoice
  static get PATCH_USER_INVOICE() {
    return `${ActionTypes.PREFIX}PATCH_USER_INVOICE`;
  }
  static get PATCH_USER_INVOICE_SUCCESS() {
    return `${ActionTypes.PREFIX}PATCH_USER_INVOICE_SUCCESS`;
  }
  static get PATCH_USER_INVOICE_FAIL() {
    return `${ActionTypes.PREFIX}PATCH_USER_INVOICE_FAIL`;
  }


  //Update User Invoice
  static get UPDATE_USER_INVOICE() {
    return `${ActionTypes.PREFIX}UPDATE_USER_INVOICE`;
  }
  static get UPDATE_USER_INVOICE_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_USER_INVOICE_SUCCESS`;
  }
  static get UPDATE_USER_INVOICE_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_USER_INVOICE_FAIL`;
  }


  // Add new position row
  static get ADD_NEW_POSITION_ROW() {
    return `${ActionTypes.PREFIX}ADD_NEW_POSITION_ROW`;
  }


  // Add multi position
  static get ADD_MULTIPLE_POSITION_ROW() {
    return `${ActionTypes.PREFIX}ADD_MULTIPLE_POSITION_ROW`;
  }


  //Update position
  static get UPDATE_POSITION() {
    return `${ActionTypes.PREFIX}UPDATE_POSITION`;
  }


  //Delete position
  static get DELETE_POSITION() {
    return `${ActionTypes.PREFIX}DELETE_POSITION`;
  }


  //Update discount
  static get UPDATE_DISCOUNT() {
    return `${ActionTypes.PREFIX}UPDATE_DISCOUNT`;
  }


  //Print Document
  static get PRINT_DOCUMENT() {
    return `${ActionTypes.PREFIX}PRINT_DOCUMENT`;
  }
  static get PRINT_DOCUMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}PRINT_DOCUMENT_SUCCESS`;
  }
  static get PRINT_DOCUMENT_FAIL() {
    return `${ActionTypes.PREFIX}PRINT_DOCUMENT_FAIL`;
  }


  // Create Fulfillment Methods
  static get CREATE_USER_INVOICES() {
    return `${ActionTypes.PREFIX}CREATE_USER_INVOICES`;
  }
  static get CREATE_USER_INVOICES_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_USER_INVOICES_SUCCESS`;
  }
  static get CREATE_USER_INVOICES_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_USER_INVOICES_FAIL`;
  }


  //Delete converted document
  static get DELETE_COMPLETE_LIST() {
    return `${ActionTypes.PREFIX}DELETE_COMPLETE_LIST`;
  }


  //Enter payment
  static get ENTER_PAYMENT() {
    return `${ActionTypes.PREFIX}ENTER_PAYMENT`;
  }
  static get ENTER_PAYMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}ENTER_PAYMENT_SUCCESS`;
  }
  static get ENTER_PAYMENT_FAIL() {
    return `${ActionTypes.PREFIX}ENTER_PAYMENT_FAIL`;
  }


  /**
 * *Delete Enter payment
 * @return action types for Enter payment
 */
  static get DELETE_ENTERED_PAYMENT() {
    return `${ActionTypes.PREFIX}DELETE_ENTERED_PAYMENT`;
  }
  static get DELETE_ENTERED_PAYMENT_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_ENTERED_PAYMENT_SUCCESS`;
  }
  static get DELETE_ENTERED_PAYMENT_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_ENTERED_PAYMENT_FAIL`;
  }


  /**
 * *Get Customers
 * @return action types for get document by id
 */
  static get GET_CUSTOMERS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMERS`;
  }
  static get GET_CUSTOMERS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_CUSTOMERS_SUCCESS`;
  }
  static get GET_CUSTOMERS_FAIL() {
    return `${ActionTypes.PREFIX}GET_CUSTOMERS_FAIL`;
  }


  // Clean up the user module
  static get CLEAN_UP_USER_MODULE() {
    return `${this.PREFIX}USERS_CLEAN_UP`;
  }
  static get CLEAN_UP_USER_MODULE_PARAMS() {
    return `${this.PREFIX}USERS_CLEAN_UP_PARAMS`;
  }

}