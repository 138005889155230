import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Importer, ImporterField, deDE, enUS, trTR } from 'react-csv-importer';
import { importExportActions } from '../../_redux/actions';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { FormattedMessage, useIntl } from 'react-intl';
import { UTILS } from '_metronic/_helpers';
import { useLang } from '_metronic/i18n';
import { toast } from 'react-toastify';



export function ImportCategory() {

  const locale = useLang();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const langList = {
    "de": deDE,
    "en": enUS,
    "tr": trTR
  }

  const { error, loading, importPreparation } = useSelector(state => ({
    loading: state.importExport.loading,
    error: state.importExport.error,
    importPreparation: state.importExport.importPreparation,
  }), shallowEqual);

  const [categoryToSave, setCategoryToSave] = useState([]);

  const processChunk = async (rows) => {
    const category = rows
      .filter(row => !row.sku.includes("Path *"))
      .map(row => ({ ...row, id: UTILS.uuidv4() }));
    setCategoryToSave((prevCategory) => [...prevCategory, ...category]);
  };


  useEffect(() => {
    if (error) {
      toast.error(<FormattedMessage id={error} />);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(importExportActions.cleanUpReducer());
    };
  }, [dispatch]);


  return (
    <Importer
      onComplete={({ file, preview, fields, columnFields }) => {

        if (!file.name.toLowerCase().endsWith('.csv')) {
          toast.error(<FormattedMessage id="IMPORT_EXPORT.INVALID_FILE" />);
          return;
        }

        if (file.size > 250000) {
          toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_SIZE" />);
          return;
        }

        if (categoryToSave.length > 500) {
          toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_MAX" />);
          return;
        }

        if (categoryToSave.length === 0) {
          toast.warning(<FormattedMessage id="IMPORT_EXPORT.INVALID_LENGTH_MIN" />)
          return;
        }

        dispatch(importExportActions.importPreparation(categoryToSave, "category"));

      }}
      locale={langList[locale]}
      assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
      restartable={false} // optional, lets user choose to upload another file when import is complete
      onStart={({ file, preview, fields, columnFields }) => { }}
      processChunk={processChunk}
      onClose={({ file, preview, fields, columnFields }) => {
        importPreparation.length > 0 && history.push(ModuleRoutes.IMPORT_CATEGORY);
      }}
      onPageChange={() => { }}
    >
      <ImporterField name="name" label={intl({ id: 'IMPORT.CSV.CATEGORY.CATEGORY_NAME' })} optional />
      <ImporterField name="path" label={intl({ id: 'IMPORT.CSV.CATEGORY.CATEGORY_PATH' })} />
      <ImporterField name="id" label={intl({ id: 'IMPORT.CSV.CATEGORY.CATEGORY_ID' })} />
      <ImporterField name="parent_id" label={intl({ id: 'IMPORT.CSV.CATEGORY.CATEGORY_LEVEL1' })} />
      <ImporterField name="level" label={intl({ id: 'IMPORT.CSV.CATEGORY.CATEGORY_LEVEL' })} />
    </Importer>
  );
}


