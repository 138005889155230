import React from "react";
import { FormattedMessage } from "react-intl";
import ActionTypes from "./actionTypes";
import { toast } from "react-toastify";



export class SettingsActions {

  // Create Document Settings
  static createUserSettings = (userSettings) => {
    return {
      type: ActionTypes.CREATE,
      payload: {
        userSettings,
      },
    };
  };

  static createUserSettingsSuccess = (userSettings) => {
    toast.success(<FormattedMessage id="SETTINGS.REDUX.CREATE.SUCCESS" />)
    return {
      type: ActionTypes.CREATE_SUCCESS,
      payload: {
        userSettings
      },
    };
  };

  static createUserSettingsFail = () => {
    return {
      type: ActionTypes.CREATE_FAIL,
    };
  };


  // Get Document Settings by Type
  static getUserSettingsByType = (settings_type) => {
    return {
      type: ActionTypes.GET_BY_TYPE,
      payload: {
        settings_type,
      },
    };
  };

  static getUserSettingsByTypeSuccess = (userSettings) => {
    return {
      type: ActionTypes.GET_BY_TYPE_SUCCESS,
      payload: {
        userSettings,
      },
    };
  };

  static getUserSettingsByTypeFail = () => {
    return {
      type: ActionTypes.GET_BY_TYPE_FAIL,
    };
  };

  // Get Document Settings
  static getUserSettings = () => {
    return {
      type: ActionTypes.GET,
    };
  }

  static getUserSettingsSuccess = (userSettings) => {
    return {
      type: ActionTypes.GET_SUCCESS,
      payload: {
        userSettings,
      },
    };
  }

  static getUserSettingsFail = () => {
    return {
      type: ActionTypes.GET_FAIL,
    };
  }


  // Create Document Settings
  static getDocumentCount = (settings_type) => {
    return {
      type: ActionTypes.DOCUMENT_COUNT,
      payload: {
        settings_type,
      },
    };
  };

  static getDocumentCountSuccess = (count) => {
    return {
      type: ActionTypes.DOCUMENT_COUNT_SUCCESS,
      payload: {
        count
      },
    };
  };

  static getDocumentCountFail = () => {
    return {
      type: ActionTypes.DOCUMENT_COUNT_FAIL,
    };
  };


  // Update Document Settings
  static updateUserSettings = (id, settings) => {
    return {
      type: ActionTypes.UPDATE,
      payload: {
        id,
        settings,
      },
    };
  };

  static updateUserSettingsSuccess = (settings) => {
    toast.success(<FormattedMessage id="SETTINGS.REDUX.UPDATE_SUCCESS" />)
    return {
      type: ActionTypes.UPDATE_SUCCESS,
      payload: {
        settings
      },
    };
  };

  static updateUserSettingsFail = () => {
    toast.error(<FormattedMessage id='SETTINGS.REDUX.DOCUMENT_UPDATE.ERROR' />)
    return {
      type: ActionTypes.UPDATE_FAIL,
    };
  };


  // Patch Document Settings
  static patchUserSettings = (id, settings_type, settings) => {
    return {
      type: ActionTypes.PATCH_USER_SETTINGS,
      payload: {
        id,
        settings_type,
        settings,
      },
    };
  };

  static patchUserSettingsSuccess = (settings) => {
    return {
      type: ActionTypes.PATCH_USER_SETTINGS_SUCCESS,
      payload: {
        settings,
      },
    };
  };

  static patchUserSettingsFail = () => {
    return {
      type: ActionTypes.PATCH_USER_SETTINGS_FAIL,
    };
  };

  
  // OSS Status
  static getOss = (type) => {
    return {
      type: ActionTypes.GET_OSS,
      payload: {
        type
      },
    };
  };

  static getOssSuccess = (ossCheck) => {
    return {
      type: ActionTypes.GET_OSS_SUCCESS,
      payload: {
        ossCheck
      },
    };
  };

  static getOssFail = () => {
    return {
      type: ActionTypes.GET_OSS_FAIL,
    };
  }

  // Variant options Handler
  /*   static updateVariantHandler = variantOptions => {
      return {
        type: ActionTypes.VARIANT_OPTIONS_HANDLER,
        payload: {
          variantOptions: variantOptions,
        },
      };
    } */


  // Clear Loading Handler
  static clearLoadingEffects = () => {
    return {
      type: ActionTypes.CLEAR_LOADING_EFFECTS,
    };
  };

  static clearState = (stateKey) => {
    return {
      type: ActionTypes.CLEAR_STATE,
      payload: {
        stateKey
      }
    };
  };


}
