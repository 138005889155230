import ActionTypes from './actionTypes';

const initiaState = {
  product: null,
  items: [],
  item: null,
  status: 'idle',
  marketPlace: null,
  error: null,
  publishQueryResults: [], // list of publish requests to Amazon for tracking
  revalidateProductTrigger: 0,
  subscriptions: [], // Check subscription for the product on Amazon,
  getListingsQueryResults: []
};

export function amazonListingReducer(state = initiaState, action) {
  switch (action.type) {
    case ActionTypes.FIND_LISTING_ITEM:
      return { ...state, error: null, item: null, status: `loading/${action.type}` };
    case ActionTypes.FIND_LISTING_ITEM_SUCCESS:
      return { ...state, item: action.payload.item, status: `success/${action.type}` };
    case ActionTypes.FIND_LISTING_ITEM_FAIL:
      return { ...state, error: action.payload.error, status: `fail/${action.type}` };

    // Draft listing item on Amazon
    case ActionTypes.DRAFT_LISTING_ITEM:
      return { ...state, error: null, item: null, status: `loading/${action.type}` };
    case ActionTypes.DRAFT_LISTING_ITEM_SUCCESS:
      return { ...state, status: `success/${action.type}`, revalidateProductTrigger: state.revalidateProductTrigger + 1 };
    case ActionTypes.DRAFT_LISTING_ITEM_FAIL:
      return { ...state, error: action.payload.error, status: `fail/${action.type}` };

    // Undo draft listing item on Amazon
    case ActionTypes.UNDO_DRAFT_LISTING_ITEM:
      return { ...state, error: null, item: null, status: `loading/${action.type}` };
    case ActionTypes.UNDO_DRAFT_LISTING_ITEM_SUCCESS:
      return { ...state, status: `success/${action.type}`, revalidateProductTrigger: state.revalidateProductTrigger + 1 };
    case ActionTypes.UNDO_DRAFT_LISTING_ITEM_FAIL:
      return { ...state, error: action.payload.error, status: `fail/${action.type}` };

    // Create new Amazon listing by existing product
    case ActionTypes.CREATE_LISTING:
      return { ...state, error: null, item: null, status: `loading/${action.type}` };
    case ActionTypes.CREATE_LISTING_SUCCESS:
      return { ...state, status: `success/${action.type}` };
    case ActionTypes.CREATE_LISTING_FAIL:
      return { ...state, error: action.payload.error, status: `fail/${action.type}` };

    // Add new product to database
    case ActionTypes.ADD_NEW_PRODUCT:
      return { ...state, error: null, product: null, status: `loading/${action.type}` };
    case ActionTypes.ADD_NEW_PRODUCT_SUCCESS:
      return { ...state, product: action.payload.product, status: `success/${action.type}` };
    case ActionTypes.ADD_NEW_PRODUCT_FAIL:
      return { ...state, error: action.payload.error, status: `fail/${action.type}` };

    // withdraw listing item on Amazon
    case ActionTypes.WITHDRAW_LISTING_ITEM:
      return { ...state, error: null, item: null, status: `loading/${action.type}` };
    case ActionTypes.WITHDRAW_LISTING_ITEM_SUCCESS:
      return { ...state, status: `success/${action.type}`, revalidateProductTrigger: state.revalidateProductTrigger + 1 };
    case ActionTypes.WITHDRAW_LISTING_ITEM_FAIL:
      return { ...state, error: action.payload.error, status: `fail/${action.type}` };

    // Publish listing item on Amazon
    case ActionTypes.PUBLISH_LISTING_ITEM:
      return {
        ...state,
        error: null,
        item: null,
        status: `loading/${action.type}`,
        publishQueryResults: [
          ...state.publishQueryResults,
          {
            id: action.payload.id,
            status: 'PENDING',
            error: null,
            data: null
          }
        ]
      };
    case ActionTypes.PUBLISH_LISTING_ITEM_SUCCESS:
      return {
        ...state,
        status: `success/${action.type}`,
        publishQueryResults: state.publishQueryResults.map((req) => (req.id === action.payload.id ? { ...req, status: 'SUCCESS', error: null, data: action.payload.data } : req)),
        revalidateProductTrigger: state.revalidateProductTrigger + 1
      };
    case ActionTypes.PUBLISH_LISTING_ITEM_FAIL:
      return {
        ...state,
        error: action.payload.error,
        status: `fail/${action.type}`,
        publishQueryResults: state.publishQueryResults.map((req) => (req.id === action.payload.id ? { ...req, status: 'FAIL', error: action.payload.error, data: null } : req))
      };

    // Subscriptions methods
    case ActionTypes.CHECK_SUBSCRIPTION:
      return {
        ...state,
        error: null,
        subscriptions: [
          ...state.subscriptions,
          {
            productId: action.payload.productId,
            data: null
          }
        ]
      };
    case ActionTypes.CHECK_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: state.subscriptions.map((sub) => (sub.productId === action.payload.productId ? { ...sub, data: action.payload.subscription } : sub))
      };
    case ActionTypes.CHECK_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload.error,
        subscriptions: state.subscriptions.map((sub) => (sub.productId === action.payload.productId ? { ...sub, data: null } : sub))
      };

    // Get listing items from Amazon
    case ActionTypes.GET_LISTINGS:
      return {
        ...state,
        error: null,
        getListingsQueryResults: state.getListingsQueryResults.concat({
          id: action.payload.id,
          error: null,
          data: null
        })
      };
    case ActionTypes.GET_LISTINGS_SUCCESS:
      return {
        ...state,
        getListingsQueryResults: state.getListingsQueryResults.map((req) => (req.id === action.payload.id ? { ...req, error: null, data: action.payload.data } : req))
      };
    case ActionTypes.GET_LISTINGS_FAIL:
      return {
        ...state,
        getListingsQueryResults: state.getListingsQueryResults.map((req) =>
          req.id === action.payload.id ? { ...req, status: 'FAIL', error: action.payload.error, data: null } : req
        )
      };

    // Get product by id from database
    case ActionTypes.GET_PRODUCT_BY_ID:
      return { ...state, error: null, product: null, status: `loading/${action.type}` };
    case ActionTypes.GET_PRODUCT_BY_ID_SUCCESS:
      return { ...state, product: action.payload.product, status: `success/${action.type}` };
    case ActionTypes.GET_PRODUCT_BY_ID_FAIL:
      return { ...state, error: action.payload.error, status: `fail/${action.type}` };

    // Update state by given property
    case ActionTypes.UPDATE_STATE_PROPERTY:
      return { ...state, ...action.payload };
    // reset state to initial state
    case ActionTypes.LISTING_RESET:
      return initiaState;
    default:
      return state;
  }
}

export const selectAmazonItem = (state) => {
  const _item = state.amazonListings.item;
  if (_item && _item.summaries && _item.summaries.length > 0) {
    const summaries = _item.summaries[0];
    const mainImage = _item.images && _item.images.length > 0 ? _item.images[0]?.images[0] : null;
    const productType = _item.productTypes[0].productType;
    return {
      asin: _item.asin,
      title: summaries.itemName,
      displayName: summaries.browseClassification.displayName,
      brand: summaries.brand,
      image: mainImage?.link,
      productType: productType
    };
  }
};

export const selectAmazonListings = (state) => state.amazonListings;
