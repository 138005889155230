import React from 'react';
import { Switch } from 'react-router-dom';
import { AdminRoutes } from 'constants/moduleRoutes';
import { UserInvoicesPage } from './UserInvoicesPage';
import { ContentRoute } from '_metronic/layout';
import { UserInvoicesEdit } from './user-invoices-edit/UserInvoicesEdit';
import { UserInvoicesUIProvider } from './_context/UserInvoicesUIContext';




export default function UserInvoices({name}) {
  return (
    <UserInvoicesUIProvider>
      <Switch>
        <ContentRoute path={AdminRoutes.DOCUMENT_NEW_FN(name)} component={UserInvoicesEdit} />
        <ContentRoute path={AdminRoutes.DOCUMENT_EDIT_FN(name)} component={UserInvoicesEdit} />
        <ContentRoute path={AdminRoutes.DOCUMENT_NAME_FN(name)}>
          {() => <UserInvoicesPage moduleName={name} />}
        </ContentRoute>
      </Switch>
    </UserInvoicesUIProvider>
  );
}
