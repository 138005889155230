import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { Card, CardBody, CardHeader, CardHeaderToolbar, } from '_metronic/_partials/controls';
import { DeutschePostProductList } from '../connection-forms/shippers/deutsche-post/DeutschePostProductList';
import { DeutschePostProductSelect } from '../connection-forms/shippers/deutsche-post/DeutschePostProductSelect';
import { FormattedMessage } from 'react-intl';
import { BackButton } from '_metronic/_partials';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { ConnectionsActions } from '../_redux/actions';
import { ConnectionGeneralCard } from './ConnectionGeneralCard';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { ConnectionEditDialog } from '../connection-dialogs';
import { ConnectionSettingsCard } from './ConnectionSettingsCard';
import { ConnectionPoliciesCard } from './ConnectionPoliciesCard';
import { DhlList } from '../connection-forms/shippers/dhl/DhlList';
import { DhlNewList } from '../connection-forms/shippers/new-dhl/DhlNewList';
import { useSettingsEditContext } from '../_context/SettingsEditContext';
import { ConnectionInfoCard } from './ConnectionInfoCard';
import { Icon } from '_metronic/_icons';



export const ConnectionSettingsPage = ({ match: { params: { connectionName, connectionId } } }) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [show, setShowModal] = useState({ token: false, dp: false });
  const success = useSelector(state => state.connections.success);

  const UIContext = useSettingsEditContext();
  const UIProps = useMemo(() => ({
    openConnectionAddDialog: UIContext.openConnectionAddDialog,
  }), [UIContext]);

  const findToken = CONNECTION_SETTINGS.find((token) => connectionName === token.name);

  useEffect(() => {
    connectionId && dispatch(ConnectionsActions.getConnectionById(connectionId));
  }, [connectionId, dispatch]);


  useEffect(() => {
    success && setShowModal({ token: false, dp: false });
    // eslint-disable-next-line
  }, [success]);


  return (<>
    <Card className="card-box">

      <CardHeader
        back={<BackButton onClick={() => { history.push(ModuleRoutes.CONNECTIONS); dispatch(ConnectionsActions.clearLoadingEffects()) }} />}
        title={<FormattedMessage id={'CONNECTIONS.SHOP_SETTINGS'} values={{ marketName: findToken?.label }} />}
        id='connections_edit'
        sticky
      >
        <CardHeaderToolbar>

          <Button variant='primary' className="svg-icon svg-icon-sm" onClick={() => UIProps.openConnectionAddDialog(connectionName, connectionId)} id="btn_change_token">
            <Icon.PencilSquare />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="CONNECTIONS.CHANGE_TOKEN" />
            </span>
          </Button>

        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>

        <Row>
          <Col lg={6}>
            <ConnectionGeneralCard connectionId={connectionId} connectionName={connectionName} />
          </Col>
          <Col lg={6}>
            <ConnectionSettingsCard connectionName={connectionName} findToken={findToken} />
          </Col>
        </Row>

        {/*  {findToken.type!=="shipper" && <ConnectionFetchOrderCard connectionId={connectionId}/>} */}

        {connectionName === "ebay" && <ConnectionPoliciesCard />}

        {connectionName === "dhl" && <DhlList />}

        {connectionName === 'dhl_new' && <DhlNewList />}

        {connectionName === 'deutsche_post' && <DeutschePostProductList show={() => setShowModal({ ...show, dp: true })} />}

        <ConnectionInfoCard connectionName={connectionName} />

      </CardBody>

    </Card>

    <ConnectionEditDialog
      connectionId={connectionId}
      connectionName={connectionName}
      show={show.token}
      onHide={() => setShowModal({ ...show, token: false })}
    />

    <DeutschePostProductSelect
      show={show.dp}
      onHide={() => setShowModal({ ...show, dp: false })}
    />

  </>)
};