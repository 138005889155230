import { API } from 'constants/apiUrl';
import { searchKeysTemp } from '../params';
import { paginationParams, searchParams } from 'middlewares/_helpers/query-params';




export const adminTempUsersMiddleware = {

  // ------------------------------------ TEMP USER ------------------------------------ //

  getTempUsers: async function (accessToken, { pagination, filter, order, search }) {
    return await API.get(`/temp-users`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...(search && {
              ...searchParams(searchKeysTemp, search),
            }),
            ...(filter && filter),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getTempUsersCount: async function (accessToken, { pagination, filter, order, search }) {
    return await API.get(`/temp-users/count`, {
      params: {
        where: {
          ...(filter && filter),
          ...(search && {
            ...searchParams(searchKeysTemp, search),
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchTempUser: async function (accessToken, tempUser) {
    return await API.patch(`/temp-users/${tempUser.id}`, tempUser, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteTempUser: async function (accessToken, ids) {
    return await API.delete(`/temp-users/${ids}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


};
