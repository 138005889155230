import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';
import { appliedFilter, searchKeys, supplierFieldParams } from './params';

export const productMiddleware = {
  getProducts: async function (accessToken, { pagination, filter, order, search, include }) {
    return await API.get(`/products`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          //order: [`${Array.isArray(order) && order.length > 0 ? order.join(' ') : 'id DESC'}`],
          order: [order.length > 0 ? order : 'id DESC'],
          where: {
            ...(search && {
              ...searchParams(searchKeys, search)
            }),
            ...(filter && appliedFilter(filter)) // Fonksiyondan dönen değeri burada kullanın
          }
        },
        ...(include && { include })
      },
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  getCount: async function (accessToken, { filter, search }) {
    return await API.get(`/products/count`, {
      params: {
        where: {
          ...(search && {
            ...searchParams(searchKeys, search)
          }),
          ...(filter && appliedFilter(filter)) // Fonksiyondan dönen değeri burada kullanın
        }
      },
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  getProductById: async function (accessToken, id, filter) {
    return await API.get(`/products/${id}`, {
      params: {
        filter: {
          ...(filter && { ...filter })
        }
      },
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  findProduct: async function (accessToken, key, value) {
    return await API.get(`/products`, {
      params: {
        filter: {
          where: {
            [key]: value
          }
        }
      },
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  findProductBySku: async function (accessToken, value, productId) {
    return await API.get(`/products`, {
      params: {
        productId,
        filter: {
          where: {
            or: value.map((v) => ({ sku: v })).concat(value.map((v) => ({ variants: { elemMatch: { sku: v } } })))
          }
        }
      },
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  createProduct: async function (accessToken, product) {
    return await API.post(`/products`, product, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  patchProduct: async function (accessToken, productId, product) {
    return await API.patch(`/products/${productId}`, product, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  deleteProduct: async function (accessToken, ids) {
    return await API.delete(`/products/${ids}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  // ------------------------- Product Images ------------------------- //

  getImages: async function (accessToken, id) {
    return await API.get(`/containers/${id}/files`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  uploadImages: async function (accessToken, productId, data) {
    return await API.post(`/containers/${productId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  deleteFile: async function (accessToken, productId, fileType, fileId) {
    return await API.delete(`/containers/${productId}/${fileType}/${fileId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  getSupplier: async function (accessToken, keys, event) {
    return await API.get(`/suppliers`, {
      params: {
        filter: {
          where: keys && event ? searchParams(keys, event) : {},
          ...supplierFieldParams
        }
      },
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  updateAllSelectedProductsBeiShops: async function (accessToken, productData) {
    return await API.post(`/products/shop-quantity`, productData, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
};
