import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { validator } from '../import-product/ImportProductValidators';
import { TAX_OPTIONS } from 'app/modules/products/_context/ProductsUIHelpers';
import * as columnFormatters from './UpdateProductColumnFormatters';
import { importExportActions } from '../../_redux/actions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function UpdateProductsTable() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  const { importPreparation, checkData } = useSelector((state) => ({
    importPreparation: state.importExport.importPreparation,
    checkData: state.importExport.checkData,
  }));

  // Veritabanındaki eski bilgileri de tabload gösterip karşılaştırmak için excelden gelen bilgiye ekliyoruz
  const importPreparationModified = importPreparation.map((product) => {
    return {
      ...product,
      old_quantity: checkData?.productList[product.sku]?.quantity || "",
      old_gross_price: checkData?.productList[product.sku]?.pricing?.gross_price || "",
      old_tax: checkData?.productList[product.sku]?.pricing?.tax || "",
      quantity: product?.quantity || "",
      gross_price: product?.gross_price || "",
      tax: product?.tax || "",
    };
  });

  const tableRowHandler = (oldValue, newValue, row, column) => {

    const updatedRow = {
      ...row,
      [column.dataField]: newValue,
    };

    dispatch(importExportActions.importPreparationUpdate(updatedRow));
  };

  const editorStyle = () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "right" });

  const COLUMNS = [
    {
      dataField: '#',
      text: '#',
      headerStyle: { minWidth: '50px', width: '5%' },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editorStyle,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      editable: false,
    },
    {
      dataField: 'sku',
      text: intl({ id: "IMPORT.PRODUCT.SKU" }),
      headerFormatter: columnFormatters.HeaderFormatterRequired,
      headerStyle: { minWidth: '100px', width: '25%' },
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bolder',
      editorStyle,
      formatter: (cell, row) => columnFormatters.SkuFormatter(cell, row, checkData.skuList),
      validator: (cell, row) => validator.skuUpdateValidator(cell, row, checkData.skuList, intl),
    },
    {
      dataField: 'old_quantity',
      text: intl({ id: "IMPORT.PRODUCT.UPDATE.OLD_QUANTITY" }),
      headerStyle: { minWidth: '100px', width: '10%', backgroundColor: 'rgba(0,0,0,0.03)' },
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bolder text-dark-50 text-right',
      style: { backgroundColor: 'rgba(0,0,0,0.03)' },
      editable: false,
    },
    {
      dataField: 'quantity',
      text: intl({ id: "GENERAL.QUANTITY" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bolder text-right',
      editorStyle,
      formatter: columnFormatters.QuantityFormatter,
      validator: (cell) => validator.quantityUpdateValidator(cell, intl),
    },
    {
      dataField: 'old_gross_price',
      text: intl({ id: "PRODUCT.TABLE.PRICE_GROSS_OLD" }),
      headerStyle: { minWidth: '100px', width: '10%', backgroundColor: 'rgba(0,0,0,0.03)' },
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bolder text-dark-50 text-right',
      style: { backgroundColor: 'rgba(0,0,0,0.03)' },
      editable: false,
    },
    {
      dataField: 'gross_price',
      text: intl({ id: "PRODUCT.TABLE.PRICE_GROSS" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bolder text-right',
      editorStyle,
      formatter: columnFormatters.GrossPriceFormatter,
      validator: (cell) => validator.grossPriceValidator(cell, intl),
    },
    {
      dataField: 'old_tax',
      text: intl({ id: "IMPORT.PRODUCT.UPDATE.OLD_TAX" }),
      headerStyle: { minWidth: '100px', width: '10%', backgroundColor: 'rgba(0,0,0,0.03)' },
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bolder text-dark-50 text-right',
      style: { backgroundColor: 'rgba(0,0,0,0.03)' },
      editable: false,
    },
    {
      dataField: 'tax',
      text: intl({ id: "PRODUCT.GENERAL.TAX" }),
      headerStyle: { minWidth: '100px', width: '10%' },
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bolder text-right',
      editorStyle,
      formatter: (cell) => {
        return (
          <div type="button" className={clsx("edit-table", (+cell < 0 || +cell > 100) && "form-control form-control-sm is-invalid")}>
            {cell}
          </div>
        );
      },
      validator: (cell) => validator.taxUpdateValidator(cell, intl),
      editor: {
        type: Type.SELECT, options: TAX_OPTIONS.map(item => ({
          value: item.value,
          label: intl({ id: item.label })
        }))
      },
    },
    {
      dataField: 'action',
      text: "...",
      headerClasses: 'font-size-h3 text-center pt-0',
      headerStyle: { minWidth: '50px', width: '5%' },
      classes: 'text-center pr-0',
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="GENERAL.REMOVE_FROM_LIST" /></Tooltip>}>
          <button className="btn btn-icon btn-sm text-dark-50 text-hover-danger" onClick={() => dispatch(importExportActions.importPreparationDelete(row.id))} id={`btn_delete_${row.id}`}>
            <Icon.Close />
          </button>
        </OverlayTrigger>
      ),
    },
  ];


  return (
    <BootstrapTable
      keyField="id"
      data={importPreparationModified || []}
      columns={COLUMNS}
      wrapperClasses="table-responsive"
      classes="table table-head-custom table-vertical-center"
      bootstrap4
      striped
      condensed
      cellEdit={cellEditFactory({
        mode: 'dbclick',
        blurToSave: true,
        autoSelectText: true,
        afterSaveCell: tableRowHandler,
      })}
    />
  );
}