/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, convertPriceCurrency, UIDates, getCountryList, getSelectRow, UTILS } from '_metronic/_helpers';
import { RemotePagination } from '_metronic/_partials/controls';
import { useLoadingHandler } from 'app/hooks';
import * as columnFormatters from './column-formatters';
import { FormattedMessage, useIntl } from 'react-intl';
import * as columnHelpers from './columnHelpers';
import * as UIHelpers from '../../../modules/documents/_context/DocumentsUIHelpers';
import { allQueryKeys } from '../_context/UserInvoicesUIHelpers';
import { AdminUserInvoicesActions } from '../_redux/actions';
import { AdminRoutes } from 'constants/moduleRoutes';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';



export function UserInvoicesTable({ userInvoices, loading, countDocuments, UIProps }) {

  const locale = useLang();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const useLoading = useLoadingHandler(loading, AdminUserInvoicesActions.cleanUpUserModuleParams({ loading: false }));

  const openPrintDocumentDialog = (id) => {

    if (id) {
      const data = {
        ids: [id],
        user_invoice_action: UIProps.requestedDocument.type,
      };

      dispatch(AdminUserInvoicesActions.printDocument(data));
    }

    UIProps.openPrintDocumentDialog();
  };

  const COLUMNS = [
    {
      dataField: 'id',
      text: intl({ id: 'CUSTOMER.TITLE' }),
      hidden: ['invoices'].includes(UIProps.requestedDocument.type),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap pl-2',
      classes: 'font-weight-bold text-nowrap pl-2',
      headerStyle: { minWidth: '120px' },
      style: { height: '52px' },
      formatter: (cell, row) => {
        return (
          <div className='d-flex align-items-center'>
            <OverlayTrigger overlay={<Tooltip id="customer-details-tooltip" className='font-weight-bold'><FormattedMessage id='DOCUMENT.DETAIL_DIALOG.TITLE' values={{ title: 'invoice' }} /></Tooltip>}>
              <div type="button" className="symbol symbol-40 symbol-light mr-3" onClick={() => UIProps.openDocumentDetailsDialog(row.id)} id='btn_customer_preview'>
                <span className="symbol-label font-size-h5 text-dark-25 text-uppercase">
                  {UTILS.getFirstLetter(row?.customer_details?.name)}
                </span>
              </div>
            </OverlayTrigger>

            <div>
              <OverlayTrigger overlay={<Tooltip id="order-tooltip" className='font-weight-bold'>
                <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.EDIT' values={{ documentTitle: UIProps.requestedDocument.title }} />
              </Tooltip>}>
                <div type="button" className="text-dark text-hover-primary text-decoration-none text-nowrap preview" onClick={() => history.push(AdminRoutes.DOCUMENT_UI_CONTEXT_EDIT_FN(UIProps.requestedDocument.type, row.id))} id='btn_customer_preview'>
                  <span className='font-size-lg font-weight-bold'>
                    {row?.customer_details?.name}
                  </span>
                  <span className='text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
                    <Icon.Pencil />
                  </span>
                </div>
              </OverlayTrigger>
              <div type="button" className="d-flex align-items-center text-nowrap preview" onClick={() => navigator.clipboard.writeText(row.id)} id='btn_customer_preview'>
                <a href={`mailto:${row?.customer_details?.email}`} className="font-weight-bold text-decoration-none text-dark-50 text-hover-primary">
                  {row?.customer_details?.email}
                </a>
                <span className='text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
                  <Icon.EnvelopeAt />
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'invoice_number',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.INVOICE_ID' }),
      sort: true,
      sortCaret,
      hidden: ['orders'].includes(UIProps.requestedDocument.type),
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        return (
          <div className='d-flex align-items-center'>
            <OverlayTrigger overlay={<Tooltip id="customer-details-tooltip" className='font-weight-bold'><FormattedMessage id='DOCUMENT.DETAIL_DIALOG.TITLE' values={{ title: 'invoice' }} /></Tooltip>}>
              <div type="button" className="symbol symbol-40 symbol-light mr-3" onClick={() => UIProps.openDocumentDetailsDialog(row.id)} id='btn_customer_preview'>
                <span className="symbol-label font-size-h5 text-dark-25 text-uppercase">
                  {UTILS.getFirstLetter(row?.customer_details?.name)}
                </span>
              </div>
            </OverlayTrigger>

            <div>
              <OverlayTrigger overlay={<Tooltip id="order-tooltip" className='font-weight-bold'>
                <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.EDIT' values={{ documentTitle: UIProps.requestedDocument.title }} />
              </Tooltip>}>
                <a type="button" className="d-flex align-items-center text-decoration-none text-hover-primary text-dark-75 preview" onClick={() => history.push(AdminRoutes.DOCUMENT_UI_CONTEXT_EDIT_FN(UIProps.requestedDocument.type, row.id))} id='btn_invoice_edit'>
                  {row?.invoice_number}
                  <span className='invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                    <Icon.Pencil />
                  </span>
                </a>
              </OverlayTrigger>

              <OverlayTrigger overlay={<Tooltip id="market-tooltip"><FormattedMessage id='GENERAL.INVOICE_PERIOD' /></Tooltip>}>
                <span className='font-size-sm text-dark-50'>
                  {row.created_at && moment(row?.created_at).format('MMMM YYYY')}
                </span>
              </OverlayTrigger>
            </div>

          </div>
        );
      },
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.INVOICE_PERIOD' }),
      hidden: ['invoices'].includes(UIProps.requestedDocument.type),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bold text-nowrap text-center pr-4',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIDates.formatDateTime(row.created_at)}</Tooltip>}>
          <span>
            {row.created_at && moment(row?.created_at).format('MMMM YYYY')}
          </span>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'invoice_date',
      text: intl({ id: columnHelpers?.dateColumnText(UIProps.requestedDocument.type) }),
      hidden: ['orders'].includes(UIProps.requestedDocument.type),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bold text-nowrap text-center pr-4',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIDates.formatDateTime(row.invoice_date)}</Tooltip>}>
          <span>
            {row.invoice_date && UIDates.formatDate(row.invoice_date)}
          </span>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'customer_details.name',
      text: intl({ id: 'CUSTOMER.TITLE' }),
      hidden: ['orders'].includes(UIProps.requestedDocument.type),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '200px' },
      formatter: (cell, row) => {
        return (
          <>
            <div type="button" className="d-flex align-items-center text-decoration-none text-hover-primary text-nowrap preview" id='btn_customer_preview'>
              <div className='d-flex flex-column mr-1'>
                <span className='font-weight-bold'>{row.customer_details?.name}</span>
              </div>
              <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                <Icon.FileEarmarkText />
              </span>
            </div>
            <div type="button" className="d-flex align-items-center text-nowrap preview" onClick={() => navigator.clipboard.writeText(row.id)} id='btn_customer_preview'>
              <a href={`mailto:${row?.customer_details?.email}`} className="font-weight-bold text-decoration-none text-dark-50 text-hover-primary">
                {row?.customer_details?.email}
              </a>
              <span className='text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
                <Icon.EnvelopeAt />
              </span>
            </div>
          </>
        );
      },
    },
    {
      dataField: 'customer_details.invoice_address.country_code',
      text: intl({ id: 'GENERAL.ADDRESS' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '160px' },
      formatter: (cell, row) => {
        const address = row.customer_details?.invoice_address
        return (
          <OverlayTrigger overlay={<Tooltip id="address-tooltip">
            <div className='font-weight-bold text-dark-75 text-left'>
              <div className='font-weight-bolder'>{address?.name}</div>
              <div className='font-weight-bolder text-dark-50'>{address?.company}</div>
              <hr className='my-1' />
              <div>{address?.address_line1} {address?.address_line2}</div>
              <div>{address?.post_code} {address?.city}</div>
              <div>{getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
            </div>
          </Tooltip>}>
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold text-nowrap'>{address.city}</span>
              <span className='text-dark-50'>{`${address?.country_code}-${address?.post_code}`}</span>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'entered_payment',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT_TYPE' }),
      // hidden: !['invoices', 'credits'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center text-muted font-weight-bold pr-7',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        const payments = cell?.map(payment => {
          const method = UIHelpers.paymentMethods.find(method => method.value === payment.payment_method);
          return method ? method.labelId ? intl({ id: method.labelId }) : method.label : payment.payment_method;
        });
        return [...new Set(payments)].join(', ');
      }
    },
    {
      dataField: 'payment_status',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT' }),
      // hidden: !['invoices', 'orders', 'credits'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      formatter: (cell, row) => {
        return (
          <span type='button' onClick={() => UIProps.openEnterPaymentDialog(row.id)} className={`label label-inline label-lg font-weight-bold label-${cell === 'paid' ? 'outline-success' : 'danger'}`}>
            {UIHelpers.getPaymentStatus(cell)?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
          </span>
        );
      },
    },
    {
      dataField: 'pricing_summary.total',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.TOTAL_AMOUNT' }),
      // hidden: !['invoices'].includes(documentType),
      isDummyField: true,
      classes: 'font-weight-bold text-right pl-3',
      headerClasses: 'text-right',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        const paymentWarnings = row.payment_warnings?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
        const discount = row.pricing_summary?.discount ?? 0;
        const totalPriceDiscount = row.pricing_summary?.total_price_discount ?? 0;
        const totalPayment = +row.pricing_summary.total - (discount * row.pricing_summary?.total / 100) + paymentWarnings + totalPriceDiscount;
        const currency = row.pricing_summary.currency;
        return (
          <span type="button" onClick={() => UIProps.openEnterPaymentDialog(row.id)}>
            {convertPriceCurrency(totalPayment, currency)}
          </span>
        )
      },
    },
    {
      dataField: 'rest',
      isDummyField: true,
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.OPEN_AMOUNT' }),
      headerClasses: 'text-right pr-4',
      classes: 'font-weight-bold text-right pr-4',
      headerStyle: { minWidth: '100px' },
      formatter: columnFormatters.RestPriceColumnFormatter,
      formatExtraData: {
        openEnterPaymentDialog: UIProps.openEnterPaymentDialog,
        // openPaymentWarningsDialog: UIProps.openPaymentWarningsDialog,
      },
    },
    {
      dataField: 'action',
      text: '...',
      classes: 'text-center text-nowrap',
      headerClasses: 'text-center icon-lg',
      headerStyle: { minWidth: '100px' },
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openPrintDocumentDialog: openPrintDocumentDialog,
        openEditDocumentDialog: (id) => history.push(AdminRoutes.DOCUMENT_UI_CONTEXT_EDIT_FN(UIProps.requestedDocument.type, id)),
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={userInvoices || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes='table table-head-custom overflow-hidden table-vertical-center'
        bootstrap4
        bordered={false}
        striped
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={useLoading} onClick={() => {UIProps.setFilterQuery('DELETE_PARAMS', allQueryKeys) }} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: userInvoices,
        })}
      />

      <RemotePagination
        queryParams={UIProps.queryParams.pagination}
        setQueryParams={UIProps.setPaginationQuery}
        totalCount={countDocuments}
        entitiesCount={userInvoices?.length}
        loading={useLoading}
      />
    </>
  );
}
