export default class ActionTypes {
  static get PREFIX() {
    return '[adminTempUsers]';
  }



  // Get all temp users
  static get GET_TEMP_USERS() {
    return `${ActionTypes.PREFIX}TEMP_USERS_GET_CALL`;
  }
  static get GET_TEMP_USERS_SUCCESS() {
    return `${ActionTypes.PREFIX}TEMP_USERS_GET_SUCCESS`;
  }
  static get GET_TEMP_USERS_FAIL() {
    return `${ActionTypes.PREFIX}TEMP_USERS_GET_FAIL`;
  }


  // Update temp users
  static get UPDATE_TEMP_USER() {
    return `${ActionTypes.PREFIX}TEMP_USER_UPDATE_CALL`;
  }
  static get UPDATE_TEMP_USER_SUCCESS() {
    return `${ActionTypes.PREFIX}TEMP_USER_UPDATE_SUCCESS`;
  }
  static get UPDATE_TEMP_USER_FAIL() {
    return `${ActionTypes.PREFIX}TEMP_USER_UPDATE_FAIL`;
  }


  // Delete temp users
  static get DELETE_TEMP_USERS() {
    return `${ActionTypes.PREFIX}DELETE_TEMP_USERS_GET_CALL`;
  }
  static get DELETE_TEMP_USERS_SUCCESS() {
    return `${ActionTypes.PREFIX}DELETE_TEMP_USERS_GET_SUCCESS`;
  }
  static get DELETE_TEMP_USERS_FAIL() {
    return `${ActionTypes.PREFIX}DELETE_TEMP_USERS_GET_FAIL`;
  }

  // Clean up the user module
  static get CLEAN_UP_USER_MODULE() {
    return `${this.PREFIX}USERS_CLEAN_UP`;
  }
  static get CLEAN_UP_USER_MODULE_PARAMS() {
    return `${this.PREFIX}USERS_CLEAN_UP_PARAMS`;
  }

}