import React, { useEffect, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { AmazonListingsActions } from '../_redux/actions';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';

function AmazonListingsPublishDialog({ ids, show, onHide, marketId }) {
  const dispatch = useDispatch();

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(
    () => ({
      queryParams: UIContext.queryParams
    }),
    [UIContext.queryParams]
  );

  useEffect(() => {
    !ids && onHide();
    !Array.isArray(ids) && onHide();
    Array.isArray(ids) && ids.length === 0 && onHide();

    return () => {
      dispatch(AmazonListingsActions.updateStateProperty({ publishQueryResults: [] }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  const publishQueryResults = useSelector((state) => state.amazonListings.publishQueryResults);

  const onPublish = (ids) => {
    for (const id of ids) {
      if (publishQueryResults.find((item) => item.id === id)) {
        continue;
      }
      dispatch(AmazonListingsActions.amazonPublishListingItemRequest({ productId: id, marketId, queryParams: UIProps.queryParams }));
    }
  };

  return (
    <Portal node={document && document.getElementById('layout-portal')}>
      <Modal show={show} onHide={onHide} size="md" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>
        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          {/* map ids with checkbox */}
          {ids &&
            ids.length > 0 &&
            ids.map((id) => (
              <div key={`publish-${id}`} className="mt-3 d-flex flex-row" style={{ gap: '.325rem' }}>
                <h4>{id}</h4>
                <span>Ready</span>
              </div>
            ))}
          <div className="mt-3">
            {publishQueryResults &&
              publishQueryResults.length > 0 &&
              publishQueryResults.map((item) => (
                <div key={`publish-${item.id}`} className="mt-3 d-flex flex-row" style={{ gap: '.325rem' }}>
                  <h4>{item.id}</h4>
                  <span>{item.status}</span>
                  {item.error && <span>{item.error}</span>}
                  {item.data && JSON.stringify(item.data)}
                </div>
              ))}
          </div>
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">
          <Button variant="ios" className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="ios" className="text-success" onClick={() => onPublish(ids)} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.PUBLISH" defaultMessage="Publish" />
          </Button>
        </Modal.Footer>
      </Modal>
    </Portal>
  );
}

export { AmazonListingsPublishDialog };
