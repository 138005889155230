import ActionTypes from './actionTypes';

export class AmazonListingsActions {
  // Find listing item by ASIN number on Amazon
  static amazonFindListingItemRequest = (payload) => ({
    type: ActionTypes.FIND_LISTING_ITEM,
    payload
  });
  static amazonFindListingItemResolves = ({ item, error, isError }) => ({
    type: isError ? ActionTypes.FIND_LISTING_ITEM_FAIL : ActionTypes.FIND_LISTING_ITEM_SUCCESS,
    payload: { item, error }
  });

  // Draft listing item on Amazon
  static amazonDraftListingItemRequest = (payload) => ({
    type: ActionTypes.DRAFT_LISTING_ITEM,
    payload
  });
  static amazonDraftListingItemResolves = ({ item, error, isError }) => ({
    type: isError ? ActionTypes.DRAFT_LISTING_ITEM_FAIL : ActionTypes.DRAFT_LISTING_ITEM_SUCCESS,
    payload: { item, error }
  });

  // Undo draft listing item on Amazon
  static amazonUndoDraftListingItemRequest = ({ productId, marketId, queryParams }) => ({
    type: ActionTypes.UNDO_DRAFT_LISTING_ITEM,
    payload: { productId, marketId, queryParams }
  });
  static amazonUndoDraftListingItemResolves = ({ error, isError }) => ({
    type: isError ? ActionTypes.UNDO_DRAFT_LISTING_ITEM_FAIL : ActionTypes.UNDO_DRAFT_LISTING_ITEM_SUCCESS,
    payload: { error }
  });

  // Create new Amazon listing by existing product
  static amazonCreateListingRequest = (payload) => ({
    type: ActionTypes.CREATE_LISTING,
    payload
  });
  static amazonCreateListingResolves = ({ item, error, isError }) => ({
    type: isError ? ActionTypes.CREATE_LISTING_FAIL : ActionTypes.CREATE_LISTING_SUCCESS,
    payload: { item, error }
  });

  // Add new product to database
  static addNewProductRequest = (payload) => ({
    type: ActionTypes.ADD_NEW_PRODUCT,
    payload
  });
  static addNewProductResolves = ({ product, error, isError }) => ({
    type: isError ? ActionTypes.ADD_NEW_PRODUCT_FAIL : ActionTypes.ADD_NEW_PRODUCT_SUCCESS,
    payload: { product, error }
  });

  // Get product by id from database
  static getProductByIdRequest = (productId) => ({
    type: ActionTypes.GET_PRODUCT_BY_ID,
    payload: { productId }
  });
  static getProductByIdResolves = ({ product, error, isError }) => ({
    type: isError ? ActionTypes.GET_PRODUCT_BY_ID_FAIL : ActionTypes.GET_PRODUCT_BY_ID_SUCCESS,
    payload: { product, error }
  });

  // withdraw listing item on Amazon
  static amazonWithdrawListingItemRequest = ({ productId, marketId, queryParams }) => ({
    type: ActionTypes.WITHDRAW_LISTING_ITEM,
    payload: { productId, marketId, queryParams }
  });
  static amazonWithdrawListingItemResolves = ({ error, isError }) => ({
    type: isError ? ActionTypes.WITHDRAW_LISTING_ITEM_FAIL : ActionTypes.WITHDRAW_LISTING_ITEM_SUCCESS,
    payload: { error }
  });

  // publish listing item on Amazon
  static amazonPublishListingItemRequest = ({ productId, marketId, queryParams }) => ({
    type: ActionTypes.PUBLISH_LISTING_ITEM,
    payload: { productId, marketId, queryParams }
  });
  static amazonPublishListingItemResolves = ({ error, isError }) => ({
    type: isError ? ActionTypes.PUBLISH_LISTING_ITEM_FAIL : ActionTypes.PUBLISH_LISTING_ITEM_SUCCESS,
    payload: { error }
  });

  // Check subscription status for Amazon
  static amazonCheckSubscriptionRequest = ({ productId }) => ({
    type: ActionTypes.CHECK_SUBSCRIPTION,
    payload: { productId }
  });
  static amazonCheckSubscriptionResolves = ({ subscription, error, isError, productId }) => ({
    type: isError ? ActionTypes.CHECK_SUBSCRIPTION_FAIL : ActionTypes.CHECK_SUBSCRIPTION_SUCCESS,
    payload: { subscription, productId, error }
  });

  // Get listing item by SKU
  static amazonGetListingsRequest = ({ marketId, productId }) => ({
    type: ActionTypes.GET_LISTINGS,
    payload: { marketId, productId }
  });
  static amazonGetListingsResolves = ({ data, error, isError }) => ({
    type: isError ? ActionTypes.GET_LISTINGS_FAIL : ActionTypes.GET_LISTINGS_SUCCESS,
    payload: { data, error }
  });

  // reset state to initial state
  static amazonListingReset = () => ({
    type: ActionTypes.LISTING_RESET
  });

  // Update state by given property
  static updateStateProperty = (payload) => ({
    type: ActionTypes.UPDATE_STATE_PROPERTY,
    payload
  });
}
