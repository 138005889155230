import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import { ConnectionsActions } from '../_redux/actions';
import { FormattedMessage } from 'react-intl';



export const DeutschePostSettingsCard = ({ connection, connectionName, isDPostLayout, setShowLayouts, layouts, loading }) => {
  const dispatch = useDispatch();

  // Deutsche Postun yeni Product API si açıldığında productlar buradan alınacak. Şu an bu değerler deutsche_post_products.js den alınıyor.
  useEffect(() => {
    connectionName === 'deutsche_post' && dispatch(ConnectionsActions.getDeutschePostConfigs());
    // eslint-disable-next-line
  }, [connectionName]);
  
  return (
    <Row className="mb-3">
      {(connectionName === 'deutsche_post' && !isDPostLayout) &&
        <Col lg="12">
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex flex-column mr-3'>
              <span className='font-weight-bolder mb-1'>
                <FormattedMessage id='CONNECTIONS.SHIPPER.D_POST.LAYOUT' />
              </span>
              <span>

                {layouts && layouts.find(option => { return option.value === connection?.deutsche_post?.layout; })?.label}
              </span>
            </div>

            <Button variant='primary' size='sm' className='font-weight-bold' onClick={() => setShowLayouts(true)} id="btn_layout_change" disabled={loading}>
              <FormattedMessage id='CONNECTIONS.SHIPPER.D_POST.LAYOUT_CHANGE' />
            </Button>
          </div>
        </Col>
      }
    </Row>
  )
}
