import React from 'react'
import { Icon } from "_metronic/_icons";
import { AdminRoutes } from "constants/moduleRoutes";
import { NewButton } from '_metronic/_partials';



export const SETTINGS_MENU = [
  {
    icon: <Icon.House />,
    title: "COMPANY.TITLE",
    route: AdminRoutes.SETTINGS_COMPANY,
    toolbar: <NewButton link={AdminRoutes.SETTINGS_COMPANY_EDIT} label="GENERAL.EDIT" className="ml-2" />,
  },
  {
    icon: <Icon.Lock />,
    title: "AUTH.GENERAL.ACCOUNT_SETTINGS",
    route: AdminRoutes.SETTINGS_LOGIN,
  },
  {
    icon: <Icon.Cash />,
    title: "ADMIN.SETTINGS.PRICING",
    route: AdminRoutes.SETTINGS_PRODUCT,
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.ORDER_TITLE",
    route: AdminRoutes.SETTINGS_DOCUMENT_FN('orders'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.INVOICE_TITLE",
    route: AdminRoutes.SETTINGS_DOCUMENT_FN('invoices'),
  },
  {
    icon: <Icon.Layers />,
    title: "DOCUMENT.CREDIT_TITLE",
    route: AdminRoutes.SETTINGS_DOCUMENT_FN('credits'),
  },
  {
    icon: <Icon.FileEarmarkMedical />,
    title: "SETTINGS.CONTRACT_TITLE",
    route: AdminRoutes.SETTINGS_CONTRACT,
  },
];

export const INITIAL_DEFAULT_PRODUCT = {
  id: '',
  title: '',
  sku: '',
  unit: "piece",
  tax_rate: '',
  gross_price: '',
};


export const INITIAL_VALUES = {

  // Default Product Settings
  product_settings: {
    settings_type: 'products',
    default_product: {
      tax: "",
      unit: "",
      currency: "EUR",
    },
    products: [
      {
        id: '',
        title: 'orders',
        sku: 'orders',
        unit: "piece",
        tax_rate: '',
        gross_price: 0,
        default: true,
      },
      {
        id: '',
        title: 'invoices',
        sku: 'invoices',
        unit: "piece",
        tax_rate: '',
        gross_price: 0,
        default: true,
      },
      {
        id: '',
        title: 'manuel_invoices',
        sku: 'manuel_invoices',
        unit: "piece",
        tax_rate: '',
        gross_price: 0,
        default: true,
      },
      {
        id: '',
        title: 'transactions',
        sku: 'transactions',
        unit: "piece",
        tax_rate: '',
        gross_price: 0.035,
        default: true,
      },
      {
        id: '',
        title: 'shippings',
        sku: 'shippings',
        unit: "piece",
        tax_rate: '',
        gross_price: 0,
        default: true,
      },
      {
        id: '',
        title: 'connections',
        sku: 'connections',
        unit: "piece",
        tax_rate: '',
        gross_price: 6,
        default: true,
      },
      {
        id: '',
        title: 'service_fee',
        sku: 'service_fee',
        unit: "piece",
        tax_rate: '',
        gross_price: 9.9,
        default: true,
      },
    ],
  },

  // Default Document Settings
  order_settings: {
    settings_type: 'orders',
    default_subtitle: {
      header: "Vielen Dank für Ihre Bestellung bei uns.",
      footer: "Bei Fragen oder Problemen zu Ihrer Bestellung helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
    },
    default_payment: {
      method: "",
    },
    default_number_range: {
      prefix: "",
      date: "YYYY",
      min_length: null,
      next_value: "",
    },
  },

  invoice_settings: {
    settings_type: 'invoices',
    default_subtitle: {
      header: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
      footer: "Bei Fragen oder Problemen zu Ihrer Rechnung helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
    },
    default_payment: {
      method: "",
    },
    default_number_range: {
      prefix: "",
      date: "YYYY",
      min_length: null,
      next_value: "",
    },
    print_details: {
      payment_method: true,
    },
  },

  credit_settings: {
    settings_type: 'credits',
    default_subtitle: {
      header: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
      footer: "Bei Fragen oder Problemen zu Ihrer Gutschrift helfen wir Ihnen gerne weiter.\nWir wünschen Ihnen einen schönen Tag."
    },
    default_payment: {
      method: "",
    },
    default_number_range: {
      prefix: "",
      date: "YYYY",
      min_length: null,
      next_value: "",
    },
  },

  // Default Dashboard Card View
  dashboard_settings: {
    id: '',
    settings_type: 'dashboard',
    default_card_view: {
      orders: true,
      sales: true,
      open_invoices: true,
      sales_by_channel: true,
      sales_by_country: true,
      sales_volume: true,
      top_products: true,
      top_customers: true,
    },
    currency: 'EUR',
  }
};

export const getSettingsByType = (settings) => {
  switch (settings.settings_type) {
    case 'products':
      return { productSettings: settings };
    case 'dashboard':
      return { dashboardSettings: settings };
    case 'orders':
      return { orderSettings: settings };
    case 'invoices':
      return { invoiceSettings: settings };
    case 'credits':
      return { creditSettings: settings };

    default:
      return;
  }
};

export const setSettingsByType = (userSettingsArray) => {
  return userSettingsArray.reduce((result, userSettings) => {
    switch (userSettings.settings_type) {
      case 'products':
        return { ...result, productSettings: userSettings };
      case 'dashboard':
        return { ...result, dashboardSettings: userSettings };
      case 'orders':
        return { ...result, orderSettings: userSettings };
      case 'invoices':
        return { ...result, invoiceSettings: userSettings };
      case 'credits':
        return { ...result, creditSettings: userSettings };

      default:
        return result;
    }
  }, {});
};

