import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext'
import EmailVerifyDialog from '../login-settings-dialog/EmailVerifyDialog';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { UserInfoDropdown } from '_metronic/_partials';
import { Alert, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';



export default function EmailSettings() {

  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);


  const { user, /* message */ } = useSelector(state => ({
    user: state.auth.user,
    //message: state.auth.message,
  }));


  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    openEmailEditDialog: UIContext.openEmailEditDialog,
  }),
    [UIContext.openEmailEditDialog]
  );


  useEffect(() => {
    const changeKey = query.get('changeKey');
    if (changeKey) {
      dispatch(AuthActions.changeEmail({ changeKey }));
      setShowModal(true);
      history.replace({ search: '' });

      /* setTimeout(() => {
          setShowModal(false);
          dispatch(AuthActions.cleanUpReducer());
        }, 3000);  */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);


  return (
    <Card className='card card-stretch gutter-b'>

      <CardSubHeader
        title={<FormattedMessage id='SETTINGS.LOGIN.EMAIL_TITLE' />}
        icon={<Icon.EnvelopeAt />}
        info={<UserInfoDropdown description={<FormattedMessage id='USER_GUIDE.SETTINGS.LOGIN_EMAIL' />} />}
      />

      <CardBody>

        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex flex-column mr-3'>
            <span className='font-weight-bolder mb-1'>
              <FormattedMessage id='GENERAL.EMAIL' />
            </span>
            <span>
              {user?.email}
            </span>
          </div>

          <Button variant='primary' size='sm' className='font-weight-bold' onClick={UIProps.openEmailEditDialog}>
            <FormattedMessage id='SETTINGS.LOGIN.CHANGE_EMAIL' />
          </Button>

        </div>

        {user?.new_email && (
          <>
            <div className="separator separator-dashed my-8" />
            <Alert variant='light-danger' className='alert-custom alert-notice flex-column' onClose={() => dispatch(AuthActions.loginSettings({ change_email: {} }))} dismissible>
              <FormattedMessage id='SETTINGS.LOGIN.NEW_EMAIL_VERIFY' />
              <span className='font-weight-bold mt-2'>
                {user.new_email}
              </span>
            </Alert>
          </>
        )}

      </CardBody>

      <EmailVerifyDialog show={showModal} />

    </Card>
  )
}
