import { call, put, takeEvery, select, fork } from 'redux-saga/effects';
import { adminUserMiddleware, tokenErrorHandler } from 'middlewares';
import { AdminUsersActions } from './actions';
import ActionTypes from './actionTypes';



// --------------------------- Users ---------------------------

// Get Users
function* getUsersSaga({ payload: { dateRange, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getUsers = yield call(adminUserMiddleware.getUsers, accessToken, dateRange, queryParams);
    const countUsers = yield call(adminUserMiddleware.getUsersCount, accessToken, '', queryParams);

    yield put(AdminUsersActions.getUsersSuccess(getUsers.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersActions.getUsersFail(JSON.stringify(err.response)));
  }
}


// Get User
function* getUser({ payload: { id } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getUser = yield call(adminUserMiddleware.getUser, accessToken, id);

    yield put(AdminUsersActions.getUserSuccess(getUser.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersActions.getUserFail(JSON.stringify(err.response)));
  }
}


// Update User
function* updateUser({ payload: { id, user } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminUserMiddleware.patchUser, accessToken, id, user);
    yield put(AdminUsersActions.updateUserSuccess(id, user));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersActions.updateUserFail(err.response));
  }
}


// Delete User
function* deleteUser({ payload: { id, user, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(adminUserMiddleware.deleteUser, accessToken, id, user);

    yield put(AdminUsersActions.deleteUserSuccess(response.data));

    yield fork(getUsersSaga, { payload: { queryParams } });

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersActions.deleteUserFail(err.response.data.error));
  }
}

// Get Users Reports
function* getReportsUsers({ payload: { dateRange, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getReports = yield call(adminUserMiddleware.getReportsUsers, accessToken, dateRange, queryParams);
    const countUsers = yield call(adminUserMiddleware.getUsersCount, accessToken, dateRange, queryParams);

    yield put(AdminUsersActions.getReportsUsersSuccess(getReports.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersActions.getReportsUsersFail(JSON.stringify(err.response)));
  }
}


// Get User Details
function* getUserCompany({ payload: { id, filter } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const userDetail = yield call(adminUserMiddleware.getUserCompany, accessToken, id, filter);

    yield put(AdminUsersActions.getUserCompanySuccess(userDetail.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersActions.getUserCompanyFail(err.response));
  }
}


function* getOrderReport({ payload: { id, filter } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const orderReport = yield call(adminUserMiddleware.getOrderReport, accessToken, id, filter);

    yield put(AdminUsersActions.getOrderReportSuccess(orderReport.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersActions.getOrderReportFail(err.response));
  }
}


function* getUserLogEntries({ payload: { id, order, search, filter, page, pageSize } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const logEntries = yield call(adminUserMiddleware.getUserLogEntries, accessToken, id, order, search, filter, page, pageSize);

    yield put(AdminUsersActions.getUserLogEntriesSuccess(logEntries.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersActions.getUserLogEntriesFail(err.response));
  }
}



export function* adminUsersSaga() {
  yield takeEvery(ActionTypes.GET_USERS, getUsersSaga);
  yield takeEvery(ActionTypes.GET_USER, getUser);
  yield takeEvery(ActionTypes.UPDATE_USER, updateUser);
  yield takeEvery(ActionTypes.DELETE_USER, deleteUser);
  yield takeEvery(ActionTypes.GET_REPORTS_USERS, getReportsUsers);
  yield takeEvery(ActionTypes.GET_USER_COMPANY, getUserCompany);
  yield takeEvery(ActionTypes.GET_ORDER_REPORT, getOrderReport);
  yield takeEvery(ActionTypes.GET_USER_LOG_ENTRIES, getUserLogEntries);
}
