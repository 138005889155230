import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { BlankMessage } from '_metronic/_partials/components'
import { Card, CardBody, CardHeader } from '_metronic/_partials/controls'
import { useUserContactUIContext } from './_context/UserContactUIContext'
import { UserContactFilter } from './user-contact-filter/UserContactFilter'
import UserContactTable from './user-contact-table/UserContactTable'
import { AdminUsersContactActions } from './_redux/actions'
import { LoadingDialog } from '_metronic/_partials/loading'
import { Icon } from '_metronic/_icons'



export default function UserContactCard() {

  const dispatch = useDispatch();

  const { count, contact, loading, totalCount } = useSelector(state => state.adminUsersContact);

  const UIContext = useUserContactUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    setOrderQuery: UIContext.setOrderQuery,
    setPaginationQuery: UIContext.setPaginationQuery,
    setSearchQuery: UIContext.setSearchQuery,
    setFilterQuery: UIContext.setFilterQuery,
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openUserDetailsDialog: UIContext.openUserDetailsDialog,
  }), [UIContext]);

  useEffect(() => {
    let delayDebounceFn;
    if (UIProps.queryParams.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(AdminUsersContactActions.getUsersContact(UIProps.queryParams))
      }, 350);
    } else {
      dispatch(AdminUsersContactActions.getUsersContact(UIProps.queryParams))
    }

    return () => { clearTimeout(delayDebounceFn) };
  }, [dispatch, UIProps.queryParams]);

  const btnRef = useRef();
  // const handleSubmit = () => {
  //   if (btnRef && btnRef.current) {
  //     btnRef.current.click();
  //   }
  // }


  return (
    <Card className='card-height'>

      <CardHeader
        icon={<Icon.PersonLines />}
        title={<FormattedMessage id="GENERAL.CONTACT" />}
        className="display-5"
        id='title_user'
      >
        {/* <CardHeaderToolbar>
          {contact?.length !== 0 &&
            <Button variant='primary' className="font-weight-bold ml-auto" onClick={handleSubmit}>
              <FormattedMessage id='REPORT.EXPORT_TO_CSV' />
            </Button>}
        </CardHeaderToolbar> */}
      </CardHeader>

      <CardBody>
        {totalCount === 0 && UIProps.queryParams.search === ''
          ? <BlankMessage
            icon={<Icon.Customer />}
            title={<FormattedMessage id='CUSTOMER.BLANK_MESSAGE_TITLE' />}
            // message={<FormattedMessage id='CUSTOMER.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
          />
          : <>
            <UserContactFilter
              UIProps={UIProps}
            />
            <UserContactTable
              loading={loading}
              contact={contact}
              count={count}
              UIProps={UIProps}
              btnRef={btnRef}
            />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  )
}
