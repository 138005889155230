import React from "react";
import { Icon } from '_metronic/_icons';
import { ModuleRoutes } from "./moduleRoutes";
//import { IsDevDomainFn } from "./apiUrl";



// TODO: License kontrolü yapılacak ancak şu an daha yapılmadı
const developmentLicense = ["development"];
//const testerLicense = ["development", "tester"];
const pilotLicense = ["development", "tester", "test_user"];
const productionLicense = ['development', 'tester', 'test_user', 'trial', 'standard', 'premium', 'admin',];


export const CONNECTION_SETTINGS = [
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "marketplace",
    name: "ebay",
    label: "Ebay",
    icon: <Icon.Ebay />,
    iconLong: <Icon.EbayLong />,
    listingRoute: (id) => ModuleRoutes.EBAY_LISTINGS_FN(id),
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "marketplace",
    name: "kaufland",
    label: "Kaufland",
    icon: <Icon.Kaufland />,
    iconLong: <Icon.KauflandLong />,
    listingRoute: (id) => ModuleRoutes.KAUFLAND_LISTINGS_FN(id),
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "marketplace",
    name: "amazon",
    label: "Amazon",
    icon: <Icon.Amazon />,
    iconLong: <Icon.AmazonLong />,
    listingRoute: (id) => ModuleRoutes.AMAZON_LISTINGS_FN(id),
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "marketplace",
    name: "amazon_prime",
    label: "Amazon Prime",
    icon: <Icon.AmazonPrimeLong />,
    iconLong: <Icon.AmazonPrimeLong />,
    listingRoute: (id) => ModuleRoutes.AMAZON_LISTINGS_FN(id),
    product: (license) => license //Development license ile kullanılabilecek
  },
  {
    active: true,
    license: developmentLicense,
    multiple: false,
    type: "marketplace",
    name: "hood",
    label: "Hood (Alpha)",
    icon: <Icon.Hood />,
    iconLong: <Icon.HoodLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: false,
    license: developmentLicense,
    multiple: false,
    type: "marketplace",
    name: "check24",
    label: "Check24 (Deprecated)",
    icon: <Icon.Check24 />,
    iconLong: <Icon.Check24Long />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: developmentLicense,
    multiple: false,
    type: "marketplace",
    name: "manomano",
    label: "Manomano (Alpha)",
    icon: <Icon.Manomano />,
    iconLong: <Icon.ManomanoLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    type: "shop",
    multiple: true,
    name: "prestashop",
    label: "PrestaShop",
    icon: <Icon.PrestaShop />,
    iconLong: <Icon.PrestaShopLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "shop",
    name: "shopware5",
    label: "Shopware 5",
    icon: <Icon.Shopware />,
    iconLong: <Icon.ShopwareLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "shop",
    name: "shopware6",
    label: "Shopware 6",
    icon: <Icon.Shopware />,
    iconLong: <Icon.Shopware6Long />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: pilotLicense,
    multiple: false,
    type: "marketplace",
    name: "otto",
    label: "Otto",
    icon: <Icon.Otto />,
    iconLong: <Icon.OttoLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "shop",
    name: "woocommerce",
    label: "Woocommerce",
    icon: <Icon.Woocommerce />,
    iconLong: <Icon.WoocommerceLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: true,
    type: "shop",
    name: "shopify",
    label: "Shopify",
    icon: <Icon.Shopify />,
    iconLong: <Icon.ShopifyLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: developmentLicense,
    multiple: true,
    type: "marketplace",
    name: "etsy",
    label: "Etsy",
    icon: <Icon.Etsy />,
    iconLong: <Icon.EtsyLong />,
    listingRoute: (id) => (id),
    product: (license) => false,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dhl",
    label: "DHL",
    icon: <Icon.DhlOld className='rounded' />,
    iconLong: <Icon.DhlOldLong />,
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dhl_new",
    label: "DHL (Neue API)",
    icon: <Icon.Dhl className='rounded' />,
    iconLong: <Icon.DhlLong />,
    product: (license) => true
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dhl_warenpost",
    label: "DHL Kleinpaket",
    icon: <Icon.DhlOldWarenpost className='rounded' />,
    iconLong: <Icon.DhlOldWarenpostLong />,
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dhl_new_warenpost",
    label: "DHL Kleinpaket (Neue API)",
    icon: <Icon.DhlWarenpost className='rounded' />,
    iconLong: <Icon.DhlWarenpostLong />,
    product: (license) => true
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "gls",
    label: "GLS (Beta)",
    icon: <Icon.Gls className='rounded' />,
    iconLong: <Icon.GlsLong />,
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "dpd",
    label: "DPD",
    icon: <Icon.Dpd className='rounded' />,
    iconLong: <Icon.DpdLong />,
    product: (license) => true,
  },
  {
    active: true,
    license: productionLicense,
    multiple: false,
    type: "shipper",
    name: "deutsche_post",
    label: "Deutsche Post",
    icon: <Icon.DeutschePost className='rounded' />,
    iconLong: <Icon.DeutschePostLong />,
    product: (license) => true,
  },
  {
    // Dummy data sadece customer table için eklendi
    active: false,
    license: productionLicense,
    multiple: false,
    type: "shop",
    name: "salevali",
    label: "SaleVali",
    icon: <Icon.ShopWindow className="text-dark-50" />,
    iconLong: <Icon.Shop />,
    product: (license) => false,
  }
]

export const getConnection = (name) => (CONNECTION_SETTINGS.find(market => market.name === name?.toLowerCase()))


export const getOrderLink = (marketplace, order_id, marketplace_url) => {
  switch (marketplace) {

    case 'ebay':
      return `https://www.ebay.de/mesh/ord/details?orderid=${order_id}`;

    case 'amazon':
    case 'amazon_prime':
      return `https://sellercentral-europe.amazon.com/orders-v3/order/${order_id}`;

    case 'otto':
      return `https://portal.otto.market/orders#/details/${order_id}/articles`;

    case 'kaufland':
      return `https://sellerportal.kaufland.de/order/${order_id}`;

    case 'manomano': //TODO: test edilmedi
      return `https://partners.manomano.com/orders/${order_id}`;

    case 'hood': //TODO: test edilmedi
      return `https://www.hood.de/order/${order_id}`;

    case 'etsy': //TODO: test edilmedi
      return `https://www.etsy.com/your/orders/sold/${order_id}`;

    case 'shopware6':
      return `${marketplace_url}/admin#/sw/order/detail/${order_id}/general`;

    case 'shopify':
      return `${marketplace_url}/admin/orders/${order_id}`;

    case 'woocommerce':
      return `${marketplace_url}/wp-admin/post.php?post=${order_id}&action=edit`;

    case 'shopware5':
      return `${marketplace_url}`;

    case 'prestashop':
      return `${marketplace_url}`;


    default:
      return '';
  }
};



export const getShipperTrackingLink = (shipper, trackingId) => {
  switch (shipper) {

    case 'dhl':
    case 'dhl_warenpost':
    case 'dhl_new':
    case 'dhl_new_warenpost':
    case 'deutsche_post':
      return `https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=${trackingId}`;

    case 'gls':
      return `https://www.gls-pakete.de/sendungsverfolgung?trackingNumber=${trackingId}`;

    case 'dpd':
      return `https://tracking.dpd.de/status/de_DE/parcel/${trackingId}`;

    default:
      return '';
  }
};
