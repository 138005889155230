import React, { useMemo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { useUserInvoicesUIContext } from '../_context/UserInvoicesUIContext';
import { AdminUserInvoicesActions } from '../_redux/actions';
import { Button, Modal } from 'react-bootstrap';
import { SelectCounter } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function SendEmailsDialog() {

  const UIContext = useUserInvoicesUIContext();
  const UIDocContext = useDocumentsUIContext();

  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    showSendEmailsDialog: UIContext.showSendEmailsDialog,
    handleCloseSendEmailsDialog: UIContext.handleCloseSendEmailsDialog,
    templates: UIContext.templates,
    requestedDocument: UIDocContext.requestedDocument,
    reminder: UIContext.reminder,
  }),
    [UIContext, UIDocContext]
  );

  const { printLoading, error, emailSuccess } = useSelector(state => ({
    printLoading: state.adminUserInvoices.printLoading,
    emailSuccess: state.adminUserInvoices.emailSuccess,
    error: state.adminUserInvoices.error,
  }),
    shallowEqual
  );

  const [selectedIds, setSelectedIds] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!UIProps.showSendEmailsDialog || !(UIProps.ids.length > 0)) {
      return UIProps.handleCloseSendEmailsDialog();
    }
    setSelectedIds([...UIProps.ids]);
  }, [dispatch, UIProps, UIProps.ids]);

  const sendSelectedDocumentWithEmail = selIds => {

    if (selIds.length) {
      const data = {
        ids: selIds,
        user_invoice_action: UIProps.requestedDocument.type,
        isEmail: true,
        ...(UIProps.reminder && { reminder: UIProps.reminder })
      };
      dispatch(AdminUserInvoicesActions.printDocument(data));
    }
  };

  useEffect(() => {
    if (emailSuccess) {
      setTimeout(() => {
        UIProps.handleCloseSendEmailsDialog()
        // dispatch(DocumentActions.clearLoadingEffects());
        UIProps.setIds([]);
        setSelectedIds([]);
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSuccess, UIProps.ids]);


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.showSendEmailsDialog} onHide={UIProps.handleCloseSendEmailsDialog} aria-labelledby="example-modal-sizes-title-standard" centered>

        <Modal.Body className='px-10 px-6'>

          <div className="d-flex flex-column align-items-center">
            {emailSuccess
              ? <Icon.CheckCircle className="text-success icon-5x my-5" />
              : <Icon.EnvelopeAt className="text-dark-75 icon-4x my-6" />
            }
          </div>

          <h6 className="text-center font-weight-bold">
            {emailSuccess
              ? <FormattedMessage id='DOCUMENT.REDUX.SEND_SELECTED_DOCUMENT_WITH_EMAIL.SUCCESS' />
              : error
                ? <span className="text-danger">
                  {error?.message ? error.message : <FormattedMessage id='GENERAL.ERROR_MESSAGE' />}
                </span>
                : <FormattedMessage id="DOCUMENT.EMAIL_SEND.CONTENT" />
            }
          </h6>
        </Modal.Body>

        <Modal.Footer>

          <SelectCounter count={UIProps.ids.length} />

          <Button variant='light' className='ml-auto' onClick={UIProps.handleCloseSendEmailsDialog} disabled={printLoading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>

          {!emailSuccess &&
            <Button variant='primary' className='ml-2' onClick={() => sendSelectedDocumentWithEmail(selectedIds)} disabled={printLoading} id="btn_send_email">
              <FormattedMessage id="GENERAL.SEND" />
            </Button>}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
