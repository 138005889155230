import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAmazonItem } from '../_redux/reducer';
// components
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { AmazonListingsAddProductHeader } from './AmazonListingsAddProductHeader';
import { Icon } from '_metronic/_icons';
import { Card, CardBody, CardSubHeader, SVInput } from '_metronic/_partials/controls';
import { AmazonSuggestedProductList1 } from '../amazon-suggested-product-list/AmazonSuggestedProductList';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { AmazonListingsActions } from '../_redux/actions';
import { AmazonListingsAddProductForm } from './AmazonListingsAddProductForm';
import { toast } from 'react-toastify';

const setupInitialValues = (product) => {
  if (!product)
    return {
      sku: '',
      price: 0,
      quantity: 0,
      condition: 'new_new',
      fulfillmentStrategy: 'FBM'
    };
  return {
    sku: product.sku,
    price: product.pricing.gross_price,
    quantity: product.quantity,
    condition: 'new_new',
    fulfillmentStrategy: 'FBM'
  };
};

export function AmazonListingsSearchProduct({
  match: {
    params: { tokenId }
  }
}) {
  const dispatch = useDispatch();

  const productListingsUIContext = useProductListingsUIContext();
  const UIProps = useMemo(
    () => ({
      backToMainPage: productListingsUIContext.backToMainPage
    }),
    [productListingsUIContext.backToMainPage]
  );

  useEffect(() => {
    return () => {
      dispatch(AmazonListingsActions.updateStateProperty({ item: null }));
    };
  }, [dispatch]);

  // get amazon item from store
  const item = useSelector(selectAmazonItem);
  const status = useSelector((state) => state.amazonListings.status);
  // get product from store by productId
  const amazonConnection = useSelector((state) => state.connections.entities.find((entity) => entity.id === tokenId));
  const marketplaceId = amazonConnection && amazonConnection.amazon && amazonConnection.amazon.MarketplaceID;

  const onSearchSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const asin = formData.get('searchProductByASIN');
    if (!asin) {
      toast.error('Please enter ASIN number to search product on Amazon');
      return;
    }
    dispatch(AmazonListingsActions.amazonFindListingItemRequest({ asin, tokenId }));
  };

  const onAddListingToAmazon = useCallback(
    (values, action) => {
      const marketplaceIds = [marketplaceId] || [];
      const newProductDetails = {
        tokenId: tokenId,
        asin: values.asin,
        sku: values.sku,
        listings: {
          market: 'Amazon',
          market_id: tokenId,
          asin: values.asin,
          marketplace_ids: marketplaceIds || [],
          status: 'DRAFT',
          catalog_item: null,
          listings_item: {
            productType: values.productType,
            attributes: {
              merchant_suggested_asin: marketplaceIds.map((mpId) => ({ value: values.asin, marketplace_id: mpId })),
              condition_type: marketplaceIds.map((mpId) => ({ value: values.condition, marketplace_id: mpId })),
              merchant_shipping_group: marketplaceIds.map((mpId) => ({
                value: values.shippingGroup,
                marketplace_id: mpId
              })),
              fulfillment_availability: [
                {
                  fulfillment_channel_code: values.fulfillmentStrategy,
                  quantity: typeof values.quantity === 'number' ? values.quantity : parseInt(values.quantity)
                }
              ],
              purchasable_offer: marketplaceIds.map((mpId) => ({
                currency: 'EUR',
                marketplace_id: mpId,
                our_price: [
                  {
                    schedule: [
                      {
                        value_with_tax: typeof values.price === 'number' ? values.price : parseInt(values.price)
                      }
                    ]
                  }
                ]
              }))
            }
          }
        }
      };

      dispatch(AmazonListingsActions.addNewProductRequest(newProductDetails));
    },
    [dispatch, marketplaceId, tokenId]
  );

  const btnRef = useRef();
  const saveButtonClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  return (
    <>
      <Card className="card-box">
        <AmazonListingsAddProductHeader backToMainPage={UIProps.backToMainPage} saveButtonClick={saveButtonClick} />
      </Card>

      {status === 'success/[listing/amazon] ADD_NEW_PRODUCT_SUCCESS' && (
        <div className="alert alert-success mt-4" role="alert">
          <div className="alert-text">Product found on Amazon successfully added to the database</div>
        </div>
      )}

      <Card className="card-box">
        <CardBody>
          <Card className="gutter-b">
            <CardSubHeader title={'Search product on Amazon'} icon={<Icon.Tag />} id="IdSearchProductOnAmazon" />
            <CardBody>
              <form className="row" onSubmit={onSearchSubmit}>
                <div className="col-sm-12 col-xl-6 d-flex flex-row" style={{ maxWidth: '768px' }}>
                  <SVInput type="text" name="searchProductByASIN" placeholder="Give a ASIN number to search product on Amazon" />
                  <Button variant="primary" type="submit" className="font-weight-bold ml-2">
                    <FormattedMessage id="GENERAL.SEARCH" />
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>

          {item && (
            <>
              <Card className="gutter-b">
                <CardSubHeader title={'Produktinfo'} icon={<Icon.Tag />} id="general" />
                <CardBody>
                  <AmazonSuggestedProductList1 item={item} />
                </CardBody>
              </Card>

              <Card className="gutter-b">
                <CardSubHeader title={<FormattedMessage id="PRODUCT.LISTINGS" defaultMessage="Listings" />} icon={<Icon.Tag />} id="general" />
                <CardBody>
                  <AmazonListingsAddProductForm
                    noReadOnly
                    onAddListingToAmazon={onAddListingToAmazon}
                    initialValues={setupInitialValues()}
                    item={item}
                    btnRef={btnRef}
                    marketplaceIds={[{ value: marketplaceId, label: 'DE' }]}
                  />
                </CardBody>
              </Card>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
