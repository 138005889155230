import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

// import persist configuration
import { persistConfigs } from './persist-configs';

// import auth types for clean up persists after logout
import AuthActionTypes from 'app/pages/auth/_redux/actionTypes';

// import all reducer
import { authReducer } from 'app/pages/auth/_redux/reducer';
import { productsReducer } from 'app/modules/products/_redux/reducer';
import { supplierReducer } from 'app/modules/suppliers/_redux/reducer';
import { customersReducer } from 'app/modules/customers/_redux/reducer';
import { documentsReducer } from 'app/modules/documents/_redux/reducer';
import { connectionsReducer } from 'app/modules/connections/_redux/reducer';
import { archiveReducer } from 'app/modules/archive/_redux/reducer';
import { importExportReducer } from 'app/modules/import-export/_redux/reducer';
import { supportsReducer } from 'app/admin/support/_redux/reducer';
import { listingsReducer } from 'app/modules/product-listings/_redux/reducer';
import { dashboardReducer } from 'app/modules/dashboard/_redux/reducer';
import { notificationReducer } from '_metronic/layout/components/header/_redux/reducer';
import { settingsReducer } from 'app/modules/settings/_redux/reducer';
import { adminSettingsReducer } from 'app/admin/settings/_redux/reducer';
import { companyReducer } from 'app/modules/settings/company/_redux/reducer';
import { categoriesReducer } from 'app/modules/settings/category/_redux/reducer';
import { reportReducer } from 'app/modules/report/_redux/reducer';
import { amazonListingReducer } from 'app/modules/product-listings/amazon-listings/_redux/reducer';
import { shippingsReducer } from 'app/modules/shippings/_redux/reducer';
import { digitalProductReducer } from 'app/modules/digital-product/_redux/reducer';
import { adminUsersReducer } from 'app/admin/users/_redux/reducer';
import { adminTempUsersReducer } from 'app/admin/temp-users/_redux/reducer';
import { adminUsersContactReducer } from 'app/admin/users-contact/_redux/reducer';
import { adminUserInvoicesReducer } from 'app/admin/user-invoices/_redux/reducer';
import { adminUsersReportsReducer } from 'app/admin/reports-users/_redux/reducer';


// then we create the app reducer, by combining all other reducers
const appReducer = combineReducers({
  auth: authReducer,
  products: productsReducer,
  customers: customersReducer,
  documents: documentsReducer,
  listings: listingsReducer,
  dashboard: dashboardReducer,
  suppliers: supplierReducer,
  connections: connectionsReducer,
  importExport: importExportReducer,
  archive: archiveReducer,
  supports: supportsReducer,
  adminUsers: adminUsersReducer,
  adminTempUsers: adminTempUsersReducer,
  adminUsersContact:adminUsersContactReducer,
  adminUserInvoices:adminUserInvoicesReducer,
  adminUsersReports: adminUsersReportsReducer,
  notification: notificationReducer,
  settings: settingsReducer,
  adminSettings: adminSettingsReducer,
  company: companyReducer,
  categories: categoriesReducer,
  reports: reportReducer,
  amazonListings: amazonListingReducer,
  shippings: shippingsReducer,
  digitalProduct: digitalProductReducer
});

// then we create the root reducer, if user try logout action we must clear localStorage
const rootReducer = (state, action) => {
  if (action.type === AuthActionTypes.LOGOUT_SUCCESS || action.type === AuthActionTypes.LOGOUT_FAIL) {
    state = undefined;
  }
  return appReducer(state, action);
};

// finally we export the persisted reducer
export const persistedRootReducer = persistReducer(persistConfigs.root, rootReducer);
