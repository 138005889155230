import React, { useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext';
import { INITIAL_DEFAULT_PRODUCT } from '../../_context/SettingsHelper';
import { maxValidation, UTILS } from '_metronic/_helpers';
import { ProductSettingsForm } from '../ProductSettingsForm';
import { Button, Modal } from 'react-bootstrap';
import { SettingsActions } from '../../_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';



export function ProductSettingsDialog() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showProductSettingsDialog,
    onHide: UIContext.closeProductSettingsDialog,
    productId: UIContext.productId,
  }), [UIContext]);

  const { id: settingsId, settings_type, product_settings } = useSelector(state => ({
    id: state.settings.productSettings.id,
    settings_type: state.settings.productSettings.settings_type,
    product_settings: state.settings.productSettings,
    loading: state.settings.loading,
  }), shallowEqual);

  const foundProduct = product_settings?.products?.find(item => item?.id === UIProps?.productId);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(intl({ id: 'GENERAL.REQUIRED' })),
    sku: Yup.string().strict(true)
      .trim(intl({ id: "PRODUCT.GENERAL.SKU_TRIM" }))
      // .matches(/^(?!.*\s)(?!.*[A-Za-z]{4}\s)[A-Za-z0-9\s]+$/, intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
      .required(intl({ id: "PRODUCT.GENERAL.SKU_REQUIRED" }))
      .max(50, maxValidation("50")),
    gross_price: Yup.number()
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .min(0, intl({ id: "PRODUCT.GENERAL.PRICE_THAN" }))
      .test('Is valid decimal?', intl({ id: "DOCUMENT.POSITION.IS_VALID.DECIMALS" }), value => {
        if (!value) return true;
        const decimalPart = (value.toString().split('.')[1] || '').length;
        return decimalPart <= 5;
      })
      .max(10000000, intl({ id: "PRODUCT.GENERAL.PRICE_MAX" })),
    tax_rate: Yup.number()
      .required(intl({ id: 'GENERAL.REQUIRED' }))

  });

  const submitByRef = useRef();
  const submitByRefClick = () => {
    submitByRef.current && submitByRef.current.click();
  };


  const saveShipping = (values) => {

    const updateExistingProduct = (values) => {
      const updatedProductList = product_settings.products.map(item =>
        item.id === values.id
          ? { ...values, updated_at: new Date() } // updated_at alanını ekle
          : item
      );
      dispatch(SettingsActions.patchUserSettings(settingsId, settings_type, { products: updatedProductList }));
    };


    const addNewProduct = (values) => {
      const { id, ...rest } = values;
      const newValues = {
        id: UTILS.uuidv4(),
        ...rest,
        created_at: new Date(),
      };

      // Eğer yeni eklenen gönderim "default" ise, mevcut tüm gönderimleri "default: false" olarak güncelle
      const updatedDefaultProduct = newValues.default
        ? product_settings.products.map(item => ({ ...item, default: false }))
        : product_settings.products;

      if (settingsId && settings_type === 'products') {
        const defaultProduct = [...updatedDefaultProduct, newValues];
        dispatch(SettingsActions.patchUserSettings(settingsId, settings_type, { products: defaultProduct }));

      }
    };

    if (UIProps.productId) {
      const { id, ...rest } = values;
      const updatedValues = {
        ...rest,
        id: UIProps.productId,
      };
      updateExistingProduct(updatedValues);
    } else {
      addNewProduct(values);
    }

    UIProps.onHide();
  };


  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} size="md" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Header className='py-3'>

        <Modal.Title>
          <FormattedMessage id={foundProduct ? 'ADMIN.SETTINGS.SERVICE_EDIT' : 'ADMIN.SETTINGS.SERVICE_ADD'} />
        </Modal.Title>

      </Modal.Header>

      <Modal.Body>

        <Formik enableReinitialize={true} initialValues={UIProps.productId ? foundProduct : INITIAL_DEFAULT_PRODUCT} validationSchema={validationSchema} onSubmit={saveShipping}>

          <Form autoComplete="off">

            <ProductSettingsForm productId={UIProps.productId} products={product_settings?.products} />
            <button type="submit" className='d-none' ref={submitByRef} />

          </Form>
        </Formik>

      </Modal.Body>

      <Modal.Footer>

        <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='primary' className="ml-2" onClick={submitByRefClick} id="btn_save_modal">
          <FormattedMessage id="GENERAL.SAVE" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
