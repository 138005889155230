export default class ActionTypes {
  static get PREFIX() {
    return '[adminUsersReports]';
  }



  // Get all users reports
  static get GET_REPORTS_USERS() {
    return `${ActionTypes.PREFIX}GET_REPORTS_USERS`;
  }
  static get GET_REPORTS_USERS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_REPORTS_USERS_SUCCESS`;
  }
  static get GET_REPORTS_USERS_FAIL() {
    return `${ActionTypes.PREFIX}GET_REPORTS_USERS_FAIL`;
  }

  // Clean up the user module
  static get CLEAN_UP_USER_MODULE() {
    return `${this.PREFIX}USERS_CLEAN_UP`;
  }
  static get CLEAN_UP_USER_MODULE_PARAMS() {
    return `${this.PREFIX}USERS_CLEAN_UP_PARAMS`;
  }

}