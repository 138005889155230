import React from 'react';
import { Field, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  sku: Yup.string().required('SKU is required').min(10, 'SKU must be at least 10 characters'),
  quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1'),
  price: Yup.number().required('Price is required').min(0.01, 'Price must be valid'),
  fulfillmentStrategy: Yup.string().required('Fulfillment strategy is required').oneOf(['DEFAULT', 'AMAZON_EU'], 'Invalid fulfillment strategy'),
});


export function AmazonListingsAddProductForm({ onAddListingToAmazon, initialValues, item, btnRef, marketplaceIds, noReadOnly, hiddenInputs }) {
  const formInputs = [
    {
      name: 'sku',
      label: 'SKU',
      type: 'text',
      placeholder: 'Enter SKU',
      readOnly: noReadOnly ? false : true,
      hidden: hiddenInputs?.includes('sku')
    },
    {
      name: 'productType',
      label: 'Product Type',
      type: 'text',
      placeholder: 'Enter Product Type',
      readOnly: true,
      hidden: true
    },
    {
      name: 'condition',
      label: 'Condition',
      type: 'select',
      options: [{ value: 'new_new', label: 'New' }],
      readOnly: true,
      hidden: hiddenInputs?.includes('condition')
    },
    {
      name: 'quantity',
      label: 'Quantity',
      type: 'number',
      placeholder: 'Enter Quantity',
      readOnly: noReadOnly ? false : true,
      hidden: hiddenInputs?.includes('quantity')
    },
    {
      name: 'price',
      label: 'Price',
      type: 'number',
      placeholder: 'Enter Price',
      readOnly: noReadOnly ? false : true,
      hidden: hiddenInputs?.includes('price')
    },
    {
      name: 'marketplaceIds',
      label: 'Marketplace',
      type: 'select',
      options: marketplaceIds.map((marketplace) => ({ value: marketplace.id, label: marketplace.label })),
      readOnly: false
    },
    {
      name: 'fulfillmentStrategy',
      label: 'Fulfillment strategy',
      type: 'select',
      options: [
        { value: 'DEFAULT', label: 'Merchant Fulfilled' },
        { value: 'AMAZON_EU', label: 'Amazon Fulfilled' }
      ]
    },
    {
      name: 'shippingGroup',
      label: 'Shipping Group',
      type: 'select',
      options: [
        { value: '04459b5b-529c-4c35-a5d1-5aa004b96403', label: 'Kostenloser Versand' },
        { value: 'da311b9b-eb51-41e9-9489-e737bcb37911', label: 'Prime vorlage' },
        { value: 'legacy-template-id', label: 'Standardvorlage Amazon' },
        { value: '4d077637-1a55-4748-90fa-7372c070ddb6', label: 'Textilversand', hidden: true },
        { value: 'legacy-book-template-id', label: 'Vorlage für Bücher Ihrer bisherigen Versandeinstellungen', hidden: true },
        { value: 'legacy-dvd-template-id', label: 'Vorlage für DVD Ihrer bisherigen Versandeinstellungen', hidden: true },
        { value: 'legacy-music-template-id', label: 'Vorlage für Musik Ihrer bisherigen Versandeinstellungen', hidden: true },
        { value: 'legacy-video-template-id', label: 'Vorlage für Video Ihrer bisherigen Versandeinstellungen', hidden: true },
      ],
      readOnly: false
    }
  ];


  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        asin: item.asin,
        productType: item.productType,
      }}
      onSubmit={(values, actions) => onAddListingToAmazon(values, actions)}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {/* Hidden inputs for ASIN and product type */}
          <input type="text" name="asin" value={item.asin} readOnly hidden />
          <input type="text" name="productType" value={item.productType} readOnly hidden />
          <Row>
            {/* Render form inputs dynamically */}
            {formInputs.map((input) => {
              if (input.type === 'select') {
                return (
                  <Col md="6" lg={input.size || '4'} key={`amazonlistingform-${input.name}`} style={{ display: input.hidden ? 'none' : 'block' }}>
                    <Field
                      component={input.component || SVReactSelect}
                      {...{
                        name: input.name,
                        label: input.label,
                        type: input.type,
                        options: input.options.filter((option) => !option?.hidden),
                        placeholder: input.placeholder,
                        withFeedbackLabel: true,
                        feedbackLabel: true,
                        required: item.required,
                        ...(input.readOnly && { disabled: true })
                      }}
                    />
                  </Col>
                );
              }
              return (
                <Col md="6" lg={input.size || '4'} key={`amazonlistingform-${input.name}`} style={{ display: input.hidden ? 'none' : 'block' }}>
                  <Field
                    component={input.component || SVFormControl}
                    {...{
                      name: input.name,
                      type: input.type,
                      label: input.label,
                      options: input.options,
                      placeholder: input.placeholder,
                      withFeedbackLabel: true,
                      feedbackLabel: true,
                      required: item.required,
                      ...(input.readOnly && { disabled: true })
                    }}
                  />
                </Col>
              )
            })}
          </Row>

          <button type="submit" className="d-none" ref={btnRef} />
        </form>
      )}
    </Formik>
  );
}

