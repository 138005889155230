import React from 'react'
import { useDispatch } from 'react-redux';
import { Portal } from 'react-portal';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseButton } from '_metronic/_partials';
import { Field, Form, Formik } from 'formik';
import { SVFormControl } from '_metronic/_partials/controls';
import { SettingsActions } from '../../../_redux/actions';
import * as Yup from 'yup';



export default function OssEditDialog({ show, setShow, loading, documentSettings, documentType }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const { oss_details } = documentSettings;

  const INPUT = [
    {
      type: 'number',
      name: "total_sales_other",
      label: 'SETTINGS.DOCUMENT.OSS.TOTAL_SALES_OTHER',
      min: 0,
      step: 0.01,
    },
  ];


  const handleOss = (values) => {
    const newValues = {
      ...documentSettings,
      ...(documentType === 'invoices' && {
        oss_details: {
          ...documentSettings.oss_details,
          total_sales_other: values.total_sales_other,
        }
      }),
    };

    documentSettings.id
      ? dispatch(SettingsActions.updateUserSettings(documentSettings.id, newValues))
      : dispatch(SettingsActions.createUserSettings(newValues))
    //Oss yi güncelle
    dispatch(SettingsActions.getOss('settings'));
    setShow(false);
  }

  const validationSchema = Yup.object().shape({
    total_sales_other: Yup.number()
      .required(intl({ id: 'GENERAL.REQUIRED' }))
      .min(0, intl({ id: 'PRODUCT.GENERAL.PRICE_THAN' }))
      .max(9999999, intl({ id: 'DOCUMENT.SHIPPING.MAX_PRICE' })),
  });


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={show} onHide={() => setShow(false)} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false} centered>

        <Formik enableReinitialize={true} initialValues={oss_details} validationSchema={validationSchema} onSubmit={handleOss}>

            <Form>
              <Modal.Body className='p-10'>
                <CloseButton onClick={() => setShow(false)} />
                <div className="d-flex flex-column align-items-center text-dark-75 my-8">
                  {/* <Icon.FileText className="icon-5x mb-3" /> */}
                  <h3>
                    <FormattedMessage id='SETTINGS.DOCUMENT.OSS.TOTAL_SALES_OTHER' />
                  </h3>
                </div>

                <Row>
                  <Col md={12}>
                    <Alert variant='light-info' className="alert-custom alert-notice mb-6">
                      <div className="text-dark-75 line-height-xl">
                        <FormattedMessage id='SETTINGS.DOCUMENT.OSS.TOTAL_SALES_OTHER.INFO' />
                      </div>
                    </Alert>
                  </Col>

                  {INPUT.map((item, i) => (
                    <Col md={12} key={i}>
                      <Field component={SVFormControl}
                        {...{
                          type: item.type,
                          name: item.name,
                          label: intl({ id: item.label }),
                          withFeedbackLabel: true,
                          feedbackLabel: true,
                          disabled: loading,
                          min: item.min,
                          step: item.step,
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant='light' onClick={() => setShow(false)} id='btn_close_modal_email'>
                  <FormattedMessage id="GENERAL.CLOSE" />
                </Button>

                <Button variant='primary' type="submit" className='ml-2' disabled={loading}>
                  <FormattedMessage id='SETTINGS.LOGIN.CHANGE_SAVE' />
                </Button>
              </Modal.Footer>

            </Form>
        </Formik>

      </Modal>
    </Portal>
  )
}
