import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';



export class AdminUserInvoicesActions {


  /**
  * get all user invoices
  */
  static getUserInvoices = (queryParams, dateRange, documentType) => {
    return {
      type: ActionTypes.GET_USER_INVOICES,
      payload: {
        queryParams,
        dateRange,
        documentType
      },
    };
  };

  static getUserInvoicesSuccess = (data, count) => {
    return {
      type: ActionTypes.GET_USER_INVOICES_SUCCESS,
      payload: {
        data,
        count,
      },
    };
  };

  static getUserInvoicesFail = () => {
    return {
      type: ActionTypes.GET_USER_INVOICES_FAIL,
    };
  };

  static getUserInvoiceById = (id) => {
    return {
      type: ActionTypes.GET_USER_INVOICE_BY_ID,
      payload: {
        id
      },
    };
  }

  static getUserInvoiceByIdSuccess = (data) => {
    return {
      type: ActionTypes.GET_USER_INVOICE_BY_ID_SUCCESS,
      payload: {
        data
      },
    };
  }

  static getUserInvoiceByIdFail = () => {
    return {
      type: ActionTypes.GET_USER_INVOICE_BY_ID_FAIL,
    };
  }


  static getUserInvoicesByIds = (ids) => {
    return {
      type: ActionTypes.GET_USER_INVOICES_BY_IDS,
      payload: {
        ids
      },
    };
  }

  static getUserInvoicesByIdsSuccess = (data) => {
    return {
      type: ActionTypes.GET_USER_INVOICES_BY_IDS_SUCCESS,
      payload: {
        data
      },
    };
  }

  static getUserInvoicesByIdsFail = () => {
    return {
      type: ActionTypes.GET_USER_INVOICES_BY_IDS_FAIL,
    };
  }

  // UPDATE USER INVOICE
  static patchUserInvoice = (id, data) => {
    return {
      type: ActionTypes.PATCH_USER_INVOICE,
      payload: {
        id,
        data
      },
    };
  }

  static patchUserInvoiceSuccess = (id, data) => {
    toast.success(<FormattedMessage id="USER.REDUX.UPDATE_USER.SUCCESS" />);
    return {
      type: ActionTypes.PATCH_USER_INVOICE_SUCCESS,
      payload: {
        id,
        data
      },
    };
  }

  static patchUserInvoiceFail = (error) => {
    toast.error(error.data.error.message);
    return {
      type: ActionTypes.PATCH_USER_INVOICE_FAIL,
      payload: {
        error
      },
    };
  }

  //UPDATE USER INVOICE
  static updateUserInvoice = (document) => {
    return {
      type: ActionTypes.UPDATE_USER_INVOICE,
      payload: {
        document
      },
    };
  };

  static updateUserInvoiceSuccess = () => {
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.UPDATE_DOCUMENT.SUCCESS' />);
    return {
      type: ActionTypes.UPDATE_USER_INVOICE_SUCCESS,
    };
  };

  static updateUserInvoiceFail = (error) => {
    toast.error(<FormattedMessage id='DOCUMENT.REDUX.UPDATE_DOCUMENT.FAIL' />);
    return {
      type: ActionTypes.UPDATE_USER_INVOICE_FAIL,
      payload: {
        error
      },
    };
  };


  // Add New Position Row
  static addNewPositionRow = (settings) => {
    return {
      type: ActionTypes.ADD_NEW_POSITION_ROW,
      payload: {
        settings
      },
    };
  };


  // Update Position
  static updatePosition = (updatedRow, column) => {
    return {
      type: ActionTypes.UPDATE_POSITION,
      payload: {
        updatedRow,
        column,
      },
    };
  };

  // Add Multi Position
  static addMultiplePositions = (products) => {
    return {
      type: ActionTypes.ADD_MULTIPLE_POSITION_ROW,
      payload: {
        products
      },
    };
  };

  // Delete Position
  static deletePosition = (id) => {
    return {
      type: ActionTypes.DELETE_POSITION,
      payload: {
        id,
      },
    };
  };

  //Update Discount
  static updateDiscount = (discount) => {
    return {
      type: ActionTypes.UPDATE_DISCOUNT,
      payload: {
        discount
      },
    };
  };

  // PRINT DOCUMENT
  static printDocument = (data, queryParams) => {
    return {
      type: ActionTypes.PRINT_DOCUMENT,
      payload: {
        data,
        queryParams,
      },
    };
  };

  static printDocumentSuccess = (response) => {
    response?.error && toast.error(response.error);
    return {
      type: ActionTypes.PRINT_DOCUMENT_SUCCESS,
      payload: {
        response,
      },
    };
  };

  static printDocumentFail = (err) => {
    return {
      type: ActionTypes.PRINT_DOCUMENT_FAIL,
      payload: {
        err
      },
    };
  };


  // Create User Invoices
  static createUserInvoices = (data) => {
    return {
      type: ActionTypes.CREATE_USER_INVOICES,
      payload: {
        data
      },
    };
  };

  static createUserInvoicesSuccess = (data) => {

    const counts = data.reduce((acc, item) => {
      if (item.invoice) { acc.invoiceLength++; }
      if (item.fulfillment) { acc.fulfillmentLength++; }
      return acc;
    }, { invoice: 0, fulfillment: 0 });

    counts.fulfillment > 0 && toast.success(<FormattedMessage id={counts.fulfillment > 1 ? 'DOCUMENT.REDUX.CREATE_FULFILLMENT_METHODS.SUCCESS_PLURAL' : 'DOCUMENT.REDUX.CREATE_FULFILLMENT_METHODS.SUCCESS'} values={{ length: counts.fulfillment }} />);
    counts.invoice > 0 && toast.success(<FormattedMessage id={counts.invoice > 1 ? 'DOCUMENT.REDUX.CONVERT_DOCUMENTS.SUCCESS_PLURAL' : 'DOCUMENT.REDUX.CONVERT_DOCUMENTS.SUCCESS'} values={{ length: counts.invoice }} />);

    return {
      type: ActionTypes.CREATE_USER_INVOICES_SUCCESS,
      payload: {
        data
      },
    };
  };

  static createUserInvoicesFail = (error) => {
    toast.error(<FormattedMessage id='DOCUMENT.REDUX.CREATE_FULFILLMENT_METHODS.FAIL' />);
    return {
      type: ActionTypes.CREATE_USER_INVOICES_FAIL,
      payload: {
        error,
      },
    };
  };

  static deleteCompleteList = (id) => {
    return {
      type: ActionTypes.DELETE_COMPLETE_LIST,
      payload: {
        id
      },
    };
  };


  // Enter Payment
  static enterPayment = (paymentValues, paymentStatus, documentId) => {
    return {
      type: ActionTypes.ENTER_PAYMENT,
      payload: {
        paymentValues,
        paymentStatus,
        documentId
      },
    };
  };

  static enterPaymentSuccess = (paymentValues, paymentStatus, documentId, history) => {
    //FIXME:BE den mesaj geliyor mesaja yansitilabilir
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.ENTER_PAYMENT.SUCCESS' />);
    return {
      type: ActionTypes.ENTER_PAYMENT_SUCCESS,
      payload: {
        paymentValues,
        paymentStatus,
        documentId,
        history
      },
    };
  };

  static enterPaymentFail = (error) => {
    return {
      type: ActionTypes.ENTER_PAYMENT_FAIL,
      payload: {
        error
      },
    };
  };


  // Delete Enter Payment
  static deleteEnteredPayment = (paymentId, documentId) => {
    return {
      type: ActionTypes.DELETE_ENTERED_PAYMENT,
      payload: {
        paymentId,
        documentId
      },
    };
  };

  static deleteEnteredPaymentSuccess = (paymentId, documentId) => {
    return {
      type: ActionTypes.DELETE_ENTERED_PAYMENT_SUCCESS,
      payload: {
        paymentId,
        documentId
      },
    };
  };


  static deleteEnteredPaymentFail = (error) => {
    return {
      type: ActionTypes.DELETE_ENTERED_PAYMENT_FAIL,
      payload: {
        error
      },
    };
  };

  //Get Customer
  static getCustomers = (event, page, limit) => {
    return {
      type: ActionTypes.GET_CUSTOMERS,
      payload: {
        event,
        page,
        limit,
      },
    };
  };

  static getCustomersSuccess = (customers) => {
    return {
      type: ActionTypes.GET_CUSTOMERS_SUCCESS,
      payload: {
        customers,
      },
    };
  };

  static getCustomersFail = () => {
    return {
      type: ActionTypes.GET_CUSTOMERS_FAIL,
    };
  };

  // Users Action Creators
  static cleanUpUserModule = () => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE,
    };
  };

  static cleanUpUserModuleParams = (params) => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE_PARAMS,
      payload: {
        params
      },
    };
  };

}