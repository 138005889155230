export default class ActionTypes {
  static get PREFIX() {
    return '[adminUsersContact]';
  }



  // Get users contact
  static get GET_USERS_CONTACT() {
    return `${ActionTypes.PREFIX}GET_USERS_CONTACT`;
  }
  static get GET_USERS_CONTACT_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_USERS_CONTACT_SUCCESS`;
  }
  static get GET_USERS_CONTACT_FAIL() {
    return `${ActionTypes.PREFIX}GET_USERS_CONTACT_FAIL`;
  }

  // Clean up the user module
  static get CLEAN_UP_USER_MODULE() {
    return `${this.PREFIX}USERS_CLEAN_UP`;
  }
  static get CLEAN_UP_USER_MODULE_PARAMS() {
    return `${this.PREFIX}USERS_CLEAN_UP_PARAMS`;
  }

}