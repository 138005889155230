import React, { useEffect, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { AmazonListingsActions } from '../_redux/actions';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';

function AmazonSubscriptionsCheckDialog({ ids, show, onHide, marketId }) {
  const dispatch = useDispatch();

  const UIContext = useProductListingsUIContext();
  const UIProps = useMemo(
    () => ({
      queryParams: UIContext.queryParams
    }),
    [UIContext.queryParams]
  );

  useEffect(() => {
    !ids && onHide();
    !Array.isArray(ids) && onHide();
    Array.isArray(ids) && ids.length === 0 && onHide();

    return () => {
      dispatch(AmazonListingsActions.updateStateProperty({ getListingsQueryResults: [] }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

//   const subscriptions = useSelector((state) => state.amazonListings.subscriptions);
  const getListingsQueryResults = useSelector((state) => state.amazonListings.getListingsQueryResults);
  const products = useSelector((state) => state.listings.products);

  const onCheckSubscriptions = (ids) => {
    for (const id of ids) {
    //   if (subscriptions.find((item) => item.id === id)) {
    //     continue;
    //   }
      dispatch(AmazonListingsActions.amazonGetListingsRequest({ productId: id, marketId, queryParams: UIProps.queryParams }));
    }
  };

  return (
    <Portal node={document && document.getElementById('layout-portal')}>
      <Modal show={show} onHide={onHide} size="md" backdrop="static" aria-labelledby="example-modal-sizes-title-sm" centered>
        <Modal.Body className="text-center line-height-md h6 py-10 mb-0">
          {ids &&
            ids.length > 0 &&
            ids.map((id) => {
              const product = products.find((product) => product.id === id);
              return (
                <div key={`publish-${id}`} className="mt-3 d-flex flex-row" style={{ gap: '.325rem' }}>
                  <span>{product?.identities?.asin}</span>
                  <span>
                    {products.subscriptions && products.subscriptions.find((item) => item.product_id === id) ? (
                      <span className="badge badge-success badge-pill">Ready</span>
                    ) : (
                      <span className="badge badge-danger badge-pill">No Subscription Founded</span>
                    )}
                  </span>
                </div>
              );
            })}
          <div className="mt-3">
            {getListingsQueryResults &&
              getListingsQueryResults.length > 0 &&
              getListingsQueryResults.map((item) => (
                <div key={`publish-${item.id}`} className="mt-3 d-flex flex-row" style={{ gap: '.325rem' }}>
                  <span>{item.id}</span>
                  <span>{item.status}</span>
                  {item.error && <span>{item.error}</span>}
                  {item.data && JSON.stringify(item.data)}
                </div>
              ))}
          </div>
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">
          <Button variant="ios" className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="ios" className="text-success" onClick={() => onCheckSubscriptions(ids)} id="btn_delete_modal">
            <FormattedMessage id="GENERAL.PUBLISH" defaultMessage="Check" />
          </Button>
        </Modal.Footer>
      </Modal>
    </Portal>
  );
}

export { AmazonSubscriptionsCheckDialog };
