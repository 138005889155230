import React from 'react'
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl'
import { ConnectionsActions } from '../_redux/actions';



const SWITCH = {
  kaufland: [
    {
      name: 'invoice_upload',
      label: 'CONNECTIONS.SHIPPER.KAUFLAND_CHECKBOX',
    }
  ],
  amazon: [
    {
      name: 'prime',
      label: 'CONNECTIONS.SHOP.AMAZON_AUTO_PRIME',
    },
    {
      name: 'invoice_upload',
      label: 'CONNECTIONS.SHOP.AMAZON_INVOICE_UPLOAD',
    },
    {
      name: 'credit_upload',
      label: 'CONNECTIONS.SHOP.AMAZON_CREDIT_UPLOAD',
    }
  ]
}


export const ConnectionUploadSettingsCard = ({ connectionName, connection, loading }) => {

  const dispatch = useDispatch();

  const handleCheckbox = (e) => {
    connection[connectionName][e.target.name] = e.target.checked;
    dispatch(ConnectionsActions.updateSettings(connection))
  }


  return (
    <>
      {(connectionName === 'amazon' || connectionName === 'kaufland') && SWITCH[connectionName]?.map(item => (
        <>
          <div className="separator separator-dashed my-5 my-md-8" />

          <label className="switch switch-sm mb-5 switch-icon" key={item.name}>
            <input type="checkbox" name={item.name} checked={connection[connectionName][item.name]} onChange={(e) => handleCheckbox(e)} id={`checkbox_${item.name}`} disabled={loading} />
            <span />
            <div className="font-weight-bold ml-2">
              <FormattedMessage id={item.label} />
            </div>
          </label>
        </>
      ))}
    </>
  )
}
