import { API } from 'constants/apiUrl';
import { searchKeysForUserInvoice } from '../params';
import { paginationParams, searchParams } from 'middlewares/_helpers/query-params';



export const adminUserInvoicesMiddleware = {


  // ------------------------------------ GET USERS INVOICES ------------------------------------ //

  getUserInvoices: async function (accessToken, dateRange, { pagination, filter, order, search }, documentType) {

    const { date_from, date_to, ...rest } = filter;
    return await API.get(`/user-invoices`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order && documentType === 'invoices' ? 'invoice_date DESC' : 'created_at DESC'}`],
          where: {
            ...(search && {
              ...searchParams(searchKeysForUserInvoice, search),
            }),
            ...(filter && rest),
            ...((dateRange.date_from && dateRange.date_to) && {
              created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
            }),
            ...(documentType === 'invoices'
              ? {
                invoice_date: { $exists: true, $ne: null },
              }
              : {
                invoice_date: { $exists: false, $ne: null },
              }
            ),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getUserInvoicesCount: async function (accessToken, dateRange, { pagination, filter, order, search }, documentType) {
    const { date_from, date_to, ...rest } = filter;
    return await API.get(`/user-invoices/count`, {
      params: {
        where: {
          ...(filter && rest),
          ...(search && {
            ...searchParams(searchKeysForUserInvoice, search),
          }),
          ...((dateRange.date_from && dateRange.date_to) && {
            created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
          }),
          ...(documentType === 'invoices'
            ? {
              invoice_date: { $exists: true, $ne: null },
            }
            : {
              invoice_date: { $exists: false, $ne: null },
            }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getUserInvoice: async function (accessToken, id, withFieldParams) {
    return await API.get(`/user-invoice/${id}`, {
      params: {
        filter: {
          ...(withFieldParams === 'field_params' && {
            ...withFieldParams,
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchUserInvoice: async function (accessToken, id, data) {
    return await API.patch(`/user-invoice/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  updateUserInvoice: async function (accessToken, document) {
    return await API.put(`/user-invoice/${document.id}`, document, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  // ------------------------------------ GET USER INVOICE BY ID ------------------------------------ //
  getUserInvoicesByIds: async function (accessToken, ids) {
    return await API.get(`/user-invoices-by-ids`, {
      params: {
        filter: {
          where: {
            ...(ids && { id: { inq: ids } }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  createInvoices: async function (accessToken, convertData) {
    return await API.post(`/create-invoices`, convertData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


};
