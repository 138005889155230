/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { NoRecordsFoundMessage, convertPriceCurrency, getSelectRow } from '_metronic/_helpers';
import { TAX_OPTIONS, UNIT_OPTIONS } from 'app/modules/products/_context/ProductsUIHelpers';
import { getPositionLabel } from 'app/admin/user-invoices/_context/UserInvoicesUIHelpers';
import { useUserSettingsUIContext } from '../../_context/UserSettingsUIContext';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { FormattedMessage, useIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DeleteConfirm } from '_metronic/_partials/dialogs';
import { SettingsActions } from '../../_redux/actions';
import { useDispatch } from 'react-redux';
import { useWindowSize } from 'app/hooks';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function ProductSettingsTable({ id, settings_type, searchedProduct, product_settings, loading, setSearchQuery }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const { windowSize } = useWindowSize();

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    openProductDeleteDialog: UIContext.openProductDeleteDialog,
    showDeleteProductDialog: UIContext.showDeleteProductDialog,
    closeDeleteProductDialog: UIContext.closeDeleteProductDialog,
    openProductSettingDialog: UIContext.openProductSettingDialog,
    productId: UIContext.productId,
    setIds: UIContext.setIds,
    ids: UIContext.ids,
  }), [UIContext]);

  const tableRowHandler = (oldValue, newValue, row, column) => {

    // if (oldValue === newValue) { return; }

    // if (column.dataField === 'gross_price') {
    //   row.net_price = +row.gross_price / (1 + +row.tax_rate / 100);
    // }
    // if (column.dataField === 'net_price') {
    //   row.gross_price = +row.net_price * (1 + +row.tax_rate / 100);
    // }
    // if (column.dataField === 'tax_rate') {
    //   if (togglePriceOption) {
    //     row.net_price = +row.gross_price / (1 + +row.tax_rate / 100);
    //   } else {
    //     row.gross_price = +row.net_price * (1 + +row.tax_rate / 100);
    //   }
    // }
    // if (typeof row.gross_price === 'string') {
    //   row.gross_price = replace(row.gross_price, ',', '.');
    //   row.gross_price = +row.gross_price;
    // }

    // dispatch(AdminActions.updatePosition(row, column));
  };

  const deleteProductSettings = () => {
    let filteredShipping
    if (UIProps.ids.length > 0 && !UIProps.productId) {
      filteredShipping = product_settings?.products.filter(item => !UIProps.ids.includes(item.id));
    } else {
      filteredShipping = product_settings?.products.filter(item => item.id !== UIProps.productId);
    }

    if (id && settings_type === "products") {
      dispatch(SettingsActions.patchUserSettings(id, settings_type, { products: filteredShipping }))
    }

    UIProps.setIds([]);
    setSearchQuery('');
  }

  const unitOptions = UNIT_OPTIONS.map(item => ({
    value: item.value,
    label: intl({ id: item.label })
  }));

  const COLUMNS = [
    {
      dataField: 'title',
      text: intl({ id: 'GENERAL.NAME' }),
      headerStyle: { minWidth: '250px', width: "45%" },
      headerClasses: 'pl-4',
      classes: 'font-weight-bolder text-dark-75 font-size-lg',
      editable: (cell, row) => !row.default,
      formatter: (cell, row) => <div type="button" className={clsx("edit-table", !cell && "form-control form-control-sm is-invalid")}>{getPositionLabel(cell) ?? cell}</div>,
    },
    {
      dataField: 'sku',
      text: intl({ id: 'PRODUCT.SKU' }),
      headerStyle: { minWidth: '80px', width: "15%" },
      style: (cell, row) => ({ cursor: !row.default ? 'pointer' : 'default', }),
      headerClasses: 'text-nowrap ',
      classes: 'font-weight-bold',
      editable: (cell, row) => !row.default,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "center" }),
      formatter: (cell, row) => <div className={clsx(!row.default && "edit-table")}>{cell}</div>
    },
    {
      dataField: 'unit',
      text: intl({ id: 'DOCUMENT.POSITION_UNIT' }),
      headerStyle: { minWidth: '100px', width: "10%" },
      style: (cell, row) => ({ cursor: !row.default ? 'pointer' : 'default', }),
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editable: (cell, row) => !row.default,
      editor: { type: Type.SELECT, options: unitOptions },
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem 0.5rem 1.75rem" }),
      formatter: (cell, row) => (
        <div className={clsx(!row.default && "edit-table")}>
          {unitOptions.find(item => item.value === cell)?.label}
        </div>
      ),
    },
    {
      dataField: 'tax_rate',
      text: intl({ id: 'DOCUMENT.POSITION_TAX_FIELD' }),
      headerStyle: { minWidth: '100px', width: "10%" },
      style: (cell, row) => ({ cursor: !row.default ? 'pointer' : 'default', }),
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      type: 'number',
      editable: (cell, row) => !row.default,
      editor: {
        type: Type.SELECT, options: TAX_OPTIONS.map(item => ({
          value: item.value,
          label: intl({ id: item.label })
        }))
      },
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem 0.5rem 1.75rem" }),
      formatter: (cell, row) => <div className={clsx(!row.default && "edit-table")}>{cell + "%"}</div>,
    },
    {
      dataField: 'gross_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      headerStyle: { minWidth: '100px', width: "10%" },
      style: (cell, row) => ({ cursor: !row.default ? 'pointer' : 'default', }),
      headerClasses: 'text-right',
      classes: 'font-weight-bold text-right',
      editable: true,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "right" }),
      // validator: (cell, row, column) => isValidPrice(cell, row, intl),
      formatter: (cell, row) => <div className={clsx("edit-table")}>{convertPriceCurrency(+cell, 'EUR', 3)}</div>,
    },
    {
      dataField: 'action',
      text: "actions",
      headerStyle: { minWidth: '80px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: false,
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
              <div className="btn btn-icon btn-hover-primary btn-sm" onClick={() => UIProps.openProductSettingDialog(row?.id)} id={`btn_edit_${row.id}`}>
                <span className="svg-icon svg-icon-md">
                  <Icon.PencilSquare />
                </span>
              </div>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip id="shipping-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
              <a className={clsx("btn btn-icon btn-hover-danger btn-sm", row.default && "disabled")} onClick={() => !row.default && UIProps.openProductDeleteDialog(row?.id)} id={`btn_delete_${row.id}`}>
                <span className="svg-icon svg-icon-md">
                  <Icon.Trash />
                </span>
              </a>
            </OverlayTrigger>
          </>
        )
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={searchedProduct || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden mb-0"
        headerClasses="bg-info-o-10"
        bootstrap4
        striped
        bordered={false}
        deleteRow={true}
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => setSearchQuery('')} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: searchedProduct,
        })}
        cellEdit={cellEditFactory({
          mode: windowSize < 576 ? 'click' : 'dbclick',
          blurToSave: true,
          afterSaveCell: tableRowHandler,
        })}
      />

      <DeleteConfirm show={UIProps.showDeleteProductDialog} onHide={UIProps.closeDeleteProductDialog} toDelete={() => { deleteProductSettings(); UIProps.closeDeleteProductDialog() }} />
    </>
  )
}

