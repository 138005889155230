/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { useUserInvoicesUIContext } from '../_context/UserInvoicesUIContext';
import { AdminUserInvoicesActions } from '../_redux/actions';
import { SelectCounter } from "_metronic/_partials";
import { FormattedMessage } from 'react-intl';
import { theme } from '_metronic/_helpers';
import { Button } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';




export function UserInvoicesGrouping() {

  const dispatch = useDispatch();
  // const filterStatus = query.get('filter.status')

  const UIContext = useUserInvoicesUIContext();
  const UIDocContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    openConvertDocumentsDialog: UIContext.openConvertDocumentsDialog,
    openDeleteDocumentDialog: UIContext.openDeleteDocumentDialog,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    openExportDocumentDialog: UIContext.openExportDocumentDialog,
    handleOpenSendEmailsDialog: UIContext.handleOpenSendEmailsDialog,
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    requestedDocument: UIDocContext.requestedDocument,

  }), [UIContext, UIDocContext]);


  const sendSelectedDocumentsViaEmail = () => UIProps.handleOpenSendEmailsDialog();

  const openPrintDocumentDialog = (item) => {

    if (UIProps.ids.length > 0) {
      const data = {
        ids: UIProps.ids,
        user_invoice_action: UIProps.requestedDocument.type
      }

      dispatch(AdminUserInvoicesActions.printDocument(data));
      UIProps.openPrintDocumentDialog();
    }
  };

  const convertSelectedDocuments = () => {
    // paginations da diğer sayfalardan seçilenlerin doc gelmesi için > getDocumentByIds
    if (UIProps.ids?.length) {
      dispatch(AdminUserInvoicesActions.getUserInvoicesByIds(UIProps.ids))
    };
    UIProps.openConvertDocumentsDialog()
  };


  return (
    <div className="d-flex align-items-center justify-content-end flex-wrap mb-2">

      <SelectCounter count={UIProps.ids.length} />


      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-auto mb-2"
        onClick={() => UIProps.openExportDocumentDialog()}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        style={{ height: theme.units.input.height['sm'], cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
        id='btn_export'
      >
        <Icon.CloudDownload />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="IMPORT_EXPORT.EXPORT" />
        </span>

      </Button>


      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2"
        onClick={sendSelectedDocumentsViaEmail}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        style={{ height: theme.units.input.height['sm'], cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
        id='btn_email_send'
      >
        <Icon.EnvelopeAt />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.SEND_EMAIL" />
        </span>

      </Button>

      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2"
        onClick={() => openPrintDocumentDialog(false)}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        style={{ height: theme.units.input.height['sm'], cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
        id='btn_print'
      >
        <Icon.Printer />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.PRINT" />
        </span>
      </Button>
      {['orders'].includes(UIProps.requestedDocument.type) &&
        <Button variant="primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2"
          onClick={convertSelectedDocuments}
          disabled={!UIProps.ids || UIProps.ids.length <= 0}
          style={{ height: theme.units.input.height['sm'], cursor: !UIProps.ids || ((UIProps.ids.length <= 0) && 'not-allowed') }}
          id='btn_convert'
        >
          <Icon.CheckSquare />
          <span className="d-none d-sm-inline ml-2">
            <FormattedMessage id="DOCUMENT.CONVERT.CREATE_INVOICE" />
          </span>
        </Button>}

    </div>
  );
}
