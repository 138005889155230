import React from 'react';



export function SkuFormatter(cell, row, checkData) {
  const checkSku = checkData?.includes(cell);
  if (!cell || cell.length > 50 || !checkSku) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  };
  return (<div type="button" className="edit-table">{cell}</div>)
};

export function OldQuantityFormatter(cell, row, rowIndex) {
  if (!cell || +cell < 0 || +cell >= 1000000) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};

export function QuantityFormatter(cell, row, rowIndex) {
  if (cell && (+cell < 0 || +cell >= 1000000)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};

export function OldGrossPriceFormatter(cell, row, rowIndex) {
  if (cell && (+cell < 0 || +cell >= 1000000)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};

export function GrossPriceFormatter(cell, row, rowIndex) {
  if (cell && (+cell < 0 || +cell >= 1000000)) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};

export function TaxFormatter(cell, row, rowIndex) {
  if (+cell < 0 || +cell > 100) {
    return (
      <div type="button" className="edit-table form-control form-control-sm is-invalid">
        {cell}
      </div>
    )
  }
  return (<div type="button" className="edit-table">{cell}</div>);
};

export function HeaderFormatterRequired(column, colIndex) {

  const words = column.text.split(" ");
  const lastWordWithAsterisk = words[words.length - 1];
  const asteriskIndex = lastWordWithAsterisk.indexOf('*');

  return (
    <div>
      {words.slice(0, -1).join(' ')}
      {asteriskIndex > -1 ? (
        <span>
          {lastWordWithAsterisk.slice(0, asteriskIndex)}
          <span className='text-danger'>{lastWordWithAsterisk.charAt(asteriskIndex)}</span>
          {lastWordWithAsterisk.slice(asteriskIndex + 1)}
        </span>
      ) : (
        lastWordWithAsterisk
      )}
    </div>
  );
};