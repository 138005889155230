import { call, put, takeEvery, select } from 'redux-saga/effects';
import { documentMiddleware, tokenErrorHandler } from 'middlewares';
import { adminUserInvoicesMiddleware } from 'middlewares/admin/user-invoices/middleware';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { AdminUserInvoicesActions } from './actions';
import ActionTypes from './actionTypes';



// Get User Invoices
function* getUserInvoices({ payload: { queryParams, dateRange, documentType } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const userInvoices = yield call(adminUserInvoicesMiddleware.getUserInvoices, accessToken, dateRange, queryParams, documentType);
    const countUserInvoices = yield call(adminUserInvoicesMiddleware.getUserInvoicesCount, accessToken, dateRange, queryParams, documentType);

    yield put(AdminUserInvoicesActions.getUserInvoicesSuccess(userInvoices.data, countUserInvoices.data));


  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUserInvoicesActions.getUserInvoicesFail());
  }
}

// Get User Invoice By Id
function* getUserInvoiceById({ payload: { id } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const userInvoice = yield call(adminUserInvoicesMiddleware.getUserInvoice, accessToken, id);

    yield put(AdminUserInvoicesActions.getUserInvoiceByIdSuccess(userInvoice.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUserInvoicesActions.getUserInvoiceByIdFail());
  }
}

// Get user invoice by ids
function* getUserInvoicesByIds({ payload: { ids } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const userInvoices = yield call(adminUserInvoicesMiddleware.getUserInvoicesByIds, accessToken, ids);

    yield put(AdminUserInvoicesActions.getUserInvoicesByIdsSuccess(userInvoices.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUserInvoicesActions.getUserInvoicesByIdsFail());
  }
}

// patch User Invoice
function* patchUserInvoice({ payload: { id, data } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminUserInvoicesMiddleware.patchUserInvoice, accessToken, id, data);
    yield put(AdminUserInvoicesActions.patchUserInvoiceSuccess(id, data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUserInvoicesActions.patchUserInvoiceFail());
  }
}

// put User Invoice
function* updateUserInvoice({ payload: { document } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminUserInvoicesMiddleware.updateUserInvoice, accessToken, document);
    yield put(AdminUserInvoicesActions.updateUserInvoiceSuccess());
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUserInvoicesActions.updateUserInvoiceFail(err?.response?.data));
  }
}


// -------------------------------------- PRINT DOCUMENT -------------------------------------- //

function* printDocument({ payload: { data, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(documentMiddleware.printDocument, accessToken, data);

    if (response.data.status === 'error') {
      yield put(AdminUserInvoicesActions.printDocumentFail(response.data));
    } else {
      yield put(AdminUserInvoicesActions.printDocumentSuccess(response.data));
    }

    // if (data?.reminder) {
    //   yield put(AdminUserInvoicesActions.getDocuments(queryParams));
    // }

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(AdminUserInvoicesActions.printDocumentFail(err));
  }
}

// -------------------------------------- INVOICE CREATE -------------------------------------- //

function* createUserInvoices({ payload: { data } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const result = yield call(adminUserInvoicesMiddleware.createInvoices, accessToken, data);

    yield put(AdminUserInvoicesActions.createUserInvoicesSuccess(result.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUserInvoicesActions.createUserInvoicesFail(err));
  }
}

// -------------------------------------- ENTERED PAYMENT  -------------------------------------- //

function* enterPayment({ payload: { paymentValues, paymentStatus, documentId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const res = yield call(adminUserInvoicesMiddleware.getUserInvoice, accessToken, documentId, { fields: { entered_payment: true } });

    const { entered_payment } = res.data;

    const currentEnteredPayment = entered_payment && Array.isArray(entered_payment) ? entered_payment : [];
    currentEnteredPayment.push(paymentValues);

    const updateDocument = {
      entered_payment: currentEnteredPayment,
      payment_status: paymentStatus
    };

    const resp = yield call(adminUserInvoicesMiddleware.patchUserInvoice, accessToken, documentId, updateDocument);

    yield put(AdminUserInvoicesActions.enterPaymentSuccess(paymentValues, paymentStatus, documentId, resp?.data?.history));

  } catch (err) {
    const error = err?.response?.data ?? err?.message;
    yield put(AdminUserInvoicesActions.enterPaymentFail(error ?? 'Payment failed'));
  }
}

function* deleteEnteredPayment({ payload: { paymentId, documentId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const res = yield call(adminUserInvoicesMiddleware.getUserInvoice, accessToken, documentId, { fields: { entered_payment: true } });
    const { entered_payment } = res.data;

    if (!entered_payment) {
      throw new Error('Keine eingegebene Zahlung im Dokument gefunden');
    }

    const deletePayment = entered_payment.filter(pay => pay.id !== paymentId);
    const updateDocument = { entered_payment: deletePayment, payment_status: 'open' };

    yield call(adminUserInvoicesMiddleware.patchUserInvoice, accessToken, documentId, updateDocument);

    yield put(AdminUserInvoicesActions.deleteEnteredPaymentSuccess(paymentId, documentId));

  } catch (err) {
    yield put(AdminUserInvoicesActions.deleteEnteredPaymentFail(err?.response));
  }
}



export function* adminUserInvoicesSaga() {
  yield takeEvery(ActionTypes.GET_USER_INVOICES, getUserInvoices);
  yield takeEvery(ActionTypes.GET_USER_INVOICE_BY_ID, getUserInvoiceById);
  yield takeEvery(ActionTypes.GET_USER_INVOICES_BY_IDS, getUserInvoicesByIds);
  yield takeEvery(ActionTypes.PATCH_USER_INVOICE, patchUserInvoice);
  yield takeEvery(ActionTypes.UPDATE_USER_INVOICE, updateUserInvoice);
  yield takeEvery(ActionTypes.PRINT_DOCUMENT, printDocument);
  yield takeEvery(ActionTypes.CREATE_USER_INVOICES, createUserInvoices);
  yield takeEvery(ActionTypes.ENTER_PAYMENT, enterPayment);
  yield takeEvery(ActionTypes.DELETE_ENTERED_PAYMENT, deleteEnteredPayment);
}
