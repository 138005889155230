import { barcodeRegExp, regexCategoryPattern, regexFeaturePattern, regexImgPattern, regexVariantPattern, upcRegExp } from "_metronic/_helpers";



export const validator = {

  skuValidator: (cell, row, checkData, intl) => {
    const checkSku = checkData?.includes(cell);
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.SKU_REQUIRED" }),
      };
    } else if (cell.length > 50) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_SKU" }),
      };
    } else if (checkSku) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.SKU_WARNING" }),
      };
    }
  },

  skuUpdateValidator: (cell, row, checkData, intl) => {
    const checkSku = checkData.includes(cell);
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.SKU_REQUIRED" }),
      };
    } else if (cell.length > 50) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_SKU" }),
      };
    } else if (!checkSku) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.NO_SKU_WARNING" }),
      };
    }
  },

  nameValidator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.NAME_REQUIRED" }),
      };
    } else if (cell.length > 250) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.NAME.MAX_LENGTH" }),
      };
    }
  },


  isVariantValidator: (cell, intl) => {
    if (!cell || (+cell !== 0 && +cell !== 1)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_IS_VARIANT" }),
      };
    }
  },


  variantSkuValidator: (cell, row, intl) => {
    const isVariant = +row.isVariant;
    if (isVariant === 0 && cell) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_WITHOUT_VARIANT" }),
      };
    } else if (isVariant === 1 && !cell) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.SKU_REQUIRED" }),
      };
    } else if (isVariant === 1 && cell?.length > 50) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_SKU" }),
      };
    }
  },


  variantNameValidator: (cell, row, intl) => {
    const isVariant = +row.isVariant;
    if (isVariant === 0 && cell) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_WITHOUT_VARIANT" }),
      };
    } else if (isVariant === 1 && !cell) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_VARIANT.NAME_MISSING" }),
      };
    } else if (isVariant === 1 && !regexVariantPattern.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_VARIANT.NAME" }),
      };
    }
  },


  variantValueValidator: (cell, row, intl) => {
    const isVariant = +row.isVariant;
    if (isVariant === 0 && cell) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_VARIANT.VALUE_MISSING" }),
      };
    } else if (isVariant === 1 && !regexVariantPattern.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_VARIANT.VALUE" }),
      };
    }
  },


  brandValidator: (cell, intl) => {
    if (cell?.length > 200) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_BRAND" }),
      };
    }
  },


  quantityUpdateValidator: (cell, intl) => {
    if (+cell < 0 || +cell >= 1000000) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_QUANTITY" }),
      };
    }
  },


  quantityValidator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_QUANTITY_REQUIRED" }),
      };
    } else if (+cell < 0 || +cell >= 1000000) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_QUANTITY" }),
      };
    }
  },


  purchasePriceValidator: (cell, intl) => {
    if (cell && (+cell < 0 || +cell >= 1000000)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_COST_PRICE" }),
      };
    }
  },


  grossPriceValidator: (cell, intl) => {
    if (cell && (+cell < 0 || +cell >= 1000000)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_GROSS_PRICE" }),
      };
    }
  },

  currencyValidator: (cell, intl) => {
    if (cell && cell?.length !== 3) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_CURRENCY" }),
      };
    }
  },


  taxUpdateValidator: (cell, intl) => {
    if (+cell < 0 || +cell > 100) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_TAX" }),
      };
    }
  },


  taxValidator: (cell, intl) => {
    if (!cell) {
      return {
        valid: false,
        message: intl({ id: "PRODUCT.GENERAL.VAT_REQUIRED" }),
      };
    } else if (+cell < 0 || +cell > 100) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_TAX" }),
      };
    }
  },


  eanValidator: (cell, intl) => {
    if (cell && cell?.length > 13) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_EAN" }),
      };
    }
  },

  asinValidator: (cell, intl) => {
    if (cell && cell?.length !== 10) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_ASIN" }),
      };
    }
  },


  barcodeValidator: (cell, intl) => {
    if (cell && !barcodeRegExp.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_BARCODE" }),
      };
    }
  },


  isbnValidator: (cell, intl) => {
    if (cell && (cell?.length < 10 || cell?.length > 13)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_ISBN" }),
      };
    }
  },


  gtinValidator: (cell, intl) => {
    if (cell?.length > 50) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_GTIN" }),
      };
    }
  },


  mpnValidator: (cell, intl) => {
    if (cell?.length > 50) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_MPN" }),
      };
    }
  },


  upcValidator: (cell, intl) => {
    if (cell && !upcRegExp.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_UPC" }),
      };
    }
  },


  weightValidator(cell, intl) {
    if (cell && (+cell < 0 || +cell > 1000000)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_WEIGHT" }),
      };
    }
  },


  widthValidator(cell, intl) {
    if (cell && (+cell < 0 || +cell > 500)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_WIDTH" }),
      };
    }
  },


  heightValidator(cell, intl) {
    if (cell && (+cell < 0 || +cell > 300)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_HEIGHT" }),
      };
    }
  },


  lengthValidator(cell, intl) {
    if (cell && (+cell < 0 || +cell > 300)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_DEPTH" }),
      };
    }
  },



  descriptionValidator: (cell, intl) => {
    if (cell?.length > 1000000) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_DESCRIPTION" }),
      };
    }
  },


  categoryPathValidator(cell, intl) {
    if (cell && !regexCategoryPattern.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_CATEGORY_PATH" }),
      };
    }
  },


  unitValidator: (cell, intl) => {
    if (cell !== "weight" && cell !== "piece") {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_UNIT" }),
      };
    }
  },


  imgValidator: (cell, intl) => {
    if (cell && !regexImgPattern.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_PICTURE" }, { number: '' }),
      };
    }
  },


  featuresValidator: (cell, intl) => {
    if (cell && !regexFeaturePattern.test(cell)) {
      return {
        valid: false,
        message: intl({ id: "IMPORT.PRODUCT.ALERT_DIALOG.PRODUCT_FEATURES" }, { number: '' }),
      };
    }
  },
};
