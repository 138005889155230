import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardBody, Card, CardHeader } from '_metronic/_partials/controls';
import { useTempUserUIContext } from './_context/TempUserUIContext';
import { TempUserFilter } from './temp-user-filter/TempUserFilter';
import { TempUserTable } from './temp-user-table/TempUserTable';
import { BlankMessage } from '_metronic/_partials/components';
import { LoadingDialog } from "_metronic/_partials/loading";
import { AdminTempUsersActions } from './_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function TempUserCard() {

  const dispatch = useDispatch();

  const UIContext = useTempUserUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    setOrderQuery: UIContext.setOrderQuery,
    setPaginationQuery: UIContext.setPaginationQuery,
    setSearchQuery: UIContext.setSearchQuery,
    ids: UIContext.ids,
    setIds: UIContext.setIds,
  }), [UIContext]);

  const { count, loading, temp_users, totalCount } = useSelector(state => state.adminTempUsers);

  useEffect(() => {
    let delayDebounceFn;
    if (UIProps.queryParams?.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(AdminTempUsersActions.getTempUsers(UIProps.queryParams));
      }, 350);
    } else {
      dispatch(AdminTempUsersActions.getTempUsers(UIProps.queryParams));
    }
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [dispatch, UIProps.queryParams]);


  return (
    <Card className='card-height'>

      <CardHeader
        icon={<Icon.PersonExclamation />}
        title={<FormattedMessage id='ADMIN.TEMP_USERS.TITLE' />}
        className="display-5"
        id='title_temp_user'
      />

      <CardBody>
        {totalCount === 0 && UIProps.queryParams.search === ''
          ? <BlankMessage
            icon={<Icon.PersonExclamation />}
            title={<FormattedMessage id='ADMIN.TEMP_USER.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='ADMIN.TEMP_USER.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
          />
          : <>
            <TempUserFilter UIProps={UIProps} />
            <TempUserTable tempUsers={temp_users} loading={loading} tempCount={count} UIProps={UIProps} />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}
