import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectionsActions } from '../../_redux/actions';
import { Formik, Form, Field } from 'formik';
import { SVFormControl } from '_metronic/_partials/controls';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import * as Yup from "yup";




const INPUTS = [
  {
    name: 'check24.FTPHost',
    label: 'CONNECTIONS.SHOP.AMAZON_FTP_HOST',
  },
  {
    name: 'check24.FTPPort',
    label: 'CONNECTIONS.SHOP.AMAZON_FTP_PORT',
  },
  {
    name: 'check24.Benutzername',
    label: 'ACCOUNT.BENUTZERNAME',
  },
  {
    name: 'check24.Passwort',
    label: "GENERAL.PASSWORD",
    type: 'password'
  },
];


export default function Check24Form({ children, tokenId, onHide, success, loading }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl()

  const { check24ForEdit, connections } = useSelector(state => ({
    check24ForEdit: state.connections.check24ForEdit,
    connections: tokenId && state.connections.entities.find(connection => connection.name === "check24")
  }));

  const INFO = [
    {
      label: "CONNECTIONS.TOKEN_TYPE",
      content: <>
        <span className={connections?.token_type === 'marketplace' ? 'text-capitalize' : ''}>
          {connections?.token_type}
        </span>
      </>,
    },
    {
      label: "CONNECTIONS.CHECK",
      content: <>
        <span className={`text-${connections?.check === 'success' ? 'success' : 'danger'}`}> {connections?.check === 'success' ? connections?.check : connections?.error}</span>
      </>,
    },
  ];

  const validationSchema = Yup.object().shape({
    check24: Yup.object().shape({
      FTPHost: Yup.string()
        .required(intl({ id: 'GENERAL.REQUIRED' })),
      FTPPort: Yup.string()
        .required(intl({ id: 'GENERAL.REQUIRED' })),
      Benutzername: Yup.string()
        .required(intl({ id: 'GENERAL.REQUIRED' })),
      Passwort: Yup.string()
        .required(intl({ id: 'GENERAL.REQUIRED' })),
    }),
  });


  const handleSubmit = (values) => {
    tokenId
      ? dispatch(ConnectionsActions.updateSettings(values))
      : dispatch(ConnectionsActions.createSettings(values));
  };


  return (
    <Formik enableReinitialize={true} initialValues={tokenId ? connections : check24ForEdit} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form autoComplete='off'>

        <Modal.Body>
          <Row>
            {!tokenId
              ? INPUTS.map((item, i) => (
                <Col lg="6" key={i}>
                  <Field component={SVFormControl}
                    {...{
                      name: item.name,
                      type: item.type,
                      label: intl({ id: item.label }),
                      withFeedbackLabel: true,
                      feedbackLabel: true,
                      required: true,
                      disabled: loading || success,
                    }}
                  />
                </Col>
              ))
              : <Col>
                <label className='font-weight-bold text-capitalize text-dark-75 mb-3'>
                  <FormattedMessage id='CONNECTIONS.SHOP_SETTINGS' values={{ marketName: connections?.name }} />
                </label>

                <div className="separator separator-dashed mb-5" />

                <div className="py-2 px-4 d-flex flex-column">
                  {INFO.length && (
                    <div className="py-2 px-4 d-flex flex-column">
                      {INFO?.map((item, i) => (
                        <h6 className={`d-flex align-items-start flex-column flex-md-row `} key={i}>
                          <span className="text-dark-50 mb-3" style={{ minWidth: '120px' }}>
                            <FormattedMessage id={item.label} />
                          </span>
                          <span>
                            {item.content}
                          </span>
                        </h6>
                      ))}
                    </div>
                  )}
                </div>
              </Col>
            }
          </Row>
        </Modal.Body>

        <Modal.Footer>

          {children}

          <Button variant="light" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          {!tokenId &&
            <Button variant="primary" type="submit" className="ml-2" disabled={loading || success} id="btn_save_modal">
              <FormattedMessage id="GENERAL.SAVE" />
            </Button>}

        </Modal.Footer>

      </Form>
    </Formik>
  )
}
