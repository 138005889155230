import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';



export class AdminTempUsersActions {


  // Get all temp users
  static getTempUsers = (queryParams) => {
    return {
      type: ActionTypes.GET_TEMP_USERS,
      payload: {
        queryParams
      },
    };
  };

  static getTempUsersSuccess = (tempUsers, count) => {
    return {
      type: ActionTypes.GET_TEMP_USERS_SUCCESS,
      payload: {
        tempUsers,
        count
      },
    };
  };

  static getTempUsersFail = (error) => {
    return {
      type: ActionTypes.GET_TEMP_USERS_FAIL,
      payload: {
        error
      },
    };
  };



  // Update Temp Users
  static updateTempUser = (tempUser) => {
    return {
      type: ActionTypes.UPDATE_TEMP_USER,
      payload: {
        tempUser
      },
    };
  };

  static updateTempUserSuccess = () => {
    toast.success(<FormattedMessage id="USER.REDUX.UPDATE_USER.SUCCESS" />);
    return {
      type: ActionTypes.UPDATE_TEMP_USER_SUCCESS,
    };
  };

  static updateTempUserFail = (error) => {
    toast.error(error.data.error.message);
    return {
      type: ActionTypes.UPDATE_TEMP_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Delete TempUser
  static deleteTempUser = (ids, queryParams) => {
    return {
      type: ActionTypes.DELETE_TEMP_USERS,
      payload: {
        ids,
        queryParams
      },
    };
  };

  static deleteTempUserSuccess = (ids) => {
    toast.success(<FormattedMessage id='ADMIN.REDUX.DELETE_TEMP_USER.SUCCESS' />);
    return {
      type: ActionTypes.DELETE_TEMP_USERS_SUCCESS,
      payload: {
        ids,
      },
    };
  };

  static deleteTempUserFail = error => {
    return {
      type: ActionTypes.DELETE_TEMP_USERS_FAIL,
      payload: {
        error: error,
      },
    };
  };

  // Users Action Creators
  static cleanUpUserModule = () => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE,
    };
  };

  static cleanUpUserModuleParams = (params) => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE_PARAMS,
      payload: {
        params
      },
    };
  };

}