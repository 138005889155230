/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ActionsColumnFormatter(cellContent, row, rowIndex, { getAddProductsUrl, market_id, undoDraftListings }) {

  const inventory = row?.inventories?.find((inv) => inv.market_id === market_id);

  return (
    <div className="d-flex justify-content-end">

      <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip"><FormattedMessage id="GENERAL.PREPARE" /></Tooltip>}>
        <Link
          className="btn btn-icon btn-hover-primary btn-sm"
          to={getAddProductsUrl({
            tokenId: market_id,
            asin: row.identities.asin,
            productId: row.id
          })}
          style={row.identities.asin ? null : { pointerEvents: 'none', opacity: 0.5 }}
          id={`btn_edit_${row.id}`}
        >
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </Link>
      </OverlayTrigger>

      {/* Undo Draft listings Action */}
      {inventory?.status === 'DRAFT' && (
        <OverlayTrigger overlay={<Tooltip id="undo-draft-listing-undo-tooltip"><FormattedMessage id="GENERAL.UNDO" defaultMessage="Undo Draft" /></Tooltip>}>
          <a className="btn btn-icon btn-hover-info btn-sm" onClick={() => undoDraftListings(row.id, market_id)} id={`btn_undo_${row?.id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.TrashX />
            </span>
          </a>
        </OverlayTrigger>
      )}

      {/* Undo published listings */}
      {(inventory?.status === 'PUBLISHED' || inventory?.status === 'PENDING') && (
        <OverlayTrigger overlay={<Tooltip id="amazon-listing-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
          <Link to={ModuleRoutes.AMAZON_LISTINGS_DEACTIVATE_FN(market_id, row.id)} className="btn btn-icon btn-hover-danger btn-sm" id={`btn_delete_${row?.id}`}>
            <span className="svg-icon svg-icon-md">
              <Icon.Trash />
            </span>
          </Link>
        </OverlayTrigger>
      )}
    </div>
  );
}


export function ActionsColumnFormatterPublish(cellContent, row, rowIndex, { market_id, openSyncAlertDialog }) {
  //FIXME: diğer marketlerdeki gibi fn yazılacak checkKauflandInventorySync(row);
  const errors = '';
  const amazoInventory = row?.inventories?.find((inv) => inv.market_id === market_id);

  if (errors.length > 0) {
    return (
      <OverlayTrigger overlay={<Tooltip id="ebay-listing-sync-tooltip"><FormattedMessage id="GENERAL.WARNING" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-warning btn-sm" onClick={() => openSyncAlertDialog(row.id, errors)} id={`btn_sync_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.ExclamationTriangle />
          </span>
        </a>
      </OverlayTrigger>
    );
  } else {
    return (
      <div className="d-flex justify-content-center" style={{ gap: '.325rem' }}>
        <OverlayTrigger overlay={<Tooltip id="ebay-listing-sync-tooltip"><FormattedMessage id="GENERAL.READY" /> </Tooltip>}>
          <span className="svg-icon svg-icon-md">
            <Icon.CheckSquare className={'text-success'} />
          </span>
        </OverlayTrigger>

        {/* If the Listings has restrictions */}
        {Array.isArray(amazoInventory?.restrictions) && amazoInventory.restrictions.length > 0 && (
          <OverlayTrigger overlay={<Tooltip id="ebay-listing-restrictions-tooltip"><FormattedMessage id="AMAZON_LISTINGS.RESTRICTIONS" /></Tooltip>}>
            <Link to={ModuleRoutes.AMAZON_LISTINGS_RESTRICTIONS_FN(market_id, row.id)} className="svg-icon svg-icon-md" id={`btn_restrictions_${row?.id}`}>
              <span className="svg-icon svg-icon-md">
                <Icon.ExclamationTriangle className={'text-danger'} />
              </span>
            </Link>
          </OverlayTrigger>
        )}
      </div>
    );
  }
}
