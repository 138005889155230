import { call, put, takeEvery, select } from 'redux-saga/effects';
import { companyMiddleware, uploadMiddleware, userMiddleware } from 'middlewares';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { SettingsActions } from '../../_redux/actions';
import { CompanyActions } from './action';
import ActionTypes from './actionTypes';



// Create company
function* createCompanySaga({ payload: { createCompany } }) {
  try {
    const { accessToken, /*userId*/ } = yield select((state) => ({
      accessToken: state.auth.access_token,
      userId: state.auth.user.id,
    }));

    // yield call(userMiddleware.patchUserData, accessToken, userId, { ...userUpdate });
    const updateUser = yield call(companyMiddleware.create, accessToken, createCompany);
    yield put(CompanyActions.createCompanySuccess(updateUser.data));
    // yield put(AuthActions.getUserSuccess(userUpdate));

  } catch (err) {
    yield put(CompanyActions.createCompanyFail(typeof err === 'string' ? err : JSON.stringify(err?.response)));
  }
}


// Complete user register
function* completeUserRegisterSaga({ payload: { userUpdate } }) {
  try {

    const accessToken = yield select((state) => state.auth.access_token);

    yield call(userMiddleware.patchUserData, accessToken, { ...userUpdate });
    yield put(AuthActions.getUserSuccess(userUpdate));

  } catch (err) {
    yield put(CompanyActions.completeUserRegisterFail(typeof err === 'string' ? err : JSON.stringify(err?.response)));
  }
}


// Update Company Data
function* updateCompanyBaseData({ payload: { company, upload } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const updateFirma = yield call(companyMiddleware.update, accessToken, company);

    if (updateFirma && upload.is) {
      yield call(uploadMiddleware.uploadImages, accessToken, company?.id, upload.file);
    }

    yield put(CompanyActions.updateCompanyBaseDataSuccess());
  } catch (err) {
    const error = err?.response ? err.response.data : err?.message || "Error while updating company's base data";
    yield put(CompanyActions.updateCompanyBaseDataFail(error));
  }
}


// Get Company Data
function* getCompanyBaseData({ payload: { type } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(companyMiddleware.get, accessToken);
    yield put(CompanyActions.getCompanyBaseDataSuccess(response.data[0]));


    if (type !== 'first_login') {
      const { data } = yield call(userMiddleware.getCounts, accessToken);

      yield put(SettingsActions.getDocumentCountSuccess(data));

      //Settings e tiklaninca user settingsi getirmek icin buraya kondu
      yield put(SettingsActions.getUserSettings());
    }

  } catch (err) {
    yield put(CompanyActions.getCompanyBaseDataFail(err?.response));
  }
}


// Image Delete
function* deleteFile({ payload: { companyId, fileType, fileId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(uploadMiddleware.deleteFile, accessToken, companyId, fileType, fileId);
    yield put(CompanyActions.deleteFileSuccess(fileId));
  } catch (err) {
    yield put(CompanyActions.deleteFileFail());
  }
}



export function* companySaga() {
  yield takeEvery(ActionTypes.CREATE_COMPANY, createCompanySaga);
  yield takeEvery(ActionTypes.COMPLETE_USER_REGISTER, completeUserRegisterSaga);
  yield takeEvery(ActionTypes.UPDATE_COMPANY_BASE_DATA, updateCompanyBaseData);
  yield takeEvery(ActionTypes.GET_COMPANY_BASE_DATA, getCompanyBaseData);
  yield takeEvery(ActionTypes.DELETE_FILE, deleteFile);
}
