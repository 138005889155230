export const checkImportProduct = (values, checkData) => {

  const errors = {
    skuMissing: 0,
    skuInvalid: 0,
    quantityInvalid: 0,
    grossPriceInvalid: 0,
    taxInvalid: 0,
  };

  const createError = (type) => {
    errors[type]++;
  };

  for (let i = 0; i < values.length; i++) {
    const { sku, quantity, gross_price, tax } = values[i];
    const checkSku = checkData?.skuList?.includes(sku);

    if (!sku) {
      createError('skuMissing');
    }
    if (sku?.length > 50) {
      createError('skuInvalid');
    }
    if (!checkSku) {
      createError('skuInvalid');
    }

    // check pricing
    if (quantity >= 0 && (+quantity < 0 || +quantity >= 1000000)) {
      createError('quantityInvalid');
    }

    if (gross_price && (+gross_price < 0 || +gross_price >= 1000000)) {
      createError('grossPriceInvalid');
    }

    if (tax && (+tax < 0 || +tax > 100)) {
      createError('taxInvalid');
    }
  }

  return errors;
};