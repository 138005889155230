import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { UserInvoicesGrouping } from './user-invoices-filter/UserInvoicesGrouping';
import { UserInvoicesFilter } from './user-invoices-filter/UserInvoicesFilter';
import { useUserInvoicesUIContext } from './_context/UserInvoicesUIContext';
import { UserInvoicesTable } from './user-invoices-table/UserInvoicesTable';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { UserGuideHelper } from '../../user-guides/UserGuideHelper';
import { BlankMessage } from '_metronic/_partials/components';
import { LoadingDialog } from "_metronic/_partials/loading";
import { AdminUserInvoicesActions } from './_redux/actions';
import { AdminRoutes } from 'constants/moduleRoutes';
import { NewButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function UserInvoicesCard() {

  const dispatch = useDispatch();

  const { userInvoices, loading, totalCount ,count} = useSelector(state => state.adminUserInvoices);

  const UIContext = useUserInvoicesUIContext();
  const UIDocContext = useDocumentsUIContext();

  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    setPaginationQuery: UIContext.setPaginationQuery,
    setOrderQuery: UIContext.setOrderQuery,
    setSearchQuery: UIContext.setSearchQuery,
    setFilterQuery: UIContext.setFilterQuery,
    dateRange: UIContext.dateRange,
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    closePrintDocumentDialog: UIContext.closePrintDocumentDialog,
    openDocumentDetailsDialog: UIContext.openDocumentDetailsDialog,
    handleOpenEnterPaymentEditDialog: UIContext.handleOpenEnterPaymentEditDialog,
    requestedDocument: UIDocContext.requestedDocument,
    openEnterPaymentDialog: UIContext.openEnterPaymentDialog,
  }), [UIContext, UIDocContext]);



  useEffect(() => {
    let delayDebounceFn;
    if (UIProps.queryParams?.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(AdminUserInvoicesActions.getUserInvoices(UIProps.queryParams, UIProps.dateRange, UIProps.requestedDocument.type));
      }, 350);
    } else {
      dispatch(AdminUserInvoicesActions.getUserInvoices(UIProps.queryParams, UIProps.dateRange, UIProps.requestedDocument.type));
    }
    return () => { clearTimeout(delayDebounceFn); };
  }, [dispatch, UIProps.queryParams, UIProps.queryParams.search, UIProps.dateRange, UIProps.requestedDocument.type]);

  // cleaning reducer on unmount
  useEffect(() => {
    return () => {
      dispatch(AdminUserInvoicesActions.cleanUpUserModule());
    };
  }, [dispatch]);


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Document />}
        title={UIProps.requestedDocument?.plural}
        info={<UserGuideHelper />}
        className="display-5"
        id="title_document"
      >

        <CardHeaderToolbar>
          <NewButton
            label={UIProps.requestedDocument.type === 'offers' ? 'DOCUMENT.NEUES' : 'DOCUMENT.NEW'}
            values={UIProps.requestedDocument.title}
            link={AdminRoutes.DOCUMENT_NEW_FN(UIProps.requestedDocument.type)}
          />
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        {totalCount === 0
          ? <BlankMessage
            icon={<Icon.Document />}
            title={<FormattedMessage id='ADMIN.USER_INVOICES.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='ADMIN.USER_INVOICES..BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
          />
          :
          <>
            <UserInvoicesGrouping />
            <UserInvoicesFilter
              UIProps={UIProps}
              UIContext={UIContext}
            />
            <UserInvoicesTable
              UIProps={UIProps}
              userInvoices={userInvoices}
              countDocuments={count}
              loading={loading}
            />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}