export const searchProperties = [
  'name',
  'sku',
  'variants.sku',
  'variants.ean',
  'variants.asin',
  'identities.ean',
  'identities.asin',
  'pricing.purchase_price',
  'identities.barcode',
  'identities.gtin',
  'identities.isbn',
];

export const allFilterKeys = [
  'published',
  'unpublished',
  'low_stock',
  'search',
];

export const defaultSorted = [{ dataField: 'created_at', order: 'desc' }];


export const initialQueryParams = { page: 1, limit: 10 };
export const initialOrderParams = 'id DESC';

export const initialSearchParams = {
  searchText: '',
  searchKeys: searchProperties,
};

export const initialProductQueryParams = {
  pagination: initialQueryParams,
  search: initialSearchParams,
  filter: null,
  order: initialOrderParams,
};

export const initShippingValue = {
  method: '',
  shippingPrice: 0,
  shippingAdditionalPrice: 0,
  interShippingCheckbox: [],
  processingTime: '',
  type: '',
};

export const initialProductValues = {
  id: '',
  name: '',
  sku: '',
  condition: 'NEW',
  brand: '',
  quantity: 0,
  unit: '',
  supplier_id: '',
  pricing: {
    purchase_price: 0,
    /* net_price: 0, */
    gross_price: 0,
    tax: 19,
    currency: '',
  },
  category: {
    id: '',
    name: '',
    path: '',
    key: ""
  },
  images: [],
  description: '',
  //short_description: '',
  identities: {
    asin: '',
    gtin: '',
    isbn: '',
    ean: '',
    upc: '',
    barcode: '',
    mpn: '',
  },
  dimensions: {
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
  },
  features: [],
  variants: [],
  inventories: [],
  digital_product: {},
};

export const initialRequestValues = {
  loading: false,
  error: '',
  status: '', // success || error || pending
  id: null, // request id
};

export const initialProductKey = {
  key: '',
  order_id: '',
  order_date: null,
  email_sended_date: null,
  confirmation_date: null,
  created_at: null,
  updated_at: new Date(),
};


export const TAX_OPTIONS = [
  {
    value: 0,
    label: "PRODUCT.GENERAL.TAX_SELECT",
  },
  {
    value: 2,
    label: '2% - ES',
  },
  {
    value: 2.1,
    label: '2.1% - FR',
  },
  {
    value: 2.6,
    label: '2.6% - CH',
  },
  {
    value: 3,
    label: '3% - CY/LU',
  },
  {
    value: 3.8,
    label: '3.8% - CH',
  },
  {
    value: 4,
    label: '4% - EL/ES/IT/PT',
  },
  {
    value: 4.8,
    label: '4.8% - IE',
  },
  {
    value: 5,
    label: '5% - CY/EE/HU/HR/IT/LV/LT/MT/PL/RO/SK/SI/GB',
  },
  {
    value: 5.5,
    label: '5.5% - FR',
  },
  {
    value: 6,
    label: '6% - BE/EL/PT/SE',
  },
  {
    value: 7,
    label: '7% - DE/MT',
  },
  {
    value: 8,
    label: '8% - LU/PL',
  },
  {
    value: 8.1,
    label: '8.1% - CH',
  },
  {
    value: 9,
    label: '9% - BG/CY/EE/EL/IE/LT/NL/PT/RO',
  },
  {
    value: 9.5,
    label: '9.5% - SI',
  },
  {
    value: 10,
    label: '10% - AT/FI/FR/IT/SK/ES',
  },
  {
    value: 12,
    label: '12% - BE/CZ/LV/MT/NO/PT- Madeira/SE',
  },
  {
    value: 13,
    label: '13% - AT/EL/HR/PT',
  },
  {
    value: 13.5,
    label: '13.5% - IE',
  },
  {
    value: 14,
    label: '14% - FI/LU',
  },
  {
    value: 15,
    label: '15% - NO',
  },
  {
    value: 16,
    label: '16% - PT- Azores',
  },
  {
    value: 17,
    label: '17% - EL/LU',
  },
  {
    value: 18,
    label: '18% - HU/MT',
  },
  {
    value: 19,
    label: '19% - DE/CY/RO',
  },
  {
    value: 20,
    label: '20% - AT/BG/FR/SK/GB',
  },
  {
    value: 21,
    label: '21% - BE/CZ/LV/LT/NL/ES',
  },
  {
    value: 22,
    label: '22% - EE/IT/PT- Madeira/SI',
  },
  {
    value: 23,
    label: '23% - IE/PL/PT',
  },
  {
    value: 24,
    label: '24% - EL',
  },
  {
    value: 25,
    label: '25% - HR/DK/NO/SE',
  },
  {
    value: 25.5,
    label: '25.5% - FI',
  },
  {
    value: 27,
    label: '27% - HU',
  },
];

export const EU_TAX_OPTIONS = [
  // { label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },

// Almanya
{ country: 'DE', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'DE', label: '7% - DE', type: 'reduced', value: 7 },
{ country: 'DE', label: '19% - DE', type: 'standard', value: 19 },

// Fransa
{ country: 'FR', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'FR', label: '2.1% - FR', type: 'reduced', value: 2.1 },
{ country: 'FR', label: '5.5% - FR', type: 'reduced', value: 5.5 },
{ country: 'FR', label: '10% - FR', type: 'reduced', value: 10 },
{ country: 'FR', label: '20% - FR', type: 'standard', value: 20 },

// İtalya
{ country: 'IT', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'IT', label: '4% - IT', type: 'reduced', value: 4 },
{ country: 'IT', label: '5% - IT', type: 'reduced', value: 5 },
{ country: 'IT', label: '10% - IT', type: 'reduced', value: 10 },
{ country: 'IT', label: '22% - IT', type: 'standard', value: 22 },

// İspanya
{ country: 'ES', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'ES', label: '4% - ES', type: 'reduced', value: 4 },
{ country: 'ES', label: '10% - ES', type: 'reduced', value: 10 },
{ country: 'ES', label: '21% - ES', type: 'standard', value: 21 },

// Hollanda
{ country: 'NL', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'NL', label: '9% - NL', type: 'reduced', value: 9 },
{ country: 'NL', label: '21% - NL', type: 'standard', value: 21 },

// Belçika
{ country: 'BE', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'BE', label: '6% - BE', type: 'reduced', value: 6 },
{ country: 'BE', label: '12% - BE', type: 'reduced', value: 12 },
{ country: 'BE', label: '21% - BE', type: 'standard', value: 21 },

// Avusturya
{ country: 'AT', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'AT', label: '10% - AT', type: 'reduced', value: 10 },
{ country: 'AT', label: '13% - AT', type: 'reduced', value: 13 },
{ country: 'AT', label: '20% - AT', type: 'standard', value: 20 },

// İsveç
{ country: 'SE', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'SE', label: '6% - SE', type: 'reduced', value: 6 },
{ country: 'SE', label: '12% - SE', type: 'reduced', value: 12 },
{ country: 'SE', label: '25% - SE', type: 'standard', value: 25 },

// Finlandiya
{ country: 'FI', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'FI', label: '10% - FI', type: 'reduced', value: 10 },
{ country: 'FI', label: '14% - FI', type: 'reduced', value: 14 },
{ country: 'FI', label: '24% - FI', type: 'standard', value: 24 },

// Danimarka
{ country: 'DK', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'DK', label: '25% - DK', type: 'standard', value: 25 },

// Yunanistan
{ country: 'GR', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'GR', label: '6% - GR', type: 'reduced', value: 6 },
{ country: 'GR', label: '13% - GR', type: 'reduced', value: 13 },
{ country: 'GR', label: '24% - GR', type: 'standard', value: 24 },

// Portekiz
{ country: 'PT', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'PT', label: '6% - PT', type: 'reduced', value: 6 },
{ country: 'PT', label: '13% - PT', type: 'reduced', value: 13 },
{ country: 'PT', label: '23% - PT', type: 'standard', value: 23 },

// Polonya
{ country: 'PL', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'PL', label: '5% - PL', type: 'reduced', value: 5 },
{ country: 'PL', label: '8% - PL', type: 'reduced', value: 8 },
{ country: 'PL', label: '23% - PL', type: 'standard', value: 23 },

// İrlanda
{ country: 'IE', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'IE', label: '4.8% - IE', type: 'reduced', value: 4.8 },
{ country: 'IE', label: '9% - IE', type: 'reduced', value: 9 },
{ country: 'IE', label: '13.5% - IE', type: 'reduced', value: 13.5 },
{ country: 'IE', label: '23% - IE', type: 'standard', value: 23 },

// Çek Cumhuriyeti
{ country: 'CZ', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'CZ', label: '10% - CZ', type: 'reduced', value: 10 },
{ country: 'CZ', label: '15% - CZ', type: 'reduced', value: 15 },
{ country: 'CZ', label: '21% - CZ', type: 'standard', value: 21 },

// Macaristan
{ country: 'HU', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'HU', label: '5% - HU', type: 'reduced', value: 5 },
{ country: 'HU', label: '18% - HU', type: 'reduced', value: 18 },
{ country: 'HU', label: '27% - HU', type: 'standard', value: 27 },

// Lüksemburg
{ country: 'LU', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'LU', label: '3% - LU', type: 'reduced', value: 3 },
{ country: 'LU', label: '8% - LU', type: 'reduced', value: 8 },
{ country: 'LU', label: '17% - LU', type: 'standard', value: 17 },

// Hırvatistan
{ country: 'HR', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'HR', label: '5% - HR', type: 'reduced', value: 5 },
{ country: 'HR', label: '13% - HR', type: 'reduced', value: 13 },
{ country: 'HR', label: '25% - HR', type: 'standard', value: 25 },

// Bulgaristan
{ country: 'BG', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'BG', label: '9% - BG', type: 'reduced', value: 9 },
{ country: 'BG', label: '20% - BG', type: 'standard', value: 20 },

// Romanya
{ country: 'RO', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'RO', label: '5% - RO', type: 'reduced', value: 5 },
{ country: 'RO', label: '9% - RO', type: 'reduced', value: 9 },
{ country: 'RO', label: '19% - RO', type: 'standard', value: 19 },

// Estonya
{ country: 'EE', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'EE', label: '9% - EE', type: 'reduced', value: 9 },
{ country: 'EE', label: '20% - EE', type: 'standard', value: 20 },

// Letonya
{ country: 'LV', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'LV', label: '5% - LV', type: 'reduced', value: 5 },
{ country: 'LV', label: '12% - LV', type: 'reduced', value: 12 },
{ country: 'LV', label: '21% - LV', type: 'standard', value: 21 },

// Litvanya
{ country: 'LT', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'LT', label: '5% - LT', type: 'reduced', value: 5 },
{ country: 'LT', label: '9% - LT', type: 'reduced', value: 9 },
{ country: 'LT', label: '21% - LT', type: 'standard', value: 21 },

// Slovakya
{ country: 'SK', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'SK', label: '10% - SK', type: 'reduced', value: 10 },
{ country: 'SK', label: '20% - SK', type: 'standard', value: 20 },

// Slovenya
{ country: 'SI', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'SI', label: '9.5% - SI', type: 'reduced', value: 9.5 },
{ country: 'SI', label: '22% - SI', type: 'standard', value: 22 },

// Malta
{ country: 'MT', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'MT', label: '5% - MT', type: 'reduced', value: 5 },
{ country: 'MT', label: '7% - MT', type: 'reduced', value: 7 },
{ country: 'MT', label: '18% - MT', type: 'standard', value: 18 },

// Kıbrıs
{ country: 'CY', label: 'PRODUCT.GENERAL.TAX_SELECT', value: 0 },
{ country: 'CY', label: '5% - CY', type: 'reduced', value: 5 },
{ country: 'CY', label: '9% - CY', type: 'reduced', value: 9 },
{ country: 'CY', label: '19% - CY', type: 'standard', value: 19 },
];



export const UNIT_OPTIONS = [
  {
    value: 'piece',
    label: 'PRODUCT.GENERAL.CONDITIONS.PIECE',
  },
  {
    value: 'weight',
    label: 'PRODUCT.GENERAL.CONDITIONS.WEIGHT',
  },
  {
    value: 'hour',
    label: 'PRODUCT.GENERAL.CONDITIONS.HOUR',
  },
  {
    value: 'length',
    label: 'PRODUCT.GENERAL.CONDITIONS.LENGTH',
  },
  {
    value: 'area',
    label: 'PRODUCT.GENERAL.CONDITIONS.AREA',
  },
  {
    value: 'volume',
    label: 'PRODUCT.GENERAL.CONDITIONS.VOLUME',
  },
  {
    value: 'parcel',
    label: 'PRODUCT.GENERAL.CONDITIONS.PARCEL',
  }
];

export const pictureSpecs = {
  width: 1200,
  height: 1200,
  size: 3145728, // 3MB
  format: ['jpeg', 'jpg', 'gif', 'png', 'webp'],
  maxNumber: 10,
};

export const IS_VARIANT_OPTIONS = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
];



// const productConditions = [
//   {
//     value: 'NEW',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.NEW' }),
//   },
//   {
//     value: 'USED',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.USED' }),
//   },
//   {
//     value: 'REFURBISHED',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.REFURBISHED' }),
//   },
//   {
//     value: 'LIKE_NEW',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.LIKE_NEW' }),
//   },
//   {
//     value: 'VERY_GOOD',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.VERY_GOOD' }),
//   },
//   {
//     value: 'GOOD',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.GOOD' }),
//   },
//   {
//     value: 'ACCEPTABLE',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.ACCEPTABLE' }),
//   },
//   {
//     value: 'FOR_PARTS_OR_NOT_WORKING',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.FOR_PARTS_OR_NOT_WORKING' }),
//   },
//   {
//     value: 'REPAIR_OR_EXCHANGE',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.REPAIR_OR_EXCHANGE' }),
//   },
//   {
//     value: 'UNSPECIFIED',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.UNSPECIFIED' }),
//   },
// ];
