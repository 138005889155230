import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { Card, CardBody, CardSubHeader, SVFormControl, SVReactSelectCreatable } from '_metronic/_partials/controls';
import { TAX_CATEGORY, countries, getTaxStatus } from '../../_context/DocumentsUIHelpers';
import { useDocumentsUIContext } from '../../_context/DocumentsUIContext';
import { TaxCategoryDialog } from '../../document-dialogs/TaxCategoryDialog';
import { DocumentActions } from '../../_redux/actions';
import { Icon } from '_metronic/_icons';



export function CustomerCard({ loading }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { values: { customer_details, tax, related_invoice_id, is_from_invoice } } = useFormikContext();

  const [taxCategory, setTaxCategory] = useState({
    show: false,
    existing: { value: "", label: "" },
    newCategory: { value: "", label: "" },
    country: "",
    isVatId: false
  });

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    requestedDocument: UIContext.requestedDocument,
    openTaxCategoryDialog: UIContext.openTaxCategoryDialog,
  }), [UIContext]);

  const { company, document,isOssThreshold } = useSelector(state => ({
    company: state.company.companyBaseData,
    document: state.documents.documentForEdit,
    isOssThreshold: state.settings.invoiceSettings?.oss_details?.is_oss_threshold,
  }));

  const taxCategoryOptions = TAX_CATEGORY.map(item => ({
    value: item.value,
    label: intl({ id: item.label })
  }));

  const { id, customer_details: { delivery_address, email, name } } = document

  useEffect(() => {
    if (!id) {
      const taxStatus = getTaxStatus(company?.address?.country_code, delivery_address?.country_code, isOssThreshold);
      const existing = taxCategoryOptions.find(item => item.value === taxStatus);
      const country = countries.find(item => item.value === customer_details?.delivery_address?.country_code)?.label || "";
      const isVatId = customer_details.vat_id === "";

      tax.category = taxStatus;
      setTaxCategory({ ...taxCategory, existing, country, isVatId });
      dispatch(DocumentActions.updatePositionTaxesToFrei(taxStatus, isVatId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery_address]);


  const handleTaxCategoryChange = (value) => {
    const unitOptions = taxCategoryOptions.find(item => item.value === value?.value);
    if (unitOptions.value !== tax.category) {
      setTaxCategory({ ...taxCategory, show: true, newCategory: unitOptions, isVatId: customer_details.vat_id === "" })
    }
  };


  const handleVatIdChange = (value) => {
    const isVatId = value === "";
    setTaxCategory({ ...taxCategory, isVatId });
    dispatch(DocumentActions.updatePositionTaxesToFrei(tax?.category, isVatId));
  };


  const INPUTS = [
    {
      type: 'text',
      name: 'customer_details.name',
      label: "GENERAL.NAME",
      disabled: name,
    },
    {
      type: 'email',
      name: 'customer_details.email',
      label: "GENERAL.EMAIL",
      disabled: !(
        (Array.isArray(customer_details?.source?.marketplace) ? customer_details?.source?.marketplace.length > 0 : customer_details?.source?.marketplace)
        && !email
      ), // amazon bazen mail verisini göndermiyor. Eğer marketplace ise ve mail yoksa disabled olmayacak.
    },
    {
      type: 'text',
      name: 'customer_details.company',
      label: "GENERAL.COMPANY"
    },
    {
      type: 'text',
      name: 'customer_details.phone',
      label: "GENERAL.PHONE"
    },
    {
      type: 'text',
      name: 'customer_details.vat_id',
      label: "GENERAL.US_NUMBER",
      onBlur: (e) => handleVatIdChange(e.target.value),
    },
    {
      name: "tax.category",
      label: "DOCUMENT.PAYMENT_CATEGORY",
      component: SVReactSelectCreatable,
      onChange: handleTaxCategoryChange,
      value: taxCategoryOptions.find(item => item.value === tax.category),
      options: taxCategoryOptions,
    },
    {
      name: 'related_invoice_id',
      label: "DOCUMENT.TABLE.COLUMN.INVOICE_ID",
      disabled: is_from_invoice,
      value: related_invoice_id,
    },
  ];


  return (<>
    <Card className="card-stretch gutter-b">

      <CardSubHeader
        icon={<Icon.Person />}
        title={<FormattedMessage id="DOCUMENT.CUSTOMER.TITLE" />}
      />

      <CardBody className='py-5'>

        <Row>
          {INPUTS.map((item, i) => (
            !(item.name === "tax.category" && UIProps.requestedDocument.type === 'offers') &&
            <Col md={6} key={i}>
              <Field component={item.component || SVFormControl}
                {...{
                  type: item.type,
                  name: item.name,
                  label: intl({ id: item.label }),
                  options: item.options,
                  isClearable: item.isClearable,
                  withFeedbackLabel: true,
                  feedbackLabel: true,
                  disabled: loading || item.disabled,
                  required: item.required,
                  value: item.value,
                  onBlur: item.onBlur,
                  onChange: item.onChange,
                }}
              />
            </Col>
          ))}
        </Row>

      </CardBody>
    </Card>

    <TaxCategoryDialog
      show={taxCategory.show}
      onHide={() => setTaxCategory({ ...taxCategory, show: false })}
      taxCategory={taxCategory}
    />
  </>
  );
}