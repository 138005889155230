import { getSettingsByType, INITIAL_VALUES, setSettingsByType } from "../_context/SettingsHelper";
import ActionTypes from "./actionTypes";



const INITIAL_SETTINGS = {
  orderSettings: INITIAL_VALUES.order_settings,
  invoiceSettings: INITIAL_VALUES.invoice_settings,
  creditSettings: INITIAL_VALUES.credit_settings,
  waybillSettings: INITIAL_VALUES.waybill_settings,
  productSettings: INITIAL_VALUES.product_settings,
  dashboardSettings: INITIAL_VALUES.dashboard_settings,
}

const INITIAL_STATE = {
  loading: false,
  error: "",
  entities: [],
  contracts: [],
  entitiesForEdit: {},
  initial: {},
  document_count: {},
  ...INITIAL_SETTINGS
};


export function adminSettingsReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {


    // Create Document Settings
    case ActionTypes.CREATE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...getSettingsByType(payload.userSettings),
      };

    case ActionTypes.CREATE_FAIL:
      return {
        ...state,
        loading: false,
        access: {},
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Get Document Settings
    case ActionTypes.GET:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        ...setSettingsByType(payload.userSettings),
      };

    case ActionTypes.GET_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Get Document Settings by Type
    case ActionTypes.GET_BY_TYPE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_BY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...getSettingsByType(payload.userSettings),
      };

    case ActionTypes.GET_BY_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Delete Document Settings
    case ActionTypes.DOCUMENT_COUNT:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DOCUMENT_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        document_count: payload.count
      };

    case ActionTypes.DOCUMENT_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Update Document Settings
    case ActionTypes.UPDATE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...getSettingsByType(payload.settings),
      };

    case ActionTypes.UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };


    // Patch User Settings
    case ActionTypes.PATCH_USER_SETTINGS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.PATCH_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...getSettingsByType(payload.settings),
      };

    case ActionTypes.PATCH_USER_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        documentSettingsForEdit: {
          ...state.documentSettingsForEdit,
          default_shipping: [],
        },
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    //Variant Options
    /*     case ActionTypes.VARIANT_OPTIONS_HANDLER:
          return {
            ...state,
            documentSettingsForEdit: {
              ...state.documentSettingsForEdit,
               variant_options: payload.variantOptions,
            },
          }; */


    // Clear Document Settings
    case ActionTypes.CLEAR_LOADING_EFFECTS:
      return {
        ...state,
        loading: false,
      };

    // settings clear state not be needed
    // case ActionTypes.CLEAR_STATE:
    //   return {
    //     ...state,
    //     ...INITIAL_SETTINGS,
    //     productSettings: {
    //       ...INITIAL_SETTINGS.productSettings,
    //       digital_product: state.productSettings.digital_product, // digital_product korunuyor
    //     },
    //   };



    case ActionTypes.GET_CONTRACTS: {
      return {
        ...state,
        loading: true,
        actionId: 'get-contracts',
      };
    }

    case ActionTypes.GET_CONTRACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        contracts: payload.contracts,
        totalCount: payload.count.total,
        count: payload.count.count,
      };
    }

    case ActionTypes.GET_CONTRACTS_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }


    // Update Contract user
    case ActionTypes.UPDATE_CONTRACT:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.UPDATE_CONTRACT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };


    default:
      return state;
  }
}
