import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import UserInfoDropdown from "../user-info/UserInfoDropdown";



export default function CardItems({ Content, type }) {
  const isColumn = type === "column";
  return (
    Content?.length
      ? Content?.map((item, i) => (
        <Row key={i} className={Content.length - 1 === i ? "mb-3" : "mb-5"}>

          <Col sm={isColumn ? "12" : "5"} md={isColumn ? "12" : "4"} className="font-size-lg font-weight-bold text-dark-50">
            <FormattedMessage id={item.label} />
            {item.info && <UserInfoDropdown description={<FormattedMessage id={item.info} />} />}
          </Col>

          <Col sm={isColumn ? "12" : "7"} md={isColumn ? "12" : "8"} className="font-size-lg font-weight-bold mt-2 mt-sm-0">
            {item.content}
          </Col>

        </Row>
      ))
      : <></>
  );
}
