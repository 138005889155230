import React from 'react';
import { SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { TAX_OPTIONS, UNIT_OPTIONS } from 'app/modules/products/_context/ProductsUIHelpers';
import { convertPriceCurrency } from '_metronic/_helpers';



export function ProductSettingsForm({ products, productId }) {

  const { formatMessage: intl } = useIntl();

  const { values: { tax_rate, gross_price, default: disabled, sku } } = useFormikContext();

  const checkIsSkuValid = (sku) => {
    if (productId) {
      return products?.find(item => item.sku === sku && item.id !== productId);
    }
    return products?.find(item => item.sku === sku);
  };

  const INPUTS = [
    {
      name: "title",
      component: SVFormControl,
      label: "PRODUCT.GENERAL.NAME",
      withFeedbackLabel: true,
      feedbackLabel: true,
      required: true,
      size: "12",
      disabled: disabled,
    },
    {
      name: "sku",
      label: "GENERAL.SKU",
      component: SVFormControl,
      size: "6",
      disabled: disabled,
      feedbackLabel: checkIsSkuValid(sku) ? intl({ id: "PRODUCT.GENERAL.SKU_WARNING" }) : true,
    },
    {
      name: "gross_price",
      type: "number",
      label: "PRODUCT.GENERAL.SALE_PRICE_GROSS",
      price: true,
      // symbol: defaultCurrency?.symbol,
      size: "6",
      feedbackLabel: (gross_price?.toString()?.split('.')[1]?.length ?? 0) > 5
        ? intl({ id: "DOCUMENT.POSITION.IS_VALID.DECIMALS" })
        : gross_price > 0
          ? <div className='font-weight-bold'>
            <span className="text-muted">
              <FormattedMessage id='PRODUCT.PRICE.NET_PRICE' /> : </span>
            <span className='text-success'>
              {convertPriceCurrency(+gross_price / (1 + +tax_rate / 100), 'EUR', 3)}
            </span>
          </div>
          : true,
    },
    {
      name: 'tax_rate',
      label: "PRODUCT.GENERAL.TAX",
      feedbackLabel: true,
      component: SVReactSelect,
      options: TAX_OPTIONS.map(item => ({
        value: item.value,
        label: intl({ id: item.label })
      })),
      required: true,
      size: "6",
      disabled: disabled,
    },
    {
      name: "unit",
      component: SVReactSelect,
      options: UNIT_OPTIONS.map(item => ({
        value: item.value,
        label: intl({ id: item.label })
      })),
      label: "DOCUMENT.POSITION_UNIT",
      size: "6",
      disabled: disabled,
    },
  ];


  return (
    <Row>

      {INPUTS.map(input => (
        <Col md={input.size || '12'} key={input.name}>
          <Field component={input.component || SVFormControl}
            {...{
              name: input.name,
              type: input.type,
              label: input.label && intl({ id: input.label }),
              options: input.options,
              withFeedbackLabel: input.withFeedbackLabel || true,
              feedbackLabel: input.feedbackLabel || true,
              required: input.required,
              min: input.min,
              step: input.step,
              disabled: input.disabled,
            }}
          />
        </Col>
      ))}

    </Row>
  );
}
