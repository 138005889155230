import { call, put, takeEvery, select } from 'redux-saga/effects';
import { adminUserMiddleware, tokenErrorHandler } from 'middlewares';
import { adminUsersReportsMiddleware } from 'middlewares/admin/admin-users-reports/middleware';
import { AdminUsersReportActions } from './actions';
import ActionTypes from './actionTypes';




// Get Users Reports
function* getReportsUsers({ payload: { dateRange, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getReports = yield call(adminUsersReportsMiddleware.getReportsUsers, accessToken, dateRange, queryParams);
    const countUsers = yield call(adminUserMiddleware.getUsersCount, accessToken, dateRange, queryParams);

    yield put(AdminUsersReportActions.getReportsUsersSuccess(getReports.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersReportActions.getReportsUsersFail(JSON.stringify(err.response)));
  }
}




export function* adminUsersReportsSaga() {
  yield takeEvery(ActionTypes.GET_REPORTS_USERS, getReportsUsers);
}
