export default class AmazonListingsActionTypes {
  static get PREFIX() {
    return '[listing/amazon]';
  }

  // Get Amazon listings
  static get GET_LISTINGS() {
    return `${AmazonListingsActionTypes.PREFIX} GET_LISTINGS`;
  }
  static get GET_LISTINGS_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} GET_LISTINGS_SUCCESS`;
  }
  static get GET_LISTINGS_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} GET_LISTINGS_FAIL`;
  }


  // Find listing item by ASIN number on Amazon
  static get FIND_LISTING_ITEM() {
    return `${AmazonListingsActionTypes.PREFIX} FIND_LISTING_ITEM`;
  }
  static get FIND_LISTING_ITEM_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} FIND_LISTING_ITEM_SUCCESS`;
  }
  static get FIND_LISTING_ITEM_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} FIND_LISTING_ITEM_FAIL`;
  }

  // Draft listing item on Amazon
  static get DRAFT_LISTING_ITEM() {
    return `${AmazonListingsActionTypes.PREFIX} DRAFT_LISTING_ITEM`;
  }
  static get DRAFT_LISTING_ITEM_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} DRAFT_LISTING_ITEM_SUCCESS`;
  }
  static get DRAFT_LISTING_ITEM_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} DRAFT_LISTING_ITEM_FAIL`;
  }

  // Undo draft listing item on Amazon
  static get UNDO_DRAFT_LISTING_ITEM() {
    return `${AmazonListingsActionTypes.PREFIX} UNDO_DRAFT_LISTING_ITEM`;
  }
  static get UNDO_DRAFT_LISTING_ITEM_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} UNDO_DRAFT_LISTING_ITEM_SUCCESS`;
  }
  static get UNDO_DRAFT_LISTING_ITEM_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} UNDO_DRAFT_LISTING_ITEM_FAIL`;
  }


  // Create new Amazon listing by existing product
  static get CREATE_LISTING() {
    return `${AmazonListingsActionTypes.PREFIX} CREATE_LISTING`;
  }
  static get CREATE_LISTING_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} CREATE_LISTING_SUCCESS`;
  }
  static get CREATE_LISTING_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} CREATE_LISTING_FAIL`;
  }

  // Add new product to database
  static get ADD_NEW_PRODUCT() {
    return `${AmazonListingsActionTypes.PREFIX} ADD_NEW_PRODUCT`;
  }
  static get ADD_NEW_PRODUCT_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} ADD_NEW_PRODUCT_SUCCESS`;
  }
  static get ADD_NEW_PRODUCT_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} ADD_NEW_PRODUCT_FAIL`;
  }


  // Get product by id from database
  static get GET_PRODUCT_BY_ID() {
    return `${AmazonListingsActionTypes.PREFIX} GET_PRODUCT_BY_ID`;
  }
  static get GET_PRODUCT_BY_ID_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} GET_PRODUCT_BY_ID_SUCCESS`;
  }
  static get GET_PRODUCT_BY_ID_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} GET_PRODUCT_BY_ID_FAIL`;
  }

  // withdraw listing item on Amazon
  static get WITHDRAW_LISTING_ITEM() {
    return `${AmazonListingsActionTypes.PREFIX} WITHDRAW_LISTING_ITEM`;
  }
  static get WITHDRAW_LISTING_ITEM_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} WITHDRAW_LISTING_ITEM_SUCCESS`;
  }
  static get WITHDRAW_LISTING_ITEM_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} WITHDRAW_LISTING_ITEM_FAIL`;
  }

  // Publish listing item on Amazon
  static get PUBLISH_LISTING_ITEM() {
    return `${AmazonListingsActionTypes.PREFIX} PUBLISH_LISTING_ITEM`;
  }
  static get PUBLISH_LISTING_ITEM_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} PUBLISH_LISTING_ITEM_SUCCESS`;
  }
  static get PUBLISH_LISTING_ITEM_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} PUBLISH_LISTING_ITEM_FAIL`;
  }

  // Subscriptions
  static get CHECK_SUBSCRIPTION() {
    return `${AmazonListingsActionTypes.PREFIX} CHECK_SUBSCRIPTION`;
  }
  static get CHECK_SUBSCRIPTION_SUCCESS() {
    return `${AmazonListingsActionTypes.PREFIX} CHECK_SUBSCRIPTION_SUCCESS`;
  }
  static get CHECK_SUBSCRIPTION_FAIL() {
    return `${AmazonListingsActionTypes.PREFIX} CHECK_SUBSCRIPTION_FAIL`;
  }

  // reset state to initial state
  static get LISTING_RESET() {
    return `${AmazonListingsActionTypes.PREFIX} LISTING_RESET`;
  }

  // update random state
  static get UPDATE_STATE_PROPERTY() {
    return `${AmazonListingsActionTypes.PREFIX} UPDATE_STATE_PROPERTY`;
  }

}
