import { call, put, takeEvery, select } from 'redux-saga/effects';
import { adminUserMiddleware, tokenErrorHandler } from 'middlewares';
import { AdminUsersContactActions } from './actions';
import ActionTypes from './actionTypes';




// Get Users Contact
function* getUsersContact({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const getUsers = yield call(adminUserMiddleware.getUsersContact, accessToken, queryParams);
    const countUsers = yield call(adminUserMiddleware.getUsersCount, accessToken, '', queryParams);

    yield put(AdminUsersContactActions.getUsersContactSuccess(getUsers.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminUsersContactActions.getUsersContactFail(JSON.stringify(err.response)));
  }
}



export function* adminUsersContactSaga() {
  yield takeEvery(ActionTypes.GET_USERS_CONTACT, getUsersContact);
}
