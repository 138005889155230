import React, { useCallback, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { ProductSettingsCard } from './ProductSettingsCard';
import { useSearchQuery } from 'app/hooks';
import { ProductSettingsDialog } from './settings-dialog/ProductSettingsDialog';



export function ProductSettings() {

  const [queryParams, setQueryBase] = useState({
    // pagination: {
    //   page: 1,
    //   limit: 10,
    // },
    // order: 'id DESC',
    search: '',
    filter: null,
  });

  const setQueryParams = useCallback((params) => {
    setQueryBase(params)
  }, [])

  const { setSearchQuery, setOrderQuery, setPaginationQuery } = useSearchQuery(setQueryParams);

  return (
    <>
      <ProductSettingsDialog />

      <Row>
        <Col lg={12}>
          <ProductSettingsCard queryParams={queryParams} setPaginationQuery={setPaginationQuery} setOrderQuery={setOrderQuery} setSearchQuery={setSearchQuery} />
        </Col>
      </Row>
    </>
  )
}
