import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Modal, Button } from 'react-bootstrap';
import { importExportActions } from "../../_redux/actions";
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function EditErrorDialog({ show, onHide, errors, productsCreate, language, setOpenErrorDialog }) {

  const dispatch = useDispatch();

  const { skuMissing, skuInvalid, quantityInvalid, grossPriceInvalid, taxInvalid, } = errors;

  const errorMessages = [
    {
      label: 'IMPORT.PRODUCT.UPDATE.ERRORS.SKU_MISSING',
      count: skuMissing
    },
    {
      label: 'IMPORT.PRODUCT.UPDATE.ERRORS.SKU_INVALID',
      count: skuInvalid
    },
    {
      label: 'IMPORT.PRODUCT.UPDATE.ERRORS.QUANTITY_INVALID',
      count: quantityInvalid
    },
    {
      label: 'IMPORT.PRODUCT.UPDATE.ERRORS.GROSS_PRICE_INVALID',
      count: grossPriceInvalid
    },
    {
      label: 'IMPORT.PRODUCT.UPDATE.ERRORS.TAX_INVALID',
      count: taxInvalid
    },
  ];

  const handleConfirm = () => {
    dispatch(importExportActions.importUpdateProduct(productsCreate, language));
    setOpenErrorDialog({ show: false, errors: {} });
  };


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={show} backdrop="static" keyboard={false} aria-labelledby="error-contained-modal-title-vcenter" size={'md'} centered>

        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="GENERAL.WARNING" />
          </Modal.Title>
          <CloseButton onClick={onHide} />
        </Modal.Header>

        <Modal.Body>
          {errorMessages.map((error, i) => error.count > 0 && (
            <Alert variant="light-warning" className="alert-custom svg-icon fade show mb-5" role="alert" key={i}>
              <div className="alert-text">
                <Icon.ExclamationTriangle className="mr-2" />
                <FormattedMessage id={error.label} values={{ count: error.count }} />
              </div>
            </Alert>
          ))}
        </Modal.Body>

        <Modal.Footer className="flex-nowrap p-0 overflow-hidden">

          <Button className="border-right" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button className="border-right" onClick={handleConfirm} id="btn_yes_modal">
            <FormattedMessage id="GENERAL.CONTINUE" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
