import React, { createContext, useContext, useState } from 'react';


const UserSettingsUIContext = createContext({});
export const useUserSettingsUIContext = () => useContext(UserSettingsUIContext);



export function UserSettingsUIProvider({ children }) {

  const [ids, setIds] = useState([]);

  const [showEmailEditDialog, setShowEmailEditDialog] = useState(false);
  const openEmailEditDialog = () => setShowEmailEditDialog(true);
  const closeEmailEditDialog = () => setShowEmailEditDialog(false);

  const [showPasswordEditDialog, setShowPasswordEditDialog] = useState(false);
  const openPasswordEditDialog = () => setShowPasswordEditDialog(true);
  const closePasswordEditDialog = () => setShowPasswordEditDialog(false);

  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
  const openAccountDeleteDialog = () => setShowDeleteAccountDialog(true);
  const closeAccountDeleteDialog = () => setShowDeleteAccountDialog(false);

  const [showClearCacheDialog, setShowClearCacheDialog] = useState(false);
  const openClearCacheDialog = () => setShowClearCacheDialog(true);
  const closeClearCacheDialog = () => setShowClearCacheDialog(false);

  // Product settings
  const [productId, setProductId] = useState();

  const [showProductSettingsDialog, setShowProductSettingsDialog] = useState(false);
  const openProductSettingDialog = (id) => {
    setProductId(id);
    setShowProductSettingsDialog(true)
  };
  const closeProductSettingsDialog = () => setShowProductSettingsDialog(false);


  // Product delete modal
  const [showDeleteProductDialog, setShowDeleteProductDialog] = useState(false);
  const openProductDeleteDialog = (id) => {
    setProductId(id);
    setShowDeleteProductDialog(true);
  };
  const closeDeleteProductDialog = () => {
    setShowDeleteProductDialog(false);
  };

  const value = {
    ids,
    setIds,
    showEmailEditDialog,
    openEmailEditDialog,
    closeEmailEditDialog,

    showPasswordEditDialog,
    openPasswordEditDialog,
    closePasswordEditDialog,

    showDeleteAccountDialog,
    openAccountDeleteDialog,
    closeAccountDeleteDialog,

    showClearCacheDialog,
    openClearCacheDialog,
    closeClearCacheDialog,

    productId,
    setProductId,
    showProductSettingsDialog,
    openProductSettingDialog,
    closeProductSettingsDialog,

    showDeleteProductDialog,
    openProductDeleteDialog,
    closeDeleteProductDialog,


  };

  return (
    <UserSettingsUIContext.Provider value={value}>
      {children}
    </UserSettingsUIContext.Provider>
  );
}