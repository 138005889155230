import { isEmpty } from 'lodash';
import moment from 'moment';



export const fieldParamsForTable = {
  fields: [
    "id",
    "marketplace",
    "marketplace_url",
    "order_id",
    "order_number",
    "order_date",
    "total_weight",
    "order_notice",
    "notes",
    "latest_ship_date",
    "customer_details",
    "position",
    "payment_status",
    "order_status",
    "entered_payment",
    "pricing_summary",
    "shipping",
    "payment_warnings",
    "history",
    "created_at",
    "updated_at",
    "related_invoice_id",
    "marketplace_id",
    "ebay_invoice_id",
  ],
};

export const fieldParamsForDocument = {
  fields: [
    "id",
    "title",
    "customer_details",
    "order_date",
    "order_number",
    "pricing_summary",
    "position",
    "payment_status",
    "order_id",
    "subtitle",
    "shipping",
    "history",
  ],
};

export const customerFieldParams = {
  fields: [
    'id',
    'customer_details',
  ],
};

export const searchKeys = [
  'id',
  'order_id',
  'order_number',
  'marketplace',
  'marketplace_url',
  'customer_details.number',
  'customer_details.email',
  'customer_details.phone',
  'customer_details.name',
  'customer_details.company',
  'customer_details.delivery_address.post_code',
  'customer_details.delivery_address.city',
  'position.sku',
  'position.title',
  'shipping.tracking_id',
  'shipping.service'
];

export const fieldGroupingTable = {
  fields: [
    "id",
    "order_id",
    "shipping",
    "marketplace",
    "marketplace_url",
    "payment_status",
    "order_number",
    "pricing_summary",
    "customer_details",
    "position",
  ],
};

export const getAdjustedFields = (documentModel) => {
  let fields = [...fieldParamsForTable.fields];
  const fieldMappings = {
    'invoices': 'invoice_uploaded',
    'credits': 'credit_uploaded',
  };
  if (fieldMappings[documentModel]) {
    fields.push(fieldMappings[documentModel]);
  }
  return { ...fieldParamsForTable, fields };
};

//export const searchParamsForDocuments = function (keys, event) {
//
//  const numericValue = parseInt(event);
//  const regex = /^[0-9-]+$/;
//  const operator = isNaN(numericValue) || regex.test(numericValue) ? 'like' : 'eq';
//
//  if (isArray(keys) && isLength(keys.length)) {
//    const searchForKeys = keys.map(key => ({
//      [key]: {
//        [operator]: isNaN(numericValue) || regex.test(numericValue) ? event : numericValue,
//        ...(operator === 'like' && { options: 'i' }),
//      },
//    }));
//    return {
//      or: searchForKeys,
//    };
//  }
//
//  return {
//    or: []
//  };
//
//};



export const isActiveParams = function (access, filter) {
  if (access.documentModel === 'offers' || access.documentModel === 'orders' || access.documentModel === 'waybills') {
    return {
      archive: { neq: true },
      ...(access.documentModel === 'orders' && !(filter?.status === "all-orders") && { order_status: "open" })
    };
  }
  return {};
};


export const filterParams = function (filter) {

  if (!filter || isEmpty(filter)) return {};

  const { no_invoice, open_invoice, credit_created, non_labeled, date_from, date_to, doc_type, status, marketplaces, invoice_ebay } = filter;

  const filterQueryParams = {};

  if (no_invoice) {
    filterQueryParams['history'] = {
      $not: {
        $elemMatch: { document_to: "invoice" }
      }
    };
  }

  if (open_invoice) {
    filterQueryParams['payment_status'] = 'open';
  }

  if (credit_created) {
    filterQueryParams['history.document_to'] = 'credit';
  }

  if (status && status !== 'all-orders') {
    filterQueryParams['order_status'] = status;
  }

  if (marketplaces) {
    filterQueryParams['marketplace'] = (marketplaces && Object.keys(marketplaces).length > 0) ? { inq: Object.keys(marketplaces) } : undefined
    if (filterQueryParams["marketplace"]?.inq?.includes("non_market")) {
      filterQueryParams["marketplace"].inq.push("");
    }
  }

  if (non_labeled) {
    filterQueryParams['$and'] = [
      { shipping: { $not: { $elemMatch: {} } } },
    ];
  }

  if (invoice_ebay) {
    const filterConditions = [
      { marketplace: 'ebay' },
      { related_invoice_id: 'ebay' }
    ];

    if (invoice_ebay !== 'ebay') {
      filterConditions.push({ marketplace_id: invoice_ebay });
    }

    filterQueryParams['and'] = [{
      and: filterConditions
    }];
  }

  if (date_from && date_to) {
    const formatDate = (date, time) => moment(date).format('YYYY-MM-DD') + time;

    const dateQuery = {
      between: [
        formatDate(date_from, 'T00:00:00.001Z'),
        formatDate(date_to, 'T23:59:59.999Z')
      ]
    };

    if (doc_type === 'orders') {
      filterQueryParams['order_date'] = dateQuery;
    } else {
      filterQueryParams['created_at'] = dateQuery;
    }
  }

  return filterQueryParams;
};
