import React from 'react';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { paymentMethods } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { AdminUserInvoicesActions } from 'app/admin/user-invoices/_redux/actions';
import { UIDates, convertPriceCurrency } from '_metronic/_helpers';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function EnterPaymentTable({ documentId }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  const history = useHistory();

  const { userInvoiceForEdit } = useSelector(state => ({
    userInvoiceForEdit: state.adminUserInvoices.userInvoiceForEdit
  }));

  const deleteEnteredPayment = (documentId, paymentId) => {

    const urlParts = history.location.pathname.split('/')
    const documentIsEdit = ['edit', 'copy', 'new'].includes(urlParts[urlParts.length - 1])

    if (documentIsEdit) {
      dispatch(AdminUserInvoicesActions.deleteEnteredPaymentSuccess(paymentId, null));
    } else {
      dispatch(AdminUserInvoicesActions.deleteEnteredPayment(paymentId, documentId))
    }
  }

  const COLUMNS = [
    {
      dataField: '#',
      text: intl({ id: 'GENERAL.NO' }),
      isDummyField: true,
      headerStyle: { minWidth: '50px', width: '5%' },
      headerClasses: 'text-center pl-7',
      classes: 'font-weight-bold text-center pl-7',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'payment_method',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT_TYPE' }),
      classes: 'font-weight-bold',
      headerStyle: { minWidth: '120px' },
      formatter: (cell, row) => (paymentMethods.find(item => item.value === row.payment_method)?.label ?? cell),
    },
    {
      dataField: 'notes',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.NOTE' }),
      classes: 'font-weight-bold text-muted font-italic',
      headerStyle: { minWidth: '150px' },
    },
    {
      dataField: 'date',
      text: intl({ id: 'DOCUMENT.PAYMENT_DATE' }),
      headerClasses: 'text-center',
      classes: 'font-weight-bold font-size-sm text-dark-50 text-center',
      headerStyle: { minWidth: '100px', width: '10%' },
      formatter: (cell, row) => (UIDates.formatDate(row.date)),
    },
    {
      dataField: 'amount',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.AMOUNT' }),
      headerClasses: 'text-right pr-10',
      classes: 'text-right font-weight-bold font-size-lg pr-10',
      headerStyle: { minWidth: '60px' },
      formatter: (cell, row) => convertPriceCurrency(+row.amount, userInvoiceForEdit?.pricing_summary.currency),
    },
    {
      dataField: 'action',
      text: "...",
      headerClasses: 'font-size-h3 text-center pt-0 pr-9',
      classes: 'text-center pr-9',
      headerStyle: { minWidth: '50px', width: "5%" },
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="delete-payment-tooltip" className='font-weight-bold'><FormattedMessage id="DOCUMENT.PAYMENT_DELETE" /></Tooltip>}>
          <Button variant='icon' size='sm' className="text-muted btn-hover-danger svg-icon svg-icon-md" onClick={() => deleteEnteredPayment(documentId, row.id)} style={(row?.source) ? { pointerEvents: "none", opacity: 0.5 } : null}>
            <Icon.Trash />
          </Button>
        </OverlayTrigger>
      )
    }
  ];


  return (
    userInvoiceForEdit?.entered_payment?.length > 0
      ? <>
        <BootstrapTable
          keyField="id"
          wrapperClasses="table-responsive"
          headerClasses="bg-info-o-10"
          classes="table table-head-custom table-vertical-center mb-0"
          data={userInvoiceForEdit?.entered_payment || []}
          bootstrap4
          bordered={false}
          columns={COLUMNS}
          condensed
        />

        <div className="separator separator-dashed mt-8" />

      </>
      : <></>
  );
}
