import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from 'middlewares/_helpers/query-params';
import { searchKeys } from '../params';



export const adminUsersReportsMiddleware = {



  // ------------------------------------ REPORTS ------------------------------------ //

  getReportsUsers: async function (accessToken, dateRange, { pagination, filter, search, order }) {
    const { license, stat, connection, date_from, date_to, ...rest } = filter;

    const licenses = license ? Object.keys(license).filter((key) => license[key]) : [];
    const statuses = stat ? Object.keys(stat).filter((key) => stat[key]) : [];
    const connections = connection ? Object.keys(connection).filter((key) => connection[key]) : [];

    return await API.get(`/reports-users`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...((dateRange.date_from && dateRange.date_to) && {
              created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
            }),
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && {
              ...rest,
              ...(Array.isArray(statuses) && !statuses.includes('all_statuses') && statuses.length > 0 && {
                'status': { inq: statuses },
              }),
              ...(Array.isArray(licenses) && !licenses.includes('all_licenses') && licenses.length > 0 && {
                'license.type': { inq: licenses },
              }),
              ...(Array.isArray(connections) && !connections.includes('all_connections') && connections.length > 0 && {
                'marketplace': connections,
              }),
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};
