import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { LayoutSelect } from "../connection-forms/shippers/deutsche-post/LayoutSelect";
import { ConnectionUploadSettingsCard } from "./ConnectionUploadSettingsCard";
import { DeutschePostSettingsCard } from "./DeutschePostSettingsCard";
import { PolicyDocsUploadSettingsCard } from "./PolicyDocsUploadSettingsCard";
import { connectionForEdit } from "../_context/SettingsUIHelpers";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { BlankMessage } from "_metronic/_partials/components";
import { UserInfoDropdown } from "_metronic/_partials";
import { Icon } from "_metronic/_icons";



export function ConnectionSettingsCard({ connectionName, findToken }) {

  const [showLayouts, setShowLayouts] = useState(false)

  const { connection, layouts, loading } = useSelector((state) => ({
    loading: state.connections.loading,
    connection: state.connections[connectionForEdit(connectionName)],
    layouts: state.connections?.deutschePostConfigs?.map((layout) => ({ value: layout?.id, label: layout?.name })),
  }));

  const isSettings = ['deutsche_post'].includes(connectionName) || ['marketplace','shop'].includes(findToken.type) ;
  const isDPostLayout = typeof connection?.deutsche_post?.layout === 'string' && connection.deutsche_post.layout.length === 0;

  return (<>
    <Card className="card-stretch gutter-b">

      <CardSubHeader
        icon={<Icon.Settings />}
        title={<FormattedMessage id="SETTINGS.TITLE" />}
        info={isDPostLayout && <UserInfoDropdown
          title={<FormattedMessage id='SETTINGS.TITLE' />}
          description={<FormattedMessage id={'CONNECTIONS.SHIPPER.D_POST.LAYOUT_ADD_INFO'} />} />}>

        {isDPostLayout &&
          <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => setShowLayouts(true)} id="btn_layout">
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="CONNECTIONS.SHIPPER.D_POST.LAYOUT_ADD" />
            </span>
          </Button>}
      </CardSubHeader>

      <CardBody>
        {(isSettings && !isDPostLayout) ?
          <>
            <PolicyDocsUploadSettingsCard connectionName={connectionName} findToken={findToken} connection={connection} />
            <ConnectionUploadSettingsCard connectionName={connectionName} connection={connection} loading={loading} />
            <DeutschePostSettingsCard connection={connection} connectionName={connectionName} isDPostLayout={isDPostLayout} layouts={layouts} setShowLayouts={setShowLayouts} loading={loading} />
          </>
          : <BlankMessage
            icon={<Icon.Settings />}
            title={<FormattedMessage id={isDPostLayout ? 'CONNECTIONS.SHIPPER.D_POST_LAYOUT.BLANK_MESSAGE_TITLE' : 'CONNECTIONS.NO_FOUND_SETTINGS'} />}
            message={<FormattedMessage id={isDPostLayout ? 'CONNECTIONS.SHIPPER.D_POST_LAYOUT.BLANK_MESSAGE' : 'CONNECTIONS.NO_FOUND_SETTINGS'} />}
            arrow={isDPostLayout}
          />
        }

      </CardBody>

    </Card>

    <LayoutSelect loading={loading} layouts={layouts} connection={connection} show={showLayouts} onHide={() => setShowLayouts(false)} />
  </>
  );
}