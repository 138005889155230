import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUserInvoicesUIContext } from 'app/admin/user-invoices/_context/UserInvoicesUIContext';
import { AdminUserInvoicesActions } from 'app/admin/user-invoices/_redux/actions';
import * as uiHelpers from '../../../_context/UserInvoicesUIHelpers';
import { Button, Modal } from 'react-bootstrap';
import EnterPaymentBody from './EnterPaymentBody';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function EnterPaymentDialog() {

  const dispatch = useDispatch();

  const [paymentAmountDifference, setPaymentAmountDifference] = useState(0);

  const UIContext = useUserInvoicesUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showEnterPaymentDialog,
    onHide: UIContext.handleCloseEnterPaymentDialog,
    queryParams: UIContext.queryParams,
    handleOpenEnterPaymentEditDialog: UIContext.handleOpenEnterPaymentEditDialog,
    requestedDocument: UIContext.requestedDocument,
    setSelectedPaymentId: UIContext.setSelectedPaymentId,
    id: UIContext.selectedPaymentId,
  }),
    [UIContext]
  );

  useEffect(() => {
    if (!UIProps?.id) {
      onHideHandler();
    } else {
      dispatch(AdminUserInvoicesActions.getUserInvoiceById(UIProps?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, UIProps?.id]);


  const onHideHandler = () => {
    UIProps.onHide();
    dispatch(
      AdminUserInvoicesActions.cleanUpUserModuleParams({
        userInvoiceForEdit: uiHelpers.initialInvoice,
        actionController: uiHelpers.initialStatesForReducer.actionController,
      })
    );
    UIProps.setSelectedPaymentId(null);
  };

  const goBackDocumentTable = () => {
    // dispatch(DocumentActions.getDocuments(UIProps.documentQueryParams));
    onHideHandler();
  };

  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={goBackDocumentTable} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>

        <Modal.Header className='py-3'>

          <Modal.Title>
            <FormattedMessage id='DOCUMENT.ACTION.PAYMENTS' />
          </Modal.Title>

          <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => UIProps.handleOpenEnterPaymentEditDialog()} disabled={paymentAmountDifference <= 0} id='btn_open'>
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id='DOCUMENT.PAYMENT.ENTER_TITLE' />
            </span>
          </Button>

        </Modal.Header>

        <Modal.Body className='p-0'>

          <EnterPaymentBody documentId={UIProps?.id} setPaymentAmountDifference={setPaymentAmountDifference} />

        </Modal.Body>

        <Modal.Footer>
          <Button variant='light' onClick={goBackDocumentTable} id='btn_close_modal'>
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>
        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
