export default class ActionTypes {
  static get PREFIX() {
    return '[settings]';
  }


  // Create Document Settings action types
  static get CREATE() {
    return `${ActionTypes.PREFIX}CREATE`;
  }
  static get CREATE_SUCCESS() {
    return `${ActionTypes.PREFIX}CREATE_SUCCESS`;
  }
  static get CREATE_FAIL() {
    return `${ActionTypes.PREFIX}CREATE_FAIL`;
  }

  // Get Document Settings action types
  static get GET() {
    return `${ActionTypes.PREFIX}GET`;
  }
  static get GET_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_SUCCESS`;
  }
  static get GET_FAIL() {
    return `${ActionTypes.PREFIX}GET_FAIL`;
  }


  // Get settings by id action types
  static get GET_BY_TYPE() {
    return `${ActionTypes.PREFIX}GET_BY_TYPE`;
  }
  static get GET_BY_TYPE_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_BY_TYPE_SUCCESS`;
  }
  static get GET_BY_TYPE_FAIL() {
    return `${ActionTypes.PREFIX}GET_BY_TYPE_FAIL`;
  }


  // Get Document count action types
  static get DOCUMENT_COUNT() {
    return `${ActionTypes.PREFIX}DOCUMENT_COUNT`;
  }
  static get DOCUMENT_COUNT_SUCCESS() {
    return `${ActionTypes.PREFIX}DOCUMENT_COUNT_SUCCESS`;
  }
  static get DOCUMENT_COUNT_FAIL() {
    return `${ActionTypes.PREFIX}DOCUMENT_COUNT_FAIL`;
  }


  // Update Document Settings action types
  static get UPDATE() {
    return `${ActionTypes.PREFIX}UPDATE`;
  }
  static get UPDATE_SUCCESS() {
    return `${ActionTypes.PREFIX}UPDATE_SUCCESS`;
  }
  static get UPDATE_FAIL() {
    return `${ActionTypes.PREFIX}UPDATE_FAIL`;
  }


  // Update Document Settings action types
  static get PATCH_USER_SETTINGS() {
    return `${ActionTypes.PREFIX}PATCH_USER_SETTINGS`;
  }
  static get PATCH_USER_SETTINGS_SUCCESS() {
    return `${ActionTypes.PREFIX}PATCH_USER_SETTINGS_SUCCESS`;
  }
  static get PATCH_USER_SETTINGS_FAIL() {
    return `${ActionTypes.PREFIX}PATCH_USER_SETTINGS_FAIL`;
  }

  /**
* *OSS Actions
* @return 
*/
  static get GET_OSS() {
    return `${ActionTypes.PREFIX}GET_OSS`;
  }
  static get GET_OSS_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_OSS_SUCCESS`;
  }
  static get GET_OSS_FAIL() {
    return `${ActionTypes.PREFIX}GET_OSS_FAIL`;
  }

  //Variant Options action types
  /*   static get VARIANT_OPTIONS_HANDLER() {
      return `${ActionTypes.PREFIX}VARIANT_OPTIONS_HANDLER`;
    } */


  // Clear loading action types
  static get CLEAR_LOADING_EFFECTS() {
    return `${ActionTypes.PREFIX}CLEAR_LOADING_EFFECTS`;
  }


  static get CLEAR_STATE() {
    return `${ActionTypes.PREFIX}CLEAR_STATE`;
  }


}