import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';



export class AdminUsersActions {

  // Get all users
  static getUsers = (dateRange, queryParams) => {
    return {
      type: ActionTypes.GET_USERS,
      payload: {
        queryParams,
        dateRange
      },
    };
  };

  static getUsersSuccess = (users, count) => {
    return {
      type: ActionTypes.GET_USERS_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getUsersFail = (error) => {
    return {
      type: ActionTypes.GET_USERS_FAIL,
      payload: {
        error
      },
    };
  };


  // Get  user
  static getUser = (id) => {
    return {
      type: ActionTypes.GET_USER,
      payload: {
        id
      },
    };
  };

  static getUserSuccess = (user) => {
    return {
      type: ActionTypes.GET_USER_SUCCESS,
      payload: {
        user,
      },
    };
  };

  static getUserFail = (error) => {
    return {
      type: ActionTypes.GET_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Update Users
  static updateUser = (id, user) => {
    return {
      type: ActionTypes.UPDATE_USER,
      payload: {
        id,
        user
      },
    };
  };

  static updateUserSuccess = (userId, updatedUser) => {
    toast.success(<FormattedMessage id="USER.REDUX.UPDATE_USER.SUCCESS" />);
    return {
      type: ActionTypes.UPDATE_USER_SUCCESS,
      payload: {
        userId,
        updatedUser
      },
    };
  };

  static updateUserFail = (error) => {
    toast.error(error?.data?.error?.message);
    return {
      type: ActionTypes.UPDATE_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Delete user
  static deleteUser = (id, user, queryParams) => {
    return {
      type: ActionTypes.DELETE_USER,
      payload: {
        id,
        user,
        queryParams
      },
    };
  };

  static deleteUserSuccess = (user) => {
    toast.success(<FormattedMessage id='ADMIN.USER.DELETE_MESSAGE.SUCCESS' values={{ name: user.name }} />);
    return {
      type: ActionTypes.DELETE_USER_SUCCESS,
      payload: {
        user
      },
    };
  };

  static deleteUserFail = (error) => {
    toast.error(error.message);
    return {
      type: ActionTypes.DELETE_USER_FAIL,
      payload: {
        error
      },
    };
  };

  // Get all users
  static getReportsUsers = (dateRange, queryParams) => {
    return {
      type: ActionTypes.GET_REPORTS_USERS,
      payload: {
        dateRange,
        queryParams
      },
    };
  };

  static getReportsUsersSuccess = (users, count) => {
    return {
      type: ActionTypes.GET_REPORTS_USERS_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getReportsUsersFail = (error) => {
    return {
      type: ActionTypes.GET_REPORTS_USERS_FAIL,
      payload: {
        error
      },
    };
  };


  // Get user details
  static getUserCompany = (id, filter) => {
    return {
      type: ActionTypes.GET_USER_COMPANY,
      payload: {
        id,
        filter
      },
    };
  };

  static getUserCompanySuccess = (data) => {
    return {
      type: ActionTypes.GET_USER_COMPANY_SUCCESS,
      payload: {
        company: data.company,
        usage_report: data.usage_report,
      },
    };
  }

  static getUserCompanyFail = (error) => {
    return {
      type: ActionTypes.GET_USER_COMPANY_FAIL,
      payload: {
        error
      },
    };
  }

  static getOrderReport = (id, filter) => {
    return {
      type: ActionTypes.GET_ORDER_REPORT,
      payload: {
        id,
        filter
      },
    };
  };

  static getOrderReportSuccess = (data) => {
    return {
      type: ActionTypes.GET_ORDER_REPORT_SUCCESS,
      payload: {
        order_report: data.order_report,
      },
    };
  }

  static getOrderReportFail = (error) => {
    return {
      type: ActionTypes.GET_ORDER_REPORT_FAIL,
      payload: {
        error
      },
    };
  }

  static getUserLogEntries = (id, order, search, filter, page, pageSize) => {
    return {
      type: ActionTypes.GET_USER_LOG_ENTRIES,
      payload: {
        id,
        order,
        search,
        filter,
        page,
        pageSize
      },
    };
  };

  static getUserLogEntriesSuccess = (data) => {
    return {
      type: ActionTypes.GET_USER_LOG_ENTRIES_SUCCESS,
      payload: {
        log_entries: data.logEntries,
        total_log_entries: data.totalLogEntries,
      },
    };
  };

  static getUserLogEntriesFail = (error) => {
    return {
      type: ActionTypes.GET_USER_LOG_ENTRIES_FAIL,
      payload: {
        error
      },
    };
  };

  // Users Action Creators
  static cleanUpUserModule = () => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE,
    };
  };

  static cleanUpUserModuleParams = (params) => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE_PARAMS,
      payload: {
        params
      },
    };
  };

}