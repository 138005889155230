import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { userGuides } from "app/user-guides/guides";
import { Icon } from "_metronic/_icons";



export function ConnectionInfoCard({ connectionName }) {

  const { formatMessage: intl } = useIntl();

  const [guide, setGuide] = useState({
    title: "GENERAL.GENERAL",
    description: "GENERAL.GENERAL",
  });

  const { pathname } = useLocation();

  useEffect(() => {

    let subGuide = {};
    if (pathname.split('/')[1] === 'connections') {
      const findGuide = userGuides.find(guide => guide.main.location === 'add');
      subGuide = findGuide && findGuide[connectionName];
    }

    subGuide && setGuide(subGuide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.InfoCircle />} title={<FormattedMessage id={guide?.title} />} />

      <CardBody>

        <ul className="alert-text text-justify text-dark-75 line-height-xl pl-5">
          {intl({ id: guide?.description }).split("#").map((item, i) => ["href=", "<b>"].some(el => item.includes(el))
            ? <li className="font-weight-bold text-muted mb-2" key={i}>
              <FormattedHTMLMessage id={item} values={{ what: 'react-intl', a: chunks => <a href='/'>{chunks}</a>, b: chunks => <strong>{chunks}</strong> }} />
            </li>
            : <li className="font-weight-bold text-muted mb-2" key={i}>
              <span className="text-muted">
                {item}
              </span>
            </li>
          )}
        </ul>

      </CardBody>

    </Card>
  );
}