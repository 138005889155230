
import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  temp_users: [],
  success: false,
  count: 0,
  totalCount: 0,
  error: null,
};


export const adminTempUsersReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    // Get all temp users
    case ActionTypes.GET_TEMP_USERS: {
      return {
        ...state,
        loading: true,
        actionId: 'get-temp-users',
      };
    }

    case ActionTypes.GET_TEMP_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        temp_users: payload.tempUsers,
        totalCount: payload.count.total,
        count: payload.count.count,
      };
    }

    case ActionTypes.GET_TEMP_USERS_FAIL: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }


    // Update Temp user
    case ActionTypes.UPDATE_TEMP_USER:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_TEMP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.UPDATE_TEMP_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };


    // Delete temp users
    case ActionTypes.DELETE_TEMP_USERS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_TEMP_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        temp_users: state.temp_users.filter((user) => user.id !== payload.id),
        count: state.count - 1,
        totalCount: state.totalCount - 1,
      };

    case ActionTypes.DELETE_TEMP_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };


    // Clean up the user module
    case ActionTypes.CLEAN_UP_USER_MODULE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case ActionTypes.CLEAN_UP_USER_MODULE_PARAMS: {
      return {
        ...state,
        ...payload.params,
      };
    }


    default:
      return state;
  }
};
