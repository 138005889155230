import { all } from 'redux-saga/effects';

import { authSaga } from 'app/pages/auth/_redux/saga';
import { productSaga } from 'app/modules/products/_redux/saga';
import { customerSaga } from 'app/modules/customers/_redux/saga';
import { supplierSaga } from 'app/modules/suppliers/_redux/saga';
import { documentSaga } from 'app/modules/documents/_redux/saga';
import { connectionsSaga } from 'app/modules/connections/_redux/saga';
import { dashboardSaga } from 'app/modules/dashboard/_redux/saga';
import { archiveSaga } from 'app/modules/archive/_redux/saga';
import { importExportSaga } from 'app/modules/import-export/_redux/saga';
import { listingsSaga } from 'app/modules/product-listings/_redux/saga';
import { notificationSaga } from '_metronic/layout/components/header/_redux/saga';
import { settingsSaga } from 'app/modules/settings/_redux/saga';
import { companySaga } from 'app/modules/settings/company/_redux/saga';
import { categoriesSaga } from 'app/modules/settings/category/_redux/saga';
import { reportSaga } from 'app/modules/report/_redux/saga';
import { amazonListingSaga } from 'app/modules/product-listings/amazon-listings/_redux/saga';
import { shippingSaga } from 'app/modules/shippings/_redux/saga';
import { digitalProductSaga } from 'app/modules/digital-product/_redux/saga';
import { adminSettingsSaga } from 'app/admin/settings/_redux/saga';
import { supportSaga } from 'app/admin/support/_redux/saga';
import { adminUsersSaga } from 'app/admin/users/_redux/saga';
import { adminTempUsersSaga } from 'app/admin/temp-users/_redux/saga';
import { adminUsersContactSaga } from 'app/admin/users-contact/_redux/saga';
import { adminUserInvoicesSaga } from 'app/admin/user-invoices/_redux/saga';
import { adminUsersReportsSaga } from 'app/admin/reports-users/_redux/saga';



export function* rootSaga() {
  yield all([
    authSaga(),

    productSaga(),
    customerSaga(),
    supplierSaga(),
    importExportSaga(),
    documentSaga(),
    connectionsSaga(),
    dashboardSaga(),
    archiveSaga(),
    settingsSaga(),
    listingsSaga(),
    notificationSaga(),
    adminSettingsSaga(),
    categoriesSaga(),
    companySaga(),
    reportSaga(),
    amazonListingSaga(),
    shippingSaga(),
    digitalProductSaga(),
    
    adminUsersSaga(),
    adminTempUsersSaga(),
    adminUsersContactSaga(),
    adminUserInvoicesSaga(),
    adminUsersReportsSaga(),
    supportSaga(),
  ]);
}
