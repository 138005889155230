import React, { useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ModuleRoutes, RELATIVE_PATH } from 'constants/moduleRoutes';
import { ProductListingsUIProvider } from '../_context/ProductListingsUIContext';
import { AmazonListingsSearchProduct } from './amazon-listings-add-product/AmazonListingsSearchProduct';
import { AmazonListingsAddProduct } from './amazon-listings-add-product/AmazonListingsAddProduct';
import { AmazonListingsPage } from './AmazonListingsPage';
import { getLastSearch } from '_metronic/_helpers';
import { ContentRoute } from '_metronic/layout';
import { toast } from 'react-toastify';



export default function AmazonListings({ match: { params: { tokenId } }, history }) {

  const [ids, setIds] = useState([]);
  const lastSearch = getLastSearch();

  const contextUIEvents = {
    tokenId,
    market_id: tokenId,
    ids,
    setIds,
    resolveQuery: (qs) => {
      const query = [];
      if (qs.productId) {
        query.push(`productId=${qs.productId}`);
      }
      if (qs.asin) {
        query.push(`identifier=ASIN&asin=${qs.asin}`);
      } else if (qs.keyword) {
        query.push(`identifier=KEYWORD&keyword=${qs.keyword}`);
      }
      return query.join('&');
    },

    getAddProductsUrl: ({ tokenId, asin, productId }) => {
      const query = contextUIEvents.resolveQuery({ asin, productId });
      return ModuleRoutes.AMAZON_LISTINGS_ADD_PRODUCT_FN(tokenId, query || '');
    },

    openAddProducts: ({ tokenId, asin, keyword, productId }) => {
      const query = contextUIEvents.resolveQuery({ asin, keyword, productId });
      return history.push(ModuleRoutes.AMAZON_LISTINGS_ADD_PRODUCT_FN(tokenId, query || ''));
    },

    openSearchProducts: ({ tokenId }) => {
      if (!tokenId) {
        return toast.error('Token ID is required');
      }
      return history.push(ModuleRoutes.AMAZON_LISTINGS_SEARCH_PRODUCT_FN(tokenId));
    },

    backToMainPage: () => history.push(ModuleRoutes.AMAZON_LISTINGS_FN(tokenId) + lastSearch)
  };

  return (
    <ProductListingsUIProvider contextUIEvents={contextUIEvents}>
      <Switch>
        <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.AMAZON_LISTINGS} />
        <ContentRoute path={ModuleRoutes.AMAZON_LISTINGS_ADD_PRODUCT} component={AmazonListingsAddProduct} />
        <ContentRoute path={ModuleRoutes.AMAZON_LISTINGS_SEARCH_PRODUCT} component={AmazonListingsSearchProduct} />
        <ContentRoute path={ModuleRoutes.AMAZON_LISTINGS} component={AmazonListingsPage} />
      </Switch>
    </ProductListingsUIProvider>
  );
}
