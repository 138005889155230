import { call, put, select, takeEvery } from 'redux-saga/effects';
import ActionTypes from './actionTypes';
import { AmazonListingsActions } from './actions';
import { amazonListingsMiddleware } from 'middlewares/product-listings/amazon-middleware';
import { productMiddleware } from 'middlewares';

/**
 * Get the listing item by ASIN number on Amazon
 * @param {string} payload.asin ASIN number of the product to search on Amazon
 * @param {string} payload.tokenId  Token ID of the amazon market place
 */
function* amazonFindListingItem({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const getItem = yield call(amazonListingsMiddleware.findListingsItem, accessToken, payload);
    yield put(AmazonListingsActions.amazonFindListingItemResolves({ error: undefined, item: getItem.data }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonFindListingItemResolves({ error: JSON.stringify(error), isError: true }));
  }
}

/**
 * Draft listing item on Amazon by the product details
 * @param {Object} payload Product details to draft listing on Amazon
 */
function* amazonDraftListingItem({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const draftItem = yield call(amazonListingsMiddleware.draftListingItem, accessToken, payload);
    yield put(AmazonListingsActions.amazonDraftListingItemResolves({ error: undefined, item: draftItem.data }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonDraftListingItemResolves({ error: JSON.stringify(error), isError: true }));
  }
}

/**
 * Add new listing item on Amazon
 * @param {Object} payload Product details to create listing on Amazon
 */
function* amazonCreateListing({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const createItem = yield call(amazonListingsMiddleware.createListing, accessToken, payload);
    yield put(AmazonListingsActions.amazonCreateListingResolves({ error: undefined, item: createItem.data }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonCreateListingResolves({ error: JSON.stringify(error), isError: true }));
  }
}

/**
 * Get product by id from database
 * @param {string} payload.productId Product ID to get the product details
 */
function* getProductById({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const getProduct = yield call(productMiddleware.getProductById, accessToken, payload.productId);
    yield put(AmazonListingsActions.getProductByIdResolves({ error: undefined, product: getProduct.data }));
  } catch (error) {
    yield put(AmazonListingsActions.getProductByIdResolves({ error: JSON.stringify(error), isError: true }));
  }
}

/**
 * Withdraw listing item on Amazon
 * @param {string} payload.productId product IDs to withdraw from Amazon
 * @param {string} payload.marketId market place ID of the Amazon
 */
function* withdrawListingItem({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    yield call(amazonListingsMiddleware.withdrawnListingItems, accessToken, payload);
    yield put(AmazonListingsActions.amazonWithdrawListingItemResolves({ error: undefined }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonWithdrawListingItemResolves({ error: JSON.stringify(error), isError: true }));
  }
}

/**
 * Publish listing item on Amazon
 * @param {string[]} payload.productIds product IDs to publish on Amazon
 */
function* publishListingItem({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const resPublishListings = yield call(amazonListingsMiddleware.publishListingsItem, accessToken, payload);
    yield put(AmazonListingsActions.amazonPublishListingItemResolves({ error: undefined, data: resPublishListings.data }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonPublishListingItemResolves({ error: JSON.stringify(error.message), isError: true }));
  }
}

/**
 * Add new product to database
 */
function* addNewProduct({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const addProduct = yield call(amazonListingsMiddleware.addNewProduct, accessToken, payload);
    yield put(AmazonListingsActions.addNewProductResolves({ error: undefined, product: addProduct.data }));
  } catch (error) {
    yield put(AmazonListingsActions.addNewProductResolves({ error: JSON.stringify(error), isError: true }));
  }
}

/**
 * Undo draft listing item on Amazon
 */
function* amazonUndoDraftListingItem({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    yield call(amazonListingsMiddleware.undoDraftListingItem, accessToken, payload);
    yield put(AmazonListingsActions.amazonUndoDraftListingItemResolves({ error: undefined }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonUndoDraftListingItemResolves({ error: JSON.stringify(error), isError: true }));
  }
}

/**
 * Check listing subscription on Amazon
 */
function* amazonCheckSubscription({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const subscriptions = yield call(amazonListingsMiddleware.checkSubscription, accessToken);
    yield put(AmazonListingsActions.amazonCheckSubscriptionRequest({ error: undefined, subscriptions: subscriptions.data }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonCheckSubscriptionResolves({ error: JSON.stringify(error), isError: true }));
  }
}

/**
 * Get listing items from Amazon
 */
function* amazonGetListings({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.access_token);
    const listings = yield call(amazonListingsMiddleware.getListingsItem, accessToken, { marketId: payload.marketId, productId: payload.productId });
    yield put(AmazonListingsActions.amazonGetListingsResolves({ error: undefined, listings: listings.data }));
  } catch (error) {
    yield put(AmazonListingsActions.amazonGetListingsResolves({ error: error.response?.data?.error?.message, isError: true }));
  }
}

export function* amazonListingSaga() {
  yield takeEvery(ActionTypes.FIND_LISTING_ITEM, amazonFindListingItem);
  yield takeEvery(ActionTypes.DRAFT_LISTING_ITEM, amazonDraftListingItem);
  yield takeEvery(ActionTypes.CREATE_LISTING, amazonCreateListing);
  yield takeEvery(ActionTypes.GET_PRODUCT_BY_ID, getProductById);
  yield takeEvery(ActionTypes.WITHDRAW_LISTING_ITEM, withdrawListingItem);
  yield takeEvery(ActionTypes.PUBLISH_LISTING_ITEM, publishListingItem);
  yield takeEvery(ActionTypes.ADD_NEW_PRODUCT, addNewProduct);
  yield takeEvery(ActionTypes.UNDO_DRAFT_LISTING_ITEM, amazonUndoDraftListingItem);
  yield takeEvery(ActionTypes.CHECK_SUBSCRIPTION, amazonCheckSubscription);
  yield takeEvery(ActionTypes.GET_LISTINGS, amazonGetListings);
}
