import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';
import { searchKeys } from './params';



export const customerMiddleware = {

  createCustomer: async function (accessToken, customer) {
    return await API.post(`/customers`, customer, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  putCustomer: async function (accessToken, customer) {
    return await API.put(`/customers/${customer.id}`, customer, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteCustomer: async function (accessToken, ids) {
    return await API.delete(`/customers/${ids}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCustomerById: async function (accessToken, id) {
    return await API.get(`/customers/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCustomers: async function (accessToken, { pagination, search, filter, order }) {
    const { date_from, date_to, marketplaces, ...rest } = filter;

    // Prepare marketplace filter
    let marketplaceFilter;
    if (marketplaces) {
      const marketplaceKeys = Object.keys(marketplaces);
      marketplaceFilter = marketplaceKeys.length > 0 ? { inq: marketplaceKeys } : undefined;

      // Handle "non_market"
      if (marketplaceFilter?.inq?.includes("non_market")) {
        marketplaceFilter.inq.push("");
        marketplaceFilter.inq.push("salevali");
      }
    }

    return await API.get(`/customers`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: order
            ? order?.includes("country_code")
              ? [order, `address[0].post_code ${order?.split(' ')[1]}`]
              : [order]
            : ['order_date DESC'],
          where: {
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...((date_from && date_to) && {
              created_at: { between: [`${date_from}T00:00:00.001Z`, `${date_to}T23:59:59.999Z`] }
            }),
            active: true,
            ...rest,
            ...(marketplaceFilter && { 'source.marketplace': marketplaceFilter }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCount: async function (accessToken, { search, filter }) {
    const { date_from, date_to, marketplaces, ...rest } = filter || {};

    // Prepare marketplace filter
    let marketplaceFilter;
    if (marketplaces) {
      const marketplaceKeys = Object.keys(marketplaces);
      marketplaceFilter = marketplaceKeys.length > 0 ? { inq: marketplaceKeys } : undefined;

      // Handle "non_market"
      if (marketplaceFilter?.inq?.includes("non_market")) {
        marketplaceFilter.inq.push("");
        marketplaceFilter.inq.push("salevali");
      }
    }

    return await API.get(`/customers/count`, {
      params: {
        where: {
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
          ...((date_from && date_to) && {
            created_at: { between: [`${date_from}T00:00:00.001Z`, `${date_to}T23:59:59.999Z`] }
          }),
          active: true,
          ...rest,
          ...(marketplaceFilter && { 'source.marketplace': marketplaceFilter }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCustomersForDocument: async function (accessToken, { search }) {
    return await API.get(`/customers`, {
      params: {
        filter: {
          where: {
            ...(search.searchKeys &&
              search.searchText && {
              ...searchParams(search.searchKeys, search.searchText),
            }),
            active: true,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  findCustomer: async function (accessToken, key, value, customerId) {
    return await API.get(`/customers`, {
      params: {
        filter: {
          where: {
            [key]: value,
            ...(customerId && { id: { $ne: customerId } }),
            active: true,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCustomerDocumentCounts: async function (accessToken, customerNumber) {
    return await API.get(`/customers/document-counts/${customerNumber}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};