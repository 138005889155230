export default class ActionTypes {
  static get PREFIX() {
    return '[import_export]';
  }



  static get ACCOUNTING_EXPORT_CLEAR() {
    return `${ActionTypes.PREFIX}ACCOUNTING_EXPORT_CLEAR`;
  }


  // Import Example action types
  static get IMPORT_EXAMPLE() {
    return `${ActionTypes.PREFIX}IMPORT_EXAMPLE`;
  }
  static get IMPORT_EXAMPLE_SUCCESS() {
    return `${ActionTypes.PREFIX}IMPORT_EXAMPLE_SUCCESS`;
  }
  static get IMPORT_EXAMPLE_FAIL() {
    return `${ActionTypes.PREFIX}IMPORT_EXAMPLE_FAIL`;
  }


  // All export data action types
  static get IMPORT_EXPORTS_GET() {
    return `${ActionTypes.PREFIX}IMPORT_EXPORTS_GET`;
  }
  static get IMPORT_EXPORTS_GET_SUCCESS() {
    return `${ActionTypes.PREFIX}IMPORT_EXPORTS_GET_SUCCESS`;
  }
  static get IMPORT_EXPORTS_GET_FAIL() {
    return `${ActionTypes.PREFIX}IMPORT_EXPORTS_GET_FAIL`;
  }


  // Export data action types
  static get EXPORT_GET() {
    return `${ActionTypes.PREFIX}EXPORT_GET`;
  }
  static get EXPORT_GET_SUCCESS() {
    return `${ActionTypes.PREFIX}EXPORT_GET_SUCCESS`;
  }
  static get EXPORT_GET_FAIL() {
    return `${ActionTypes.PREFIX}EXPORT_GET_FAIL`;
  }


  // Export delete action types
  static get IMPORT_EXPORT_DELETE() {
    return `${ActionTypes.PREFIX}IMPORT_EXPORT_DELETE`;
  }
  static get IMPORT_EXPORT_DELETE_SUCCESS() {
    return `${ActionTypes.PREFIX}IMPORT_EXPORT_DELETE_SUCCESS`;
  }
  static get IMPORT_EXPORT_DELETE_FAIL() {
    return `${ActionTypes.PREFIX}IMPORT_EXPORT_DELETE_FAIL`;
  }


  // Export download action types
  static get IMPORT_EXPORT_DOWNLOAD() {
    return `${ActionTypes.PREFIX}IMPORT_EXPORT_DOWNLOAD`;
  }
  static get IMPORT_EXPORT_DOWNLOAD_SUCCESS() {
    return `${ActionTypes.PREFIX}IMPORT_EXPORT_DOWNLOAD_SUCCESS`;
  }
  static get IMPORT_EXPORT_DOWNLOAD_FAIL() {
    return `${ActionTypes.PREFIX}IMPORT_EXPORT_DOWNLOAD_FAIL`;
  }


  //Import Add
  static get IMPORT_PREPARATION() {
    return `${ActionTypes.PREFIX}IMPORT_PREPARATION`;
  }


  //Import Update
  static get IMPORT_PREPARATION_UPDATE() {
    return `${ActionTypes.PREFIX}IMPORT_PREPARATION_UPDATE`;
  }


  // Import delete
  static get IMPORT_PREPARATION_DELETE() {
    return `${ActionTypes.PREFIX}IMPORT_PREPARATION_DELETE`;
  }


  // Import action types
  static get IMPORT_PRODUCT() {
    return `${ActionTypes.PREFIX}IMPORT_PRODUCT`;
  }
  static get IMPORT_PRODUCT_SUCCESS() {
    return `${ActionTypes.PREFIX}IMPORT_PRODUCT_SUCCESS`;
  }
  static get IMPORT_PRODUCT_FAIL() {
    return `${ActionTypes.PREFIX}IMPORT_PRODUCT_FAIL`;
  }


  static get IMPORT_UPDATE_PRODUCT() {
    return `${ActionTypes.PREFIX}IMPORT_UPDATE_PRODUCT`;
  }
  static get IMPORT_UPDATE_PRODUCT_SUCCESS() {
    return `${ActionTypes.PREFIX}IMPORT_UPDATE_PRODUCT_SUCCESS`;
  }
  static get IMPORT_UPDATE_PRODUCT_FAIL() {
    return `${ActionTypes.PREFIX}IMPORT_UPDATE_PRODUCT_FAIL`;
  }


  // Import Customer types
  static get IMPORT_CUSTOMER() {
    return `${ActionTypes.PREFIX}IMPORT_CUSTOMER`;
  }
  static get IMPORT_CUSTOMER_SUCCESS() {
    return `${ActionTypes.PREFIX}IMPORT_CUSTOMER_SUCCESS`;
  }
  static get IMPORT_CUSTOMER_FAIL() {
    return `${ActionTypes.PREFIX}IMPORT_CUSTOMER_FAIL`;
  }


  // Import Category types
  static get IMPORT_CATEGORY() {
    return `${ActionTypes.PREFIX}IMPORT_CATEGORY`;
  }
  static get IMPORT_CATEGORY_SUCCESS() {
    return `${ActionTypes.PREFIX}IMPORT_CATEGORY_SUCCESS`;
  }
  static get IMPORT_CATEGORY_FAIL() {
    return `${ActionTypes.PREFIX}IMPORT_CATEGORY_FAIL`;
  }


  // Check Data
  static get CHECK_DATA() {
    return `${ActionTypes.PREFIX}CHECK_DATA`;
  }
  static get CHECK_DATA_SUCCESS() {
    return `${ActionTypes.PREFIX}CHECK_DATA_SUCCESS`;
  }
  static get CHECK_DATA_FAIL() {
    return `${ActionTypes.PREFIX}CHECK_DATA_FAIL`;
  }


  // Clean up redux state
  static get CLEAN_UP_REDUX_STATE() {
    return `${this.PREFIX}CLEAN_UP_REDUX_STATE`;
  }


  // Clean up redux state
  static get RESET_FORM_HANDLER() {
    return `${this.PREFIX}RESET_FORM_HANDLER`;
  }
}