import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'app/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectAmazonItem, selectAmazonListings } from '../_redux/reducer';
import { AmazonListingsActions } from '../_redux/actions';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { AmazonListingsAddProductForm } from './AmazonListingsAddProductForm';
import { AmazonSuggestedProductList1 } from '../amazon-suggested-product-list/AmazonSuggestedProductList';
import { AmazonListingsAddProductHeader } from './AmazonListingsAddProductHeader';
import { useProductListingsUIContext } from '../../_context/ProductListingsUIContext';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';

/* 
ASIN exanple:  B07BS89D3Q, B01J1VPQAM
*/

const setupInitialValues = (product, marketplaceId) => {
  if (!product)
    return {
      sku: '',
      price: 0,
      quantity: 0,
      condition: 'new_new',
      fulfillmentStrategy: '',
      marketplaceIds: marketplaceId || ''
    };
  return {
    sku: product.sku,
    price: product.pricing.gross_price,
    quantity: product.quantity,
    condition: 'new_new',
    fulfillmentStrategy: 'FBM',
    marketplaceIds: marketplaceId
  };
};

export function AmazonListingsAddProduct({
  match: {
    params: { tokenId }
  }
}) {
  const dispatch = useDispatch();
  // extract query params
  const query = useQuery();
  const asin = query.get('asin');
  const productId = query.get('productId');
  // const identifier = query.get('identifier');

  const productListingsUIContext = useProductListingsUIContext();
  const UIProps = useMemo(
    () => ({
      backToMainPage: productListingsUIContext.backToMainPage
    }),
    [productListingsUIContext.backToMainPage]
  );

  // get amazon item from store
  const item = useSelector(selectAmazonItem);
  // get product from store by productId
  const { product } = useSelector(selectAmazonListings);
  const amazonConnection = useSelector((state) => state.connections.entities.find((entity) => entity.id === tokenId));
  const marketplaceId = amazonConnection && amazonConnection.amazon && amazonConnection.amazon.MarketplaceID;

  useEffect(() => {
    // fetch item from Amazon by ASIN
    if (asin) {
      dispatch(AmazonListingsActions.amazonFindListingItemRequest({ asin, tokenId }));
    }

    // fetch product fron database by productId
    if (productId) {
      dispatch(AmazonListingsActions.getProductByIdRequest(productId));
    }

    return () => {
      dispatch(AmazonListingsActions.amazonListingReset());
    };
  }, [dispatch, asin, productId, tokenId]);

  const btnRef = useRef();
  const saveButtonClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const onAddListingToAmazon = useCallback(
    (values, action) => {
      const marketplaceIds = [marketplaceId] || [];
      const createListings = {
        productId: productId,
        tokenId: tokenId,
        sku: values.sku,
        listings: {
          market: 'Amazon',
          market_id: tokenId,
          asin: values.asin,
          marketplace_ids: marketplaceIds || [],
          status: 'DRAFT',
          catalog_item: null,
          listings_item: {
            productType: values.productType,
            attributes: {
              merchant_suggested_asin: marketplaceIds.map((mpId) => ({ value: values.asin, marketplace_id: mpId })),
              condition_type: marketplaceIds.map((mpId) => ({ value: values.condition, marketplace_id: mpId })),
              merchant_shipping_group: marketplaceIds.map((mpId) => ({
                value: values.shippingGroup,
                marketplace_id: mpId
              })),
              fulfillment_availability: [
                {
                  fulfillment_channel_code: values.fulfillmentStrategy,
                  quantity: typeof values.quantity === 'number' ? values.quantity : parseInt(values.quantity)
                }
              ],
              purchasable_offer: marketplaceIds.map((mpId) => ({
                currency: 'EUR',
                marketplace_id: mpId,
                our_price: [
                  {
                    schedule: [
                      {
                        value_with_tax: typeof values.price === 'number' ? values.price : parseInt(values.price)
                      }
                    ]
                  }
                ]
              }))
            }
          }
        }
      };

      dispatch(AmazonListingsActions.amazonDraftListingItemRequest(createListings));
    },
    [dispatch, marketplaceId, productId, tokenId]
  );

  return (
    <>
      <Card className="card-box">
        <AmazonListingsAddProductHeader backToMainPage={UIProps.backToMainPage} saveButtonClick={saveButtonClick} />
      </Card>

      {product && item && (
        <Card className="card-box">
          <CardBody>
            <Card className="gutter-b">
              <CardSubHeader title={'Produktinfo'} icon={<Icon.Tag />} id="general" />

              <CardBody>
                <AmazonSuggestedProductList1 item={item} sku={product.sku} price={product.pricing.gross_price} />
              </CardBody>
            </Card>

            <Card className="gutter-b">
              <CardSubHeader title={<FormattedMessage id="PRODUCT.LISTINGS" defaultMessage="Listings" />} icon={<Icon.Tag />} id="general" />
              <CardBody>
                <AmazonListingsAddProductForm
                  onAddListingToAmazon={onAddListingToAmazon}
                  initialValues={setupInitialValues(product, marketplaceId)}
                  item={item}
                  btnRef={btnRef}
                  marketplaceIds={[{ value: marketplaceId, label: 'DE' }]}
                  hiddenInputs={['sku', 'quantity', 'price']}
                />
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      )}
    </>
  );
}
