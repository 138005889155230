import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { ProductSettingsTable, } from './product-settings-table/ProductSettingsTable';
import { ProductSettingsFilter } from './settings-filter/ProductSettingsFilter';
import { useUserSettingsUIContext } from '../_context/UserSettingsUIContext';
import { BlankMessage } from '_metronic/_partials/components';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ProductSettingsCard({ queryParams, setOrderQuery, setPaginationQuery, setSearchQuery }) {

  const { id, settings_type, loading, product_settings } = useSelector(state => ({
    id: state.settings.productSettings.id,
    settings_type: state.settings.productSettings.settings_type,
    product_settings: state.settings.productSettings,
    loading: state.settings.loading,
  }), shallowEqual);

  const UIContext = useUserSettingsUIContext();
  const UIProps = useMemo(() => ({
    openProductSettingDialog: UIContext.openProductSettingDialog,
  }),
    [UIContext]
  );
  const searchedProduct = () => {
    if (queryParams.search) {
      return product_settings?.products.filter(item => item.title.toLowerCase().includes(queryParams.search.toLowerCase()))
    }
    return product_settings?.products
  }

  return (
    <Card className="gutter-b">

      <CardSubHeader
        title={<FormattedMessage id="ADMIN.SETTINGS.PRICING.SETTINGS" />}
        icon={<Icon.Cash />}
      >

        <OverlayTrigger overlay={<Tooltip id="market-tooltip"><FormattedMessage id="ADMIN.SETTINGS.NEW_SERVICE" /></Tooltip>}>
          <Button variant='outline-primary' size='sm' className="svg-icon" onClick={() => UIProps.openProductSettingDialog()} id="btn_shipping" disabled={product_settings?.products?.length >= 10}>
            <Icon.Plus />
            <span className="d-none d-md-inline font-weight-bold ml-2">
              <FormattedMessage id="ADMIN.SETTINGS.NEW_SERVICE" />
            </span>
          </Button>
        </OverlayTrigger>

      </CardSubHeader>

      <CardBody>
        {searchedProduct()?.length === 0 && !queryParams.search
          ?
          <BlankMessage
            icon={<Icon.BoxSeam />}
            title={<FormattedMessage id='PRODUCT.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='PRODUCT.BLANK_MESSAGE' />}
            loading={loading}
            arrow
          />
          : <>
            <ProductSettingsFilter setSearchQuery={setSearchQuery} />
            <ProductSettingsTable
              id={id}
              queryParams={queryParams}
              settings_type={settings_type}
              searchedProduct={searchedProduct()}
              loading={loading}
              product_settings={product_settings}
              setPaginationQuery={setPaginationQuery}
              setOrderQuery={setOrderQuery}
              setSearchQuery={setSearchQuery}
            />
          </>
        }

      </CardBody>

    </Card>
  );
}