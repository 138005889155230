import React, { useMemo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal, Button } from 'react-bootstrap';
import { Card, CardSubHeader } from '_metronic/_partials/controls';
import { SelectionCheckbox, convertPriceCurrency, headerSortingClasses } from '_metronic/_helpers';
import { SelectCounter } from '_metronic/_partials';
import { useUserInvoicesUIContext } from 'app/admin/user-invoices/_context/UserInvoicesUIContext';
import { UNIT_OPTIONS } from 'app/modules/products/_context/ProductsUIHelpers';
import { getPositionLabel } from 'app/admin/user-invoices/_context/UserInvoicesUIHelpers';
import { AdminUserInvoicesActions } from 'app/admin/user-invoices/_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toast } from 'react-toastify';
import { Icon } from '_metronic/_icons';
import { isArray } from 'lodash';



export function ProductSelectDialog() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const inputRef = useRef();

  const [productIds, setProductIds] = useState([]);


  const UIContext = useUserInvoicesUIContext();
  const UIProps = useMemo(() => ({
    handleCloseProductSelectDialog: UIContext.handleCloseProductSelectDialog,
    showProductSelectDialog: UIContext.showProductSelectDialog,
  }),
    [UIContext]
  );

  const { products, positions } = useSelector((state) => ({
    positions: state.adminUserInvoices.userInvoiceForEdit.position,
    products: state.settings.productSettings.products,
  }),
    shallowEqual
  );

  const filteredProducts = products.filter((p) => !positions.some((pos) => pos.sku === p.sku)).filter((p) => p.sku !== 'service_fee');

  const onHideHandler = () => {
    UIProps.handleCloseProductSelectDialog();
    setProductIds([]);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    return () => {
      setProductIds([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.showProductSelectDialog]);


  const multipleSelectedProductHandler = (productIds) => {
    const foundProducts = products.filter((p) => productIds.includes(p.sku));
    if (isArray(foundProducts) && foundProducts.length > 0) {
      dispatch(AdminUserInvoicesActions.addMultiplePositions(foundProducts));
    }
    onHideHandler();
  };
  const unitOptions = UNIT_OPTIONS.map(item => ({
    value: item.value,
    label: intl({ id: item.label })
  }));

  const COLUMNS = [
    {
      dataField: 'title',
      text: intl({ id: 'GENERAL.NAME' }),
      headerClasses: 'text-nowrap',
      headerStyle: { minWidth: '260px', width: '65%' },
      classes: 'font-weight-bold pr-6',
      headerSortingClasses,
      formatter: (cell, row) => (
        <div className="d-flex align-items-center">
          <div>
            <div className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg">
              {getPositionLabel(cell) ?? cell}
            </div>
            <div className="font-weight-bold text-dark-50">
              {row.sku}
            </div>
          </div>
        </div>
      ),
    },
    {
      dataField: 'unit',
      text: intl({ id: 'DOCUMENT.POSITION_UNIT' }),
      headerStyle: { minWidth: '100px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      editable: (cell, row) => !row.default,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem 0.5rem 1.75rem" }),
      formatter: (cell, row) => (
        <div >
          {unitOptions.find(item => item.value === cell)?.label}
        </div>
      ),
    },
    {
      dataField: 'gross_price',
      text: intl({ id: 'PRODUCT.TABLE.PRICE_GROSS' }),
      headerClasses: 'text-right pr-3',
      headerStyle: { minWidth: '130px', width: '10%' },
      classes: 'text-right font-weight-bold pr-3',
      formatter: (cell, row) => <div >{convertPriceCurrency(+cell, 'EUR')}</div>,
    }
  ];

  const handleOnSelect = (row, isSelect) => {
    if (isSelect && row?.variants?.length > 0) {
      return false;
    }
    return true;
  };

  const getNonSelectableRows = (prods) => {
    if (!isArray(prods) && prods.length === 0) {
      return [];
    }
    const indexes = prods.map((p, i) => (p.variants?.length > 0 ? i : null)).filter((i) => i !== null);
    return indexes;
  };



  const onChange = ({ ids, setIds, rowId, currency }) => {
    if (ids.some((id) => id === rowId)) {
      setIds(ids.filter((id) => id !== rowId));
    } else {
      const newIds = [...ids];
      newIds.push(rowId);
      setIds(newIds);
    }
  };

  const selectRow = ({ entities, ids, setIds }) => {
    return {
      mode: 'checkbox',
      clickToSelect: true,
      hideSelectAll: true,
      clickToExpand: true,
      nonSelectable: getNonSelectableRows(entities),

      selectionRenderer: ({ rowKey }) => {
        const row = entities.find((ent) => ent.sku === rowKey);
        const isSelected = ids.some((el) => el === rowKey);

        const checkProduct = (row) => {
          if (!isSelected && positions.some((pos) => pos.sku === rowKey)) {
            return toast.warning(intl({ id: 'DOCUMENT.POSITION.ALREADY_AVAILABLE' }, { name: row?.title }))
          }
          onChange({ ids, setIds, rowId: rowKey })
        }

        return (
          <SelectionCheckbox
            isSelected={isSelected}
            onChange={() => {
              checkProduct(row);
            }}
            id={row?.sku}
          />
        );
      },
      onSelect: handleOnSelect
    };
  };


  return (
    <Modal show={UIProps.showProductSelectDialog} onHide={onHideHandler} backdrop="static" keyboard={false} size="lg" centered>
      <Card>

        <CardSubHeader
          icon={<Icon.Catalog />}
          title={<FormattedMessage id="ADMIN.SETTINGS.SERVICE_ADD" />}
        >
        </CardSubHeader>

        <Modal.Body>

          <PerfectScrollbar className="scroll" style={{ maxHeight: "calc(90vh - 200px)", minHeight: "200px", position: "relative" }}>
            <BootstrapTable
              keyField="sku"
              key={productIds}
              data={filteredProducts || []}
              columns={COLUMNS}
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center"
              bootstrap4
              bordered={false}
              condensed
              noDataIndication={intl({ id: 'GENERAL.NO_RESULT' })}
              selectRow={selectRow({
                ids: productIds,
                setIds: setProductIds,
                entities: filteredProducts
              })}
            />
          </PerfectScrollbar>

        </Modal.Body>

        <Modal.Footer>

          <SelectCounter count={productIds.length} />

          <Button variant="light" className='ml-auto' onClick={onHideHandler} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant="primary" className="ml-2" onClick={() => multipleSelectedProductHandler(productIds)} disabled={!productIds.length} id="btn_add_modal">
            <FormattedMessage id="GENERAL.ADD" />
          </Button>

        </Modal.Footer>
      </Card>
    </Modal>
  );
}
