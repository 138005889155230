import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PositionsTable } from './positions-table/PositionsTable';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { useUserInvoicesUIContext } from '../../_context/UserInvoicesUIContext';
import { ProductSelectDialog } from './product-select-dialog/ProductSelectDialog';
import { AdminUserInvoicesActions } from '../../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function PositionsCard({ loading }) {

  const dispatch = useDispatch();

  //UIContext
  const UIContext = useUserInvoicesUIContext();
  const UIProps = useMemo(() => ({
    handleOpenProductSelectDialog: UIContext.handleOpenProductSelectDialog,
    selectedProduct: UIContext.selectedProduct,
  }), [
    UIContext,
  ]);

  const productSettings = useSelector(state => state.settings.productSettings.default_product);

  return (<>
    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.Layers />}
        title={<FormattedMessage id="GENERAL.POSITIONS" />}
      >

        <Dropdown as={ButtonGroup} drop='down' size='sm'>

          <Button variant='outline-primary' className="svg-icon svg-icon-sm" onClick={UIProps.handleOpenProductSelectDialog} disabled={loading} id='btn_add_title'>
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="DOCUMENT.POSITION.PRODUCTS_TITLE" />
            </span>
          </Button>

          <Dropdown.Toggle split variant="outline-primary" id="dropdown-split-basic" />

          <Dropdown.Menu>
            <Dropdown.Item type='button' onClick={() => dispatch(AdminUserInvoicesActions.addNewPositionRow(productSettings))} id='dropdown_new_position'>
              <FormattedMessage id="DOCUMENT.POSITION_NEW_POSITION" />
            </Dropdown.Item>
          </Dropdown.Menu>

        </Dropdown>

      </CardSubHeader>

      <CardBody className="pt-0 px-0">
        <PositionsTable loading={loading} />
      </CardBody>

    </Card>

    {/* {UIProps.showProductEditDialog && (
      <ProductEdit modal show={UIProps.showProductEditDialog} onHide={UIProps.handleCloseProductEditDialog} />
    )} */}

    <ProductSelectDialog />

  </>);
}
