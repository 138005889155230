import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { ACCOUNT_STATUS, LICENSE_TYPE } from 'app/admin/_context/AdminUIHelper';
import { AdminUsersActions } from '../../_redux/actions';
import { maxValidation } from '_metronic/_helpers';
import { Formik, Form, Field } from 'formik';
import { Icon } from '_metronic/_icons';
import * as Yup from 'yup';



const INPUTS = [
  {
    name: "name",
    label: "AUTH.REGISTER.NAME",
    required: true,
  },
  {
    name: "surname",
    label: "AUTH.REGISTER.SURNAME",
    required: true,
  },
  {
    name: "license.type",
    label: "ADMIN.USER.LICENSE_TYPE",
    component: SVReactSelect,
    options: LICENSE_TYPE,
    required: true,
  },
  {
    name: "status",
    label: "ADMIN.USER.ACCOUNT_STATUS",
    component: SVReactSelect,
    options: ACCOUNT_STATUS,
    required: true,
  }
];


export function GeneralEditDialog({ show, onHide, user }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const userEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, intl({ id: 'AUTH.REGISTER.NAME.LENGTH_MIN' }))
      .max(100, maxValidation("100"))
      .required(intl({ id: 'AUTH.VALIDATION.NAME_REQUIRED' })),
    surname: Yup.string()
      .min(1, intl({ id: 'AUTH.REGISTER.NAME.LENGTH_MIN' }))
      .max(100, maxValidation("100"))
      .required(intl({ id: 'AUTH.VALIDATION.SURNAME_REQUIRED' })),
    license: Yup.object().shape({
      type: Yup.string()
        .required(intl({ id: "GENERAL.REQUIRED" })),
    }),
    status: Yup.string()
      .required(intl({ id: "GENERAL.REQUIRED" })),
  });


  const handleSubmit = (values) => {
    const data = {
      name: values?.name ?? '',
      surname: values?.surname ?? '',
      license_type: values?.license?.type ?? '',
      status: values?.status ?? '',
    }
    dispatch(AdminUsersActions.updateUser(user.id, data));
    onHide()
  }


  return (
    <Modal size="md" show={show} onHide={onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" centered>

      <Modal.Header>
        <Modal.Title>
          <Icon.Tag className="mr-3" />
          <FormattedMessage id="GENERAL.GENERAL" />
        </Modal.Title>
      </Modal.Header>

      <Formik enableReinitialize={true} initialValues={user} validationSchema={userEditSchema} onSubmit={handleSubmit}>
        <Form>

          <Modal.Body>
            <Row>
              {INPUTS.map((item, i) => (
                <Col lg="12" key={i}>
                  <Field component={item.component || SVFormControl}
                    {...{
                      name: item.name,
                      label: intl({ id: item.label }),
                      withFeedbackLabel: true,
                      options: item.options,
                      feedbackLabel: true,
                      required: item.required,
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Modal.Body>

          <Modal.Footer>

            <Button variant='light' onClick={onHide} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>

            <Button variant='primary' type="submit" id='btn_update_modal'>
              <FormattedMessage id="GENERAL.SAVE" />
            </Button>

          </Modal.Footer>

        </Form>
      </Formik>

    </Modal>
  );
}