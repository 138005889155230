import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardBody } from '_metronic/_partials/controls'
import { infoType } from '../_context/ImportExportUIHelper';
import { Card as Cards, Accordion } from 'react-bootstrap';
import { importExportActions } from '../_redux/actions';
import AlertInfo from '../_components/AlertInfo';
import { FormattedMessage } from 'react-intl';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export default function InfoAccordion({ importType }) {

  const dispatch = useDispatch();
  const locale = useLang();

  const [isOpen, setIsOpen] = useState(true);

  const getImportExample = useCallback(async (type) => {
    dispatch(importExportActions.importExample({ locale, type }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  return (
    <Card className="gutter-b">

      <Accordion defaultActiveKey="0" type="button">

        <Accordion.Toggle as={Cards.Header} className='d-flex justify-content-between align-items-center font-size-h4 font-weight-bold px-9 py-5' onClick={() => setIsOpen(!isOpen)} eventKey="0">

          <span className='d-flex align-items-center'>
            <Icon.InfoCircle className='mr-3' />
            <FormattedMessage id='IMPORT.USER_INFO' />
          </span>

          <Icon.ChevronDown style={{ transition: "all 0.15s ease", transform: isOpen ? "rotate(-90deg)" : "" }} />

        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0">
          <CardBody>
            {importType &&
              <AlertInfo
                title={infoType.find(item => item.type === importType)?.title}
                description={infoType.find(item => item.type === importType)?.description}
                button={
                  <>
                    <FormattedMessage id='IMPORT_EXPORT.PRODUCT.IMPORT.SAMPLE_DOWNLOAD' />
                    {/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */}
                    <a href="#!" onClick={() => getImportExample(importType)} className='font-weight-bold ml-1 p-0' id='btn_example'>
                      <FormattedMessage id='IMPORT_EXPORT.PRODUCT.IMPORT.SAMPLE_CSV' />
                    </a>
                  </>
                }
              />}
          </CardBody>
        </Accordion.Collapse>

      </Accordion>
    </Card>
  );
}