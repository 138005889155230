import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getShipperName, paymentMethods } from '../../_context/DocumentsUIHelpers';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { useDocumentsUIContext } from '../../_context/DocumentsUIContext';
import { BlankMessage } from '_metronic/_partials/components';
import { getConnection } from 'constants/connectionSettings';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CURRENCIES, UIDates } from '_metronic/_helpers';
//import { UserInfoDropdown } from '_metronic/_partials';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DocumentActions } from '../../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function History({ history }) {

  const location = useLocation();
  const dispatch = useDispatch();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
  }), [UIContext]);

  const { documentForEdit, positionTotals } = useSelector(state => ({
    documentForEdit: state.documents.documentForEdit,
    positionTotals: state.documents.positionTotals,
  }), shallowEqual);

  const currency = documentForEdit?.marketplace === "" ? positionTotals?.currency : documentForEdit?.pricing_summary.currency;

  const handleLabelClick = (item) => {
    const row = documentForEdit.shipping.filter((ship) => {
      return ship.tracking_id === item.description;
    })[0];

    const pathname = location.pathname;
    const parts = pathname.split("/");
    const doc_number = parts[parts.length - 2];

    dispatch(DocumentActions.getLabel([row.id], row.type));
    UIProps.openPrintLabelsDialog(row.service, doc_number, "", row.type)
  }

  const translate_document_names = {
    order: <FormattedMessage id="DOCUMENT.ORDER_TITLE" />,
    invoice: <FormattedMessage id="DOCUMENT.INVOICE_TITLE" />,
    offer: <FormattedMessage id="DOCUMENT.OFFER_TITLE" />,
    credit: <FormattedMessage id="DOCUMENT.CREDIT_TITLE" />,
    waybill: <FormattedMessage id="DOCUMENT.WAYBILL_TITLE" />,
    archive: <FormattedMessage id="ARCHIVE.TITLE" />,
  }

  const getMessages = (item) => {
    const actionType = {
      create_document: {
        label: <FormattedMessage id="DOCUMENT.EDIT.HISTORY.CREATE_DOCUMENT" values={{
          created:
            <span className="text-success">
              <FormattedMessage id="DOCUMENT.EDIT.HISTORY.CREATED" />
            </span>,
        }} />
      },
      convert_document: {
        label: <FormattedMessage id="DOCUMENT.EDIT.HISTORY.CONVERT_DOCUMENT" values={{
          converted:
            <span className="text-success">
              <FormattedMessage id="DOCUMENT.EDIT.HISTORY.CONVERTED" />
            </span>,
        }} />
      },
      send_email: {
        label: <FormattedMessage id="DOCUMENT.EDIT.HISTORY.SEND_EMAIL" values={{
          sent:
            <span className="text-success">
              <FormattedMessage id="DOCUMENT.EDIT.HISTORY.SENT" />
            </span>,
        }} />
      },
      shipping_label: {
        label: <FormattedMessage id="DOCUMENT.EDIT.HISTORY.SHIPPING_LABEL" values={{
          created:
            <span className="text-success">
              <FormattedMessage id="DOCUMENT.EDIT.HISTORY.CREATED" />
            </span>,
        }} />
      },
      return_label: {
        label: <FormattedMessage id="DOCUMENT.EDIT.HISTORY.RETURN_LABEL" values={{
          created:
            <span className="text-success">
              <FormattedMessage id="DOCUMENT.EDIT.HISTORY.CREATED" />
            </span>,
        }} />
      },
      delete_label: {
        label: <FormattedMessage id="DOCUMENT.EDIT.HISTORY.DELETE_LABEL" values={{
          deleted:
            <span className="text-danger">
              <FormattedMessage id="DOCUMENT.EDIT.HISTORY.DELETED" />
            </span>,
        }} />
      },
      fulfillment: {
        label: <FormattedMessage id="DOCUMENT.EDIT.HISTORY.FULFILLMENT" values={{
          complete:
            <span className="text-success">
              <FormattedMessage id="DOCUMENT.EDIT.HISTORY.COMPLETE" />
            </span>,
        }} />
      },
      //FIXME: entered_payment split degeri aciklama number gelince hata veriyor bakılacak
      entered_payment: {
        label: <FormattedMessage id="DOCUMENT.EDIT.HISTORY.ENTERED_PAYMENT" values={{
          entered:
            <span className="text-success">
              <FormattedMessage id="DOCUMENT.EDIT.HISTORY.ENTERED" />
            </span>,
          amount: typeof item?.description === 'string' && item?.description?.includes("#")
            ? item.description.split("#")[1]
            : null,
          currency: CURRENCIES.find(curr => currency === curr.code)?.symbol,
          method: typeof item?.description === 'string' && item.description.includes("#")
            ? paymentMethods.find(method => item.description.split("#")[0] === method.value)?.label
            : null
        }} />
      },
    };
    return actionType[item.action]?.label || "";
  };


  const findIcon = (item) => {

    switch (item.action) {
      case "create_document":
        return getConnection(item.api)?.icon ?? <Icon.ShopWindow />

      case "convert_document":
        return <Icon.FileEarmarkText />

      case "send_email":
        return <Icon.EnvelopeAt />

      case "shipping_label":
        return item?.api ? getConnection(item.api)?.icon : <Icon.Supplier />

      case "return_label":
        return (<span style={{ transform: "scaleX(-1)" }}>{getConnection(item.api)?.icon ?? <Icon.Supplier />}</span>);

      case "delete_label":
        return <Icon.Trash />;

      case "fulfillment":
        return <Icon.CheckSquare />;

      case "entered_payment":
        return <Icon.Cash />;

      default:
        return <div className={clsx("timeline-badge", item.status === "success" ? "bg-success" : "bd-danger")} />;
    }

  }


  return (
    <Card className="card-stretch">

      <CardSubHeader
        icon={<Icon.TextIndentLeft />}
        title={<FormattedMessage id='DOCUMENT.EDIT.HISTORY.TITLE' />}
      //info={<UserInfoDropdown description={<FormattedMessage id={'DOCUMENT.HEADER.INFO_DESCRIPTION'} />} />} 
      />

      <CardBody style={{ minHeight: "410px" }}>
        {!history?.length > 0
          ? <BlankMessage
            icon={<Icon.TextIndentLeft />}
            title={<FormattedMessage id='DOCUMENT.HISTORY.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='DOCUMENT.HISTORY.BLANK_MESSAGE' />}
            arrow
          />
          : <PerfectScrollbar className="scroll" style={{ maxHeight: '410px' }}>

            <div className="timeline timeline-1">
              <div className="timeline-sep" style={{ left: "12px" }} />

              {history?.map((item, i) => (
                <div className="timeline-item" key={i}>

                  <span className="timeline-badge text-dark-25 svg-icon svg-icon-md mr-1">
                    {findIcon(item)}
                  </span>

                  <div className="timeline-content d-flex align-items-center font-weight-bold justify-content-between">
                    <span className="d-flex align-items-center flex-wrap">

                      {item?.api &&
                        <span className="mr-1">
                          {getConnection(item.api)?.label ?? item.api}
                        </span>}

                      {item?.manuel_shipping &&
                        <span className="mr-1">
                          {getShipperName(item.manuel_shipping) ?? item.manuel_shipping}
                        </span>}

                      {item.action === "convert_document" &&
                        <span className='mr-1'>
                          {translate_document_names[item.document_from]} {'>'} {translate_document_names[item.document_to]}
                        </span>}

                      {item.description &&
                        <span className={clsx("mr-1", { "text-primary text-decoration-underline": item.api })} style={{ cursor: item.api ? "pointer" : "default" }} onClick={item.api ? () => handleLabelClick(item) : undefined}>
                          {item.action === "convert_document"
                            ? <a href={`/document/${location.pathname.includes(item.document_to) ? item.document_from : item.document_to}s/${item.description}/edit`}>
                              {item.description}
                            </a>
                            : item.action === "send_email"
                              ? <span className="mr-1">
                                <FormattedMessage id="DOCUMENT.INVOICE_TITLE" />
                                {" "}
                                <a href={`/document/${item.document_to}s/${item.relation_id}/edit`}>
                                  {item.relation_id}
                                </a>
                              </span>
                              : item.action !== "entered_payment" && `${item.description}`
                          }
                        </span>}

                      <span className={item.error && "text-danger"}>
                        {item.status === "success"
                          ? getMessages(item)
                          : item.error}
                      </span>

                    </span>

                    <OverlayTrigger overlay={<Tooltip id="history-tooltip" className='font-weight-bold'>{UIDates.formatDateTime(item.created_at)}</Tooltip>}>
                      <span className="text-dark-25 font-weight-bold text-right font-italic">
                        {UIDates.formNow(item.created_at)}
                      </span>
                    </OverlayTrigger>

                  </div>

                </div>
              )).reverse()}

            </div>
          </PerfectScrollbar>}
      </CardBody>
    </Card>
  );
}
