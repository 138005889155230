import { call, put, takeEvery, select, fork } from 'redux-saga/effects';
import { adminTempUsersMiddleware, tokenErrorHandler } from 'middlewares';
import { AdminTempUsersActions } from './actions';
import ActionTypes from './actionTypes';




// --------------------------- Temp Users ---------------------------

// Get Temp Users
function* getTempUsersSaga({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countTempUsers = yield call(adminTempUsersMiddleware.getTempUsersCount, accessToken, queryParams);
    const getTempUsers = yield call(adminTempUsersMiddleware.getTempUsers, accessToken, queryParams);

    yield put(AdminTempUsersActions.getTempUsersSuccess(getTempUsers.data, countTempUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminTempUsersActions.getTempUsersFail(JSON.stringify(err.response)));
  }
}


// Update Temp user
function* updateTempUser({ payload: { tempUser } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminTempUsersMiddleware.patchTempUser, accessToken, tempUser);
    yield put(AdminTempUsersActions.updateTempUserSuccess());

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminTempUsersActions.updateTempUserFail(err.response));
  }
}


// Delete Temp User
function* deleteTempUser({ payload: { ids, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminTempUsersMiddleware.deleteTempUser, accessToken, ids);
    yield put(AdminTempUsersActions.deleteTempUserSuccess(ids));

    yield fork(getTempUsersSaga, { payload: { queryParams } });

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminTempUsersActions.deleteTempUserFail(err.response.data.error));
  }
}




export function* adminTempUsersSaga() {
  yield takeEvery(ActionTypes.GET_TEMP_USERS, getTempUsersSaga);
  yield takeEvery(ActionTypes.UPDATE_TEMP_USER, updateTempUser);
  yield takeEvery(ActionTypes.DELETE_TEMP_USERS, deleteTempUser);
}
