
import { initialInvoice, initialPosition, initialPositionTotals, initialStatesForReducer } from '../_context/UserInvoicesUIHelpers';
import { calculatePositionPrices, PaymentStatus } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { UTILS } from '_metronic/_helpers';
import ActionTypes from './actionTypes';
import { cloneDeep } from 'lodash';



const INITIAL_STATE = {
  loading: false,
  userInvoices: [],
  convertDetails: [],
  actionLoading: false,
  documentPDF: {},
  printLoading: false, // loading effect for print or label actions
  userInvoiceForEdit: initialInvoice,
  positionTotals: { id: UTILS.uuidv4(), ...initialPositionTotals },
  actionController: initialStatesForReducer.actionController,
  request: initialStatesForReducer.request,
  tax: 19,
  success: false,
  count: 0,
  totalCount: 0,
  queryParams: {},
  actionId: null, // enum of actions: 'get-users', 'update-user-servers'
  actionResults: [],
  error: null,
};


export const adminUserInvoicesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {


    // Get user invoices
    case ActionTypes.GET_USER_INVOICES:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_USER_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        userInvoices: payload.data,
        count: payload.count.count,
        totalCount: payload.count.total,
      };

    case ActionTypes.GET_USER_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };

    case ActionTypes.GET_USER_INVOICE_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_USER_INVOICE_BY_ID_SUCCESS:
      const userInvoiceForEdit = payload.data;

      return {
        ...state,
        loading: false,
        userInvoiceForEdit: {
          ...userInvoiceForEdit,
          subtitle: {
            header: userInvoiceForEdit.subtitle?.header || initialInvoice.subtitle.header,
            footer: userInvoiceForEdit.subtitle?.footer || initialInvoice.subtitle.footer,
          },
        },
        positionTotals: {
          ...state.positionTotals,
          ...calculatePositionPrices(userInvoiceForEdit?.position),
          currency: userInvoiceForEdit.pricing_summary.currency || 'EUR',
          discount: userInvoiceForEdit.pricing_summary.discount ?? 0,
          total_price_discount: userInvoiceForEdit.pricing_summary.total_price_discount ?? 0,
        },
      };

    case ActionTypes.GET_USER_INVOICE_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };

    case ActionTypes.GET_USER_INVOICES_BY_IDS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_USER_INVOICES_BY_IDS_SUCCESS:
      const convertDetails = payload.data.map(item => ({
        id: item.id,
        customer_details: item.customer_details,
        payment_status: item.payment_status,
        created_at: item.created_at,

      }));

      return {
        ...state,
        loading: false,
        convertDetails: convertDetails,
      };

    case ActionTypes.GET_USER_INVOICES_BY_IDS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };


    //* update user invoice
    case ActionTypes.UPDATE_USER_INVOICE:
      return {
        ...state,
        success: false,
        request: {
          loading: true,
          name: 'update_document',
          specify: null,
          error: null,
          status: 'start',
        },
      };

    case ActionTypes.UPDATE_USER_INVOICE_SUCCESS:
      return {
        ...state,
        success: true,
        request: {
          loading: false,
          name: 'update_document',
          specify: null,
          error: null,
          status: 'fulfilled',
        },
      };

    case ActionTypes.UPDATE_USER_INVOICE_FAIL:
      return {
        ...state,
        success: false,
        request: {
          loading: false,
          name: 'update_document',
          specify: null,
          error: '--ERROR--',
          status: 'failed',
        },
        error: 'GENERAL.ERROR_MESSAGE',
      };


    //* add new position Row
    case ActionTypes.ADD_NEW_POSITION_ROW: {
      const positions = cloneDeep(state.userInvoiceForEdit.position);

      const { tax, customer_details } = state.userInvoiceForEdit;
      const isTax = tax.category === "domestic" || (tax.category === "eu_region" && customer_details.vat_id);
      positions.push({
        ...initialPosition,
        id: UTILS.uuidv4(),
        // productId: UTILS.uuidv4(),
        tax_rate: isTax ? (payload.settings?.tax || state.tax) : 0,
        original_tax_rate: state.tax,
        gross_price: 0,
        currency: state.positionTotals.currency ?? payload.settings.currency,
      });

      return {
        ...state,
        // positions: positions,
        userInvoiceForEdit: {
          ...state.userInvoiceForEdit,
          position: positions,
        },
        positionTotals: {
          ...state.positionTotals,
          ...calculatePositionPrices(positions),
        },
      };
    }


    case ActionTypes.ADD_MULTIPLE_POSITION_ROW: {
      const positions = cloneDeep(state.userInvoiceForEdit.position);
      const { tax, customer_details } = state.userInvoiceForEdit;
      const isTax = tax.category === "domestic" || (tax.category === "eu_region" && customer_details.vat_id);
      const products = payload.products;

      const newPositions = products.map(product => {
        const productTaxRate = isTax ? +product?.tax_rate : 0;
        const existingPosition = positions.find(p => p.sku === product.sku);
        const netPrise = product.gross_price

        if (!existingPosition/*  && product.quantity > 0 */) {
          return {
            id: UTILS.uuidv4(),
            sku: product.sku,
            title: product.title,
            quantity: 1,
            tax_rate: productTaxRate,
            original_tax_rate: +product?.tax_rate,
            net_price: netPrise,
            total_net_price: netPrise,
            gross_price: productTaxRate === 0 ? netPrise : +product?.gross_price,
            total_gross_price: productTaxRate === 0 ? netPrise : +product?.gross_price + (1 + +productTaxRate / 100),
            unit: product.unit || '',
            currency: product.currency || '',
          };
        } else {
          return null;
        }
      }).filter(position => position !== null);

      const concatPositions = [...positions, ...newPositions];

      return {
        ...state,
        userInvoiceForEdit: {
          ...state.userInvoiceForEdit,
          payment_status: PaymentStatus(concatPositions, state.userInvoiceForEdit),
          position: concatPositions
        },
        positionTotals: {
          ...state.positionTotals,
          ...calculatePositionPrices(concatPositions),
        }
      };
    }


    //* update position
    case ActionTypes.UPDATE_POSITION: {

      const positions = cloneDeep(state.userInvoiceForEdit.position);
      const updatedPositionIndex = positions.findIndex(p => p.id === payload.updatedRow.id);
      const { net_price, gross_price, quantity, tax_rate, sku, title } = payload.updatedRow;

      if (!state.userInvoiceForEdit.marketplace && updatedPositionIndex !== -1) {

        if (payload.column?.dataField === 'gross_price') {
          const newGrossPrice = typeof gross_price === 'string' ? +gross_price.replace(',', '.') : gross_price;
          positions[updatedPositionIndex] = {
            ...payload.updatedRow,
            sku: sku?.trim(),
            title: title?.trim(),
            net_price: +newGrossPrice / (1 + tax_rate / 100),
            total_net_price: +quantity * (newGrossPrice / (1 + tax_rate / 100)),
            gross_price: +newGrossPrice,
            total_gross_price: +quantity * +newGrossPrice,
            //...(column.dataField === 'quantity' && { decrementInStock: +quantity }),
          }
        } else {
          const newNetPrice = typeof net_price === 'string' ? +net_price.replace(',', '.') : net_price;
          positions[updatedPositionIndex] = {
            ...payload.updatedRow,
            sku: sku?.trim(),
            title: title?.trim(),
            net_price: newNetPrice,
            total_net_price: +quantity * +newNetPrice,
            gross_price: +newNetPrice * (1 + +tax_rate / 100),
            total_gross_price: +quantity * +newNetPrice * (1 + +tax_rate / 100),
            //...(column.dataField === 'quantity' && { decrementInStock: +quantity }),
          }
        }

      }

      return {
        ...state,
        // positions: positions,
        userInvoiceForEdit: {
          ...state.userInvoiceForEdit,
          payment_status: PaymentStatus(positions, state.userInvoiceForEdit),
          position: positions,
        },
        positionTotals: {
          ...state.positionTotals,
          ...calculatePositionPrices(positions),
        }
      };
    }

    //* delete position
    case ActionTypes.DELETE_POSITION: {
      const positions = cloneDeep(state.userInvoiceForEdit.position);
      const deletePosition = positions.filter(pos => pos.id !== payload.id);

      return {
        ...state,
        // positions: deletePosition,
        userInvoiceForEdit: {
          ...state.userInvoiceForEdit,
          payment_status: PaymentStatus(deletePosition, state.userInvoiceForEdit),
          position: deletePosition,
        },
        positionTotals: {
          ...state.positionTotals,
          ...calculatePositionPrices(deletePosition),
        }
      };
    }


    case ActionTypes.PATCH_USER_INVOICE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.PATCH_USER_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        userInvoiceForEdit: {
          ...state.userInvoiceForEdit,
          ...payload.data,
        },
      };

    case ActionTypes.PATCH_USER_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: 'GENERAL.ERROR_MESSAGE',
      };

    //* print document
    case ActionTypes.PRINT_DOCUMENT:
      return {
        ...state,
        printLoading: true,
        emailSuccess: false,
      };

    case ActionTypes.PRINT_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentPDF: payload.response,
        printLoading: false,
        emailSuccess: true,
      };

    case ActionTypes.PRINT_DOCUMENT_FAIL:
      return {
        ...state,
        printLoading: false,
        emailSuccess: false,
        error: payload.err,
      };

    // Create User Invoices
    case ActionTypes.CREATE_USER_INVOICES:
      return {
        ...state,
        actionLoading: true
      };

    case ActionTypes.CREATE_USER_INVOICES_SUCCESS:
      const currentConvertDetails = state.convertDetails.map(item => {
        const newConvertDetails = payload?.data.find(i => i.id === item.id);
        return { ...item, ...newConvertDetails };
      });

      return {
        ...state,
        actionLoading: false,
        convertDetails: currentConvertDetails
      };

    case ActionTypes.CREATE_USER_INVOICES_FAIL:
      return {
        ...state,
        actionLoading: false
      };

    // Delete converted document
    case ActionTypes.DELETE_COMPLETE_LIST:
      return {
        ...state,
        convertDetails: state.convertDetails.filter(item => item.id !== payload.id)
      };

    //* enter payment
    case ActionTypes.ENTER_PAYMENT:
      return {
        ...state,
        actionController: {
          ...state.actionController,
          loading: true,
          id: payload.documentId,
        },
      };

    case ActionTypes.ENTER_PAYMENT_SUCCESS:
      const paymentValues = payload.paymentValues;
      const paymentStatus = payload.paymentStatus;

      const updatedDocumentPayment = state.userInvoices.map(document => {
        if (document.id === payload.documentId) {
          return {
            ...document,
            payment_status: paymentStatus,
            payments: [...document.entered_payment, paymentValues],
            entered_payment: [...document.entered_payment, paymentValues],
          };
        } else {
          return document;
        }
      });

      return {
        ...state,
        actionController: {
          ...state.actionController,
          loading: false,
          error: null,
          result: 'success',
        },
        userInvoices: updatedDocumentPayment,
        // getDocumentById: {
        //   ...state.getDocumentById,
        //   payment_status: paymentStatus,
        //   entered_payment: [...state.getDocumentById.entered_payment, paymentValues],
        // },
        userInvoiceForEdit: {
          ...state.userInvoiceForEdit,
          history: payload?.history || state.userInvoiceForEdit.history,
          payment_status: paymentStatus,
          entered_payment: [...state.userInvoiceForEdit.entered_payment, paymentValues],
        },
      };

    case ActionTypes.ENTER_PAYMENT_FAIL: {
      const error = payload?.error;
      return {
        ...state,
        actionController: {
          ...state.actionController,
          loading: false,
          error: typeof error === 'string' ? error : JSON.stringify(error.error.message ?? error),
          result: 'error',
        },
      };
    }

    //* delete entered payment
    case ActionTypes.DELETE_ENTERED_PAYMENT:
      return {
        ...state,
      };

    case ActionTypes.DELETE_ENTERED_PAYMENT_SUCCESS:

      const deletePaymentDocId = payload.documentId;
      const deletePaymentId = payload.paymentId;

      const updatedDocument = state.userInvoices.map(document => {
        if (document.id === deletePaymentDocId) {
          return {
            ...document,
            payment_status: PaymentStatus(document.position, { ...document, entered_payment: document.entered_payment.filter(payment => payment.id !== deletePaymentId) }),
            entered_payment: document.entered_payment.filter(payment => payment.id !== deletePaymentId)
          };
        } else {
          return document;
        }
      });

      const deleteEnteredPayment = state.userInvoiceForEdit.entered_payment.filter(payment => payment.id !== deletePaymentId)

      return {
        ...state,
        userInvoices: updatedDocument,
        userInvoiceForEdit: {
          ...state.userInvoiceForEdit,
          payment_status: PaymentStatus(state.userInvoiceForEdit.position, { ...state.userInvoiceForEdit, entered_payment: deleteEnteredPayment }),
          entered_payment: deleteEnteredPayment,
        },
      };


    //* update discount
    case ActionTypes.UPDATE_DISCOUNT: {
      const pricingSummary = {
        ...state.userInvoiceForEdit.pricing_summary,
        discount: payload.discount
      }
      return {
        ...state,
        userInvoiceForEdit: {
          ...state.userInvoiceForEdit,
          payment_status: PaymentStatus(state.userInvoiceForEdit.position, { ...state.userInvoiceForEdit, pricing_summary: pricingSummary }),
          pricing_summary: pricingSummary
        },
        positionTotals: {
          ...state.positionTotals,
          discount: payload.discount
        },
      };
    }

    case ActionTypes.DELETE_ENTERED_PAYMENT_FAIL:
      return {
        ...state,
        error: 'GENERAL.ERROR_MESSAGE',
      };


    // Clean up the user module
    case ActionTypes.CLEAN_UP_USER_MODULE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case ActionTypes.CLEAN_UP_USER_MODULE_PARAMS: {
      return {
        ...state,
        ...payload.params,
      };
    }


    default:
      return state;
  }
};
