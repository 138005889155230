export const searchKeys = [
  'email',
  'name',
  'surname',
  'license.type',
  'user_id'
];

export const searchKeysForUserInvoice = [
  'customer_details.email',
  'customer_details.name',
];

export const searchKeysContact = [
  'owner',
  'company_name',
  'email',
  'phone',
  'mobile',
  'address.city',
  'address.post_code',
  'address.country_code',
];

export const searchKeysTemp = [
  'email',
  'name',
  'surname'
];

export const logKeys = [
  'method',
  'method_name',
  'ip_address',
  'details.device_type',
  'details.browser_type',
  //Bunlar için ya backendde model eklenerek nested arama yaptırılmalı, ya da aranacak alt alanlar belirlenerek search içinde yapılmalı.
  'request',
  'response',
];