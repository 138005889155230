import { API } from 'constants/apiUrl';

export const amazonListingsMiddleware = {
  findListingsItem: (accessToken, { asin, tokenId }) => {
    return API.get(`/amazon/find-listing-item/${tokenId}/${asin}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  draftListingItem: (accessToken, payload) => {
    return API.post(`/amazon/draft-listing-item`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  undoDraftListingItem: (accessToken, payload) => {
    return API.post(`/amazon/undraft-listings-items`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  createListing: (accessToken, payload) => {
    return API.put(`/amazon/put-listing-item`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  addNewProduct: (accessToken, payload) => {
    return API.post(`/amazon/add-new-product`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  // withdrawn listing item on Amazon
  withdrawnListingItems: (accessToken, payload) => {
    return API.post(`/amazon/withdraw-listings-items`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  // publish listing items on Amazon
  publishListingsItem: (accessToken, payload) => {
    return API.post(`/amazon/publish-listings`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  // get product subscriptions on Amazon
  checkSubscription: (accessToken, payload) => {
    return API.get(`/amazon/check-subscription/${payload.productId}/${payload.subscriptionId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  },

  // get listings item
  getListingsItem: (accessToken, payload) => {
    return API.get(`/amazon/get-listings/${payload.marketId}/${payload.productId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
};
