import React from 'react';
import { Col, Row } from 'react-bootstrap';



const AmazonSuggestedProductListItem = ({ label, value }) => (
  <li className="list-group-item" style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
    <span className="font-weight-bold">{label}</span>
    <span>{value}</span>
  </li>
);

export function AmazonSuggestedProductList1({ item, sku, price, showAction = false }) {
  const listItems = [
    { label: 'ASIN', value: item.asin },
    { label: 'Brand', value: item.brand },
    { label: 'Category', value: item.displayName }
  ];

  if (sku) listItems.push({ label: 'SKU', value: sku });
  if (price) listItems.push({ label: 'Price', value: price });

  return (
    <Row>
      <Col lg={4} md={6} sm={12}>
        <img src={item.image} alt="main" style={{ maxHeight: '18rem' }} />
      </Col>
      <Col lg={8} md={6} sm={12}>
        <div className="">
          <h5 className="card-title">{item.title}</h5>
          <ul className="list-group">
            {listItems.map((item, index) => (
              <AmazonSuggestedProductListItem key={index} label={item.label} value={item.value} />
            ))}
          </ul>
        </div>
      </Col>
    </Row>
  );
}

export function AmazonSuggestedProductList({ item, showAction = false }) {
  return (
    <div className="card mt-4 border-light" style={{ maxWidth: '36rem' }}>
      <div className="card-img-top" style={{ height: 'auto', overflow: 'hidden' }}>
        <img src={item.image} alt="main" style={{ maxHeight: '18rem' }} />
      </div>

      <div className="card-body p-4">
        <h5 className="card-title">{item.title}</h5>
        <ul className="list-group list-group-flush">
          <li className="list-group-item" style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
            <span className="font-weight-bold">ASIN</span>
            <span>{item.asin}</span>
          </li>
          <li className="list-group-item" style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
            <span className="font-weight-bold">Brand</span>
            <span>{item.brand}</span>
          </li>
          <li className="list-group-item" style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
            <span className="font-weight-bold">Category</span>
            <span>{item.displayName}</span>
          </li>
        </ul>
      </div>

      {showAction && (
        <div className="card-footer d-flex justify-content-between">
          <button className="btn btn-primary">Add to Amazon</button>
        </div>
      )}
    </div>
  );
}
