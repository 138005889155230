import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { convertPriceCurrency, UIDates } from '_metronic/_helpers';
import { SettingsDialog } from './oss-dialogs/SettingsDialog';
import { CardItems } from '_metronic/_partials/components';
import { OSS_STATUS } from '../../_context/SettingsHelper';
import OssEditDialog from './oss-dialogs/OssEditDialog';
import { UserInfoDropdown } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function OssSettings({ loading, documentSettings, documentType }) {

  const [show, setShow] = useState(false);
  const [action, setAction] = useState({ show: false, type: "", checked: false });

  const limit = 10000; // Toplam limit

  const total_sales_other = documentSettings?.oss_details?.total_sales_other; // Diğer satışlar
  const total_sales = documentSettings?.oss_details?.total_sales // SAV Toplam satışlar
  const total = total_sales + (total_sales_other || 0); // Toplam satışlar

  // İlerleme yüzdesini hesapla
  const progressPercentage = Math.min((total_sales / limit) * 100, 100); // Yüzdeyi limitle 100'e sınırla
  const progressPercentage2 = Math.min(((total_sales_other || 0) / limit) * 100);

  const handleCheckbox = (e) => {
    setAction({ show: true, type: e.target.id, checked: e.target.checked });
  };

  const onHide = () => {
    setAction({ show: false, type: "", checked: false, });
  };

  const isOssActive = documentSettings?.oss_details?.oss_active;
  const ossStatus = OSS_STATUS?.find(item => item.value === (documentSettings?.oss_details?.oss_limit_active ? 'manuel_active' : (documentSettings?.oss_details?.is_oss_threshold ? 'limit_exceeded' : 'limit_not_exceeded')));
  
  const oss_info = [
    {
      label: "SETTINGS.DOCUMENT.OSS.OSS_LIMIT_ACTIVATE",
      info: "SETTINGS.DOCUMENT.OSS.OSS_LIMIT_ACTIVATE.INFO",
      content: <label className="switch switch-sm switch-icon d-flex align-items-center justify-content-start" key="oss_limit_active">
        <input
          type="checkbox"
          name="oss_limit_active"
          checked={!!documentSettings?.oss_details?.oss_limit_active}
          onChange={(e) => handleCheckbox(e)}
          disabled={!isOssActive} // Disabled durumu burada kontrol ediliyor
          id="oss_limit_active"
        />
        <span />
      </label>
    },
    {
      label: "SETTINGS.DOCUMENT.OSS.STATUS",
      content: <label className={`label label-xl label-inline label-rounded label-outline-${ossStatus?.className}`}>
        <span className={`label label-xl label-dot label-${ossStatus?.className} mr-2`} />
        {ossStatus?.label ?? ''}
      </label>,
    },
    {
      label: "SETTINGS.DOCUMENT.OSS.TOTAL_SALES_SAV",
      content: convertPriceCurrency(total_sales ?? 0, 'EUR'),
    },
    {
      label: "SETTINGS.DOCUMENT.OSS.TOTAL_SALES_OTHER",
      content: <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
        <span type="button" className="text-hover-primary preview" onClick={() => setShow(true)} id="btn_edit_oss">
          {convertPriceCurrency(total_sales_other ?? 0, 'EUR')}
          <span className='text-dark-25 svg-icon svg-icon-sm invisible pl-3 pb-1'>
            <Icon.PencilSquare />
          </span>
        </span>
      </OverlayTrigger>
    },
    ...(documentSettings?.oss_details?.sales_limit_exceed_date
      ? [{
        label: "SETTINGS.DOCUMENT.OSS.EXCEED_SALES_DATE",
        content: UIDates.formatDateTime(documentSettings?.oss_details?.sales_limit_exceed_date),
      }]
      : []),
  ];


  return (
    <Card className='card-stretch overlay gutter-b'>

      <CardSubHeader
        icon={<Icon.FileText />}
        title={<FormattedMessage id="SETTINGS.DOCUMENT.OSS.OSS_TITLE" />}
        info={<UserInfoDropdown description={<FormattedMessage id='SETTINGS.DOCUMENT.OSS.TITLE_INFO' />} />}>

        <div style={{ zIndex: 9 }}>
          <OverlayTrigger placement="top" overlay={<Tooltip id='oss_active'><FormattedMessage id={isOssActive ? "SETTINGS.DOCUMENT.OSS.OSS_DEACTIVATE" : "SETTINGS.DOCUMENT.OSS.OSS_ACTIVATE"} /></Tooltip>}>
            <label className="switch switch-sm switch-icon d-flex align-items-center justify-content-end mb-0" key="oss_active">
              <input type="checkbox" name="oss_active" id="oss_active" checked={!!isOssActive} onChange={(e) => handleCheckbox(e)} />
              <span />
            </label>
          </OverlayTrigger>
        </div>

      </CardSubHeader>

      <CardBody>

        <div className="progress" style={{ height: '25px' }}>

          <div
            className="progress-bar bg-secondary"
            role="progressbar"
            style={{ width: `${progressPercentage2}%` }}
            aria-valuenow={progressPercentage2}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span className="progress-bar-label font-weight-bolder text-dark">
              {progressPercentage2 > 6 && `${progressPercentage2.toFixed(1)}%`}
            </span>
          </div>

          <div
            className={`progress-bar ${progressPercentage >= 100 ? 'bg-danger' : progressPercentage > 90 ? 'bg-warning' : 'bg-success'}`}
            role="progressbar"
            style={{ width: `${progressPercentage}%` }}
            aria-valuenow={progressPercentage}
            aria-valuemin='0'
            aria-valuemax='100'
          >
            <span className="progress-bar-label font-weight-bolder">
              {progressPercentage > 6 && `${progressPercentage.toFixed(1)}%`}
            </span>
          </div>

        </div>

        <div className={clsx('font-weight-bolder text-right mt-2 mb-2', total >= 9000 ? 'text-danger' : 'text-muted')}>
          {`${convertPriceCurrency(total, 'EUR')} / ${convertPriceCurrency(limit, 'EUR')}`}
        </div>

        <CardItems Content={oss_info} />

      </CardBody>

      <OssEditDialog show={show} setShow={setShow} loading={loading} documentSettings={documentSettings} documentType={documentType} />
      <SettingsDialog documentSettings={documentSettings} documentType={documentType} action={action} onHide={onHide} />

      {!isOssActive && <div className="overlay-layer bg-dark-o-10" />}

    </Card>
  );
}


